import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  // Typography,
  Grid,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
// import DimondType from './Forms/DimondType';
// import CreateRules from './Forms/CreateRules';
// import NaturalDiam from './Forms/NaturalDiam';
// import LabDiam from './Forms/LabDiam';
import ColorMarkup from './Forms/ColorMarkup';
// import SupplierPage from './Forms/SupplierPage';
import FormSuccess from './FormSuccess/FormSuccess';
import { NODE_API_URL } from '../../../../env_config';
import ValidationSchema from './FormModel/validationSchemaEdit';
// import ValidationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
import {fetchData} from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';
// import UpdateRules from './Forms/UpdateRules';
import { useHistory, useLocation } from 'react-router-dom';
// import { ApiV3DataNatural } from '../ApiDataTable/ApiV3DataNatural';
// import { ApiV3DataLab } from '../ApiDataTable/ApiV3DataLab';
import NaturalDiamEdit from './Forms/NaturalDiamEdit';
import LabDiamEdit from './Forms/LabDiamEdit';
import { toast } from 'react-toastify';
import red from "material-ui/colors/red";
const steps = ['Diamond Parameters', 'Price Markup'];
const { formId, formField } = kycFormModel;
function _renderStepContent(step,setFieldValue,prev,setPrev,values,userinfo,selectallfields,selectallfieldslab,language,setValues,location,setSelectAllFields,setSelectAllFieldsLab,setSupplierChanged,setSupplierData,supplierdata,updateapi,setValidMarkup) {
  // console.log(Object.keys(values).length,"_renderStepContentvalues")
  if(Object.keys(values).length){
    switch (step) {
      case 0:
        return <>{(location.state.naturaldiamond || location.state.naturalfancydiamond)?<NaturalDiamEdit setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfields} language={language} location={location}/>:<LabDiamEdit setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfieldslab} language={language} location={location}/>}</>;
      case 1:
          return <ColorMarkup formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language} setValidMarkup={setValidMarkup} location={location}/>;  
      default:
        return <div>Not Found</div>;
    }
  }
}

export default function CcmodeRule(props) {
  const ErrorButton = withStyles(theme => ({

    root: {

      color: theme.palette.getContrastText(red[500]),

      backgroundColor: red[500],

      '&:hover': {

        backgroundColor: red[700],

      },

    },

  }))(Button);
  const location = useLocation()
  const history = useHistory()
  // console.log(location,"locationlocation")
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues,setInitialValues] = useState({})
  const [customerapi,setCustomerAPI] = useState([])
  const [allrules,setAllRules] = useState([])
  const [supplierchanged,setSupplierChanged] = useState(false)
  const [supplierdata,setSupplierData] = useState([])
  const [updateapi,setupdateApi] = useState(false)
  const [validmarkup,setValidMarkup] = useState(false)
  // console.log(validmarkup,"validmarkup")
  useEffect(() => {
    fetchData(props.userinfo,location).then(values => {
      // console.log(values,"fetchDatafetchData")
      setInitialValues(values)
    })
    axios({
      method: "POST",
      url: `${NODE_API_URL}/API/fetchCCModeRules`,
      headers: { "Content-Type": "application/json" },
      data: {
          "user_id":props.userinfo.id,
      },
  }).then(apireq => {
      if(apireq && apireq.data && apireq.data.data && apireq.data.data.length){
        let mapdata = []
        if(uservalues.naturaldiamond){
          mapdata = apireq.data.data.filter(val => val.naturaldiamond && val.naturaldiamond.toString() === uservalues.naturaldiamond.toString())
        }
        if(uservalues.naturalfancydiamond){
          mapdata = apireq.data.data.filter(val => val.naturalfancydiamond && val.naturalfancydiamond.toString() === uservalues.naturalfancydiamond.toString())
        }
        if(uservalues.lgwhitediam){
          mapdata = apireq.data.data.filter(val => val.labdiamond && val.labdiamond.toString() === uservalues.lgwhitediam.toString())
        }
        if(uservalues.lgfancydiam){
          mapdata = apireq.data.data.filter(val => val.labfancydiamond && val.labfancydiamond.toString() === uservalues.lgfancydiam.toString())
        }
        // console.log(mapdata,"mapdatamapdata")
        mapdata = mapdata.filter((val) => val.rule_id.toString() !== location.state.rule_id.toString()).map(value => {
            return {
              rule_name:value.rule_name,//remove if overlap not working
              shape:value.shape,
              cut:value.cut,
              clarity:value.clarity,
              color:value.color,
              min_carat:value.min_carat,
              max_carat:value.max_carat,
              lab:value.lab,
              symmetry:value.symmetry,
              fluorescence:value.fluorescence,
              polish:value.polish,
              milky:value.milky,
              eyeclean:value.eyeclean,
              shade:value.shade,
              min_dollarperct:value.min_dollarperct,
              max_dollarperct:value.max_dollarperct,
              total_price_from:value.total_price_from,
              total_price_to:value.total_price_to,
              media:value.media,
              minlength:value.minlength,
              maxlength:value.maxlength,
              minwidth:value.minwidth,
              maxwidth:value.maxwidth,
              minheight:value.minheight,
              maxheight:value.maxheight,
              tablefrom:value.tablefrom,
              tableto:value.tableto,
              depthfrom:value.depthfrom,
              depthto:value.depthto,
              ratiofrom:value.ratiofrom,
              ratioto:value.ratioto,
              crheightmin:value.crheightmin,
              crheightmax:value.crheightmax,
              cranglemin:value.cranglemin,
              cranglemax:value.cranglemax,
              pavheightmin:value.pavheightmin,
              pavheightmax:value.pavheightmax,
              pavanglemin:value.pavanglemin,
              pavanglemax:value.pavanglemax,
              brand:value.brand,
              origin:value.origin,
              treatment:value.treatment,
              keytosymbol:value.keytosymbol,
              diamondfancy_color:value.diamondfancy_color,
              diamondfancy_intensity:value.diamondfancy_intensity,
              diamondfancy_overtone:value.diamondfancy_overtone,

              lab_shape:value.lab_shape,
              lab_cut:value.lab_cut,
              lab_clarity:value.lab_clarity,
              lab_color:value.lab_color,
              lab_min_carat:value.lab_min_carat,
              lab_max_carat:value.lab_max_carat,
              lab_lab:value.lab_lab,
              lab_symmetry:value.lab_symmetry,
              lab_fluorescence:value.lab_fluorescence,
              lab_polish:value.lab_polish,
              lab_milky:value.lab_milky,
              lab_eyeclean:value.lab_eyeclean,
              lab_shade:value.lab_shade,
              lab_min_dollarperct:value.lab_min_dollarperct,
              lab_max_dollarperct:value.lab_max_dollarperct,
              lab_total_price_from:value.lab_total_price_from,
              lab_total_price_to:value.lab_total_price_to,
              lab_media:value.lab_media,
              labminlength:value.labminlength,
              labmaxlength:value.labmaxlength,
              labminwidth:value.labminwidth,
              labmaxwidth:value.labmaxwidth,
              labminheight:value.labminheight,
              labmaxheight:value.labmaxheight,
              labtablemin:value.labtablemin,
              labtablemax:value.labtablemax,
              labdepthmin:value.labdepthmin,
              labdepthmax:value.labdepthmax,
              labratiomin:value.labratiomin,
              labratiomax:value.labratiomax,
              labcrheightmin:value.labcrheightmin,
              labcrheightmax:value.labcrheightmax,
              labcranglemin:value.labcranglemin,
              labcranglemax:value.labcranglemax,
              labpavheightmin:value.labpavheightmin,
              labpavheightmax:value.labpavheightmax,
              labpavanglemin:value.labpavanglemin,
              labpavanglemax:value.labpavanglemax,
              laborigin:value.laborigin,
              labtreatment:value.labtreatment,
              labkeytosymbol:value.labkeytosymbol,
              // keytosymbol:value.keytosymbol,
              lab_fancy_color:value.lab_fancy_color,
              lab_fancy_intensity:value.lab_fancy_intensity,
              lab_fancy_overtone:value.lab_fancy_overtone,
            }
          })
          // console.log(mapdata,"mapdatamapdata")
          setCustomerAPI(mapdata)
          setAllRules(apireq.data.data.filter((val) => val.rule_id.toString() !== location.state.rule_id.toString()))
      }
  })
  },[activeStep])
  const [uservalues,setUserValues] = useState({})
  const currentValidationSchema = ValidationSchema(uservalues,customerapi,allrules,location)[activeStep];
  const isLastStep = activeStep + 1 === steps.length;
  // console.log(initialValues,"initialValues")
  // console.log(supplierdata,"supplierdata")
  // console.log(isLastStep,"isLastStep",activeStep)
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    let FinalObject = {
      diamond_type:location.state.diamond_type,
      naturaldiamond:location.state.naturaldiamond,
      naturalfancydiamond:location.state.naturalfancydiamond,
      labdiamond:location.state.labdiamond,
      labfancydiamond:location.state.labfancydiamond,
      rule_name:location.state.rule_name,
      user_id:props.userinfo.id,
      suppliers:values.suppliers,
      perctinclusive:values.perctinclusive?1:0
      // api_id:props.userinfo.api_id,
    }
    FinalObject["rulemarkups"] = values.caratpricemarkups.map(value => {
      console.log(value,"VALUE")
      console.log((parseFloat(value.fromrange) + parseFloat(value.markupvalue)))
      let markupfromrange = 0
      let markuptorange = 0
      if(values.markupoption === "Price"){
        if(value.markuptype === "Absolute"){
          markupfromrange = Math.round((parseFloat(value.fromrange) + parseFloat(value.markupvalue)) * 100)/100
          markuptorange = Math.round((parseFloat(value.torange) + parseFloat(value.markupvalue)) * 100)/100
        }
        else{
          markupfromrange = Math.round((parseFloat(value.fromrange) + (parseFloat(value.fromrange) * parseFloat(value.markupvalue)/100)) * 100)/100
          markuptorange = Math.round((parseFloat(value.torange) + (parseFloat(value.torange) * parseFloat(value.markupvalue)/100)) * 100)/100
        }
      }
      if(FinalObject["diamond_type"] === "N"){
        if(values.markupoption === "Carat"){
          if(value.markuptype === "Absolute"){
            markupfromrange = Math.round((parseFloat(values.total_price_from) + parseFloat(value.markupvalue)) * 100)/100
            markuptorange = Math.round((parseFloat(values.total_price_to) + parseFloat(value.markupvalue)) * 100)/100
          }
          else{
            markupfromrange = Math.round((parseFloat(values.total_price_from) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
            markuptorange = Math.round((parseFloat(values.total_price_to) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
          }
        }
      }
      else{
        if(values.markupoption === "Carat"){
          if(value.markuptype === "Absolute"){
            markupfromrange = Math.round((parseFloat(values.lab_total_price_from) + parseFloat(value.markupvalue)) * 100)/100
            markuptorange = Math.round((parseFloat(values.lab_total_price_to) + parseFloat(value.markupvalue)) * 100)/100
          }
          else{
            markupfromrange = Math.round((parseFloat(values.lab_total_price_from) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
            markuptorange = Math.round((parseFloat(values.lab_total_price_to) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
          }
        }
      }
      return {
        ...value,
        markupname:values.markupoption,
        markupfromrange:markupfromrange,
        markuptorange:markuptorange
      }
    })
    //FinalObject["currency"] = values.multi_currency
    FinalObject["markupname"] = values.markupoption
    FinalObject["rule_id"] = values.rule_id
    if(location.state.diamond_type === "N"){
        FinalObject["shape"] = values.diamondshape
        FinalObject["cut"] = values.cuts
        FinalObject["clarity"] = values.diamondclarity
        FinalObject["min_carat"] = parseFloat(values.carat_from)
        FinalObject["max_carat"] = parseFloat(values.carat_to)
        FinalObject["lab"] = values.lab_val
        FinalObject["symmetry"] = values.symmetries
        FinalObject["fluorescence"] = values.diamondflour
        FinalObject["polish"] = values.polishes
        FinalObject["milky"] = values.milky
        FinalObject["eyeclean"] = values.eyeclean
        FinalObject["shade"] = values.diamondshade
        FinalObject["min_dollarperct"] = parseFloat(values.price_from)
        FinalObject["max_dollarperct"] = parseFloat(values.price_to)
        FinalObject["total_price_from"] = parseFloat(values.total_price_from)
        FinalObject["total_price_to"] = parseFloat(values.total_price_to)
        FinalObject["media"] = values.media
        FinalObject["minlength"] = parseFloat(values.measurementlengthfrom)
        FinalObject["maxlength"] = parseFloat(values.measurementlengthto)
        FinalObject["minwidth"] = parseFloat(values.measurementwidthfrom)
        FinalObject["maxwidth"] = parseFloat(values.measurementwidthto)
        FinalObject["minheight"] = parseFloat(values.measurementheightfrom)
        FinalObject["maxheight"] = parseFloat(values.measurementheightto)
        FinalObject["tablemin"] = parseFloat(values.tablefrom)
        FinalObject["tablemax"] = parseFloat(values.tableto)
        FinalObject["depthmin"] = parseFloat(values.depthfrom)
        FinalObject["depthmax"] = parseFloat(values.depthto)
        FinalObject["ratiomin"] = parseFloat(values.ratiofrom)
        FinalObject["ratiomax"] = parseFloat(values.ratioto)
        FinalObject["crheightmin"] = parseFloat(values.crownheightfrom)
        FinalObject["crheightmax"] = parseFloat(values.crownheightto)
        FinalObject["cranglemin"] = parseFloat(values.crownanglefrom)
        FinalObject["cranglemax"] = parseFloat(values.crownangleto)
        FinalObject["pavheightmin"] = parseFloat(values.pavheightfrom)
        FinalObject["pavheightmax"] = parseFloat(values.pavheightto)
        FinalObject["pavanglemin"] = parseFloat(values.pavanglefrom)
        FinalObject["pavanglemax"] = parseFloat(values.pavangleto)
        FinalObject["brand"] = values.brand
        FinalObject["origin"] = values.origin
        FinalObject["treatment"] = values.treatment
        FinalObject["keytosymbol"] = values.keytosymbol
        if(location.state.naturaldiamond){
          FinalObject["color"] = values.diamondcolor
        }
        else{
          FinalObject["diamondfancy_color"] = values.diamondfancy_color
          FinalObject["diamondfancy_intensity"] = values.diamondfancy_intensity
          FinalObject["diamondfancy_overtone"] = values.diamondfancy_overtone
        }
    }
    else{
      FinalObject["lab_shape"] = values.lab_shape
      FinalObject["lab_cut"] = values.lab_cuts
      FinalObject["lab_clarity"] = values.lab_clarity
      FinalObject["lab_min_carat"] = parseFloat(values.lab_carat_from)
      FinalObject["lab_max_carat"] = parseFloat(values.lab_carat_to)
      FinalObject["lab_lab"] = values.lab_lab
      FinalObject["lab_symmetry"] = values.lab_symmetries
      FinalObject["lab_fluorescence"] = values.lab_flourselect
      FinalObject["lab_polish"] = values.lab_polishes
      FinalObject["lab_milky"] = values.lab_milky
      FinalObject["lab_eyeclean"] = values.lab_eyeclean
      FinalObject["lab_shade"] = values.lab_shadeselect
      FinalObject["lab_min_dollarperct"] = parseFloat(values.lab_min_dollarperct)
      FinalObject["lab_max_dollarperct"] = parseFloat(values.lab_max_dollarperct)
      FinalObject["lab_total_price_from"] = parseFloat(values.lab_total_price_from)
      FinalObject["lab_total_price_to"] = parseFloat(values.lab_total_price_to)
      FinalObject["lab_media"] = values.lab_all_media
      FinalObject["labminlength"] = parseFloat(values.labmeasurementlengthfrom)
        FinalObject["labmaxlength"] = parseFloat(values.labmeasurementlengthto)
        FinalObject["labminwidth"] = parseFloat(values.labmeasurementwidthfrom)
        FinalObject["labmaxwidth"] = parseFloat(values.labmeasurementwidthto)
        FinalObject["labminheight"] = parseFloat(values.labmeasurementheightfrom)
        FinalObject["labmaxheight"] = parseFloat(values.labmeasurementheightto)
        FinalObject["labtablemin"] = parseFloat(values.labtablefrom)
        FinalObject["labtablemax"] = parseFloat(values.labtableto)
        FinalObject["labdepthmin"] = parseFloat(values.labdepthfrom)
        FinalObject["labdepthmax"] = parseFloat(values.labdepthto)
        FinalObject["labratiomin"] = parseFloat(values.labratiofrom)
        FinalObject["labratiomax"] = parseFloat(values.labratioto)
        FinalObject["labcrheightmin"] = parseFloat(values.labcrownheightfrom)
        FinalObject["labcrheightmax"] = parseFloat(values.labcrownheightto)
        FinalObject["labcranglemin"] = parseFloat(values.labcrownanglefrom)
        FinalObject["labcranglemax"] = parseFloat(values.labcrownangleto)
        FinalObject["labpavheightmin"] = parseFloat(values.labpavheightfrom)
        FinalObject["labpavheightmax"] = parseFloat(values.labpavheightto)
        FinalObject["labpavanglemin"] = parseFloat(values.labpavanglefrom)
        FinalObject["labpavanglemax"] = parseFloat(values.labpavangleto)
        // FinalObject["brand"] = values.brand
        FinalObject["laborigin"] = values.laborigin
        FinalObject["labtreatment"] = values.labtreatment
        FinalObject["labkeytosymbol"] = values.labkeytosymbol
      if(location.state.labdiamond){
        FinalObject["lab_color"] = values.lab_color
      }
      else{
        FinalObject["lab_fancy_color"] = values.lab_fancy_color
        FinalObject["lab_fancy_intensity"] = values.lab_fancy_intensity
        FinalObject["lab_fancy_overtone"] = values.lab_fancy_overtone
      }
    }
    // console.log(FinalObject,"FinalObject")
  let activityObject = {...FinalObject};
  delete activityObject['rulemarkups']
  delete activityObject['naturaldiamond']
  delete activityObject['naturalfancydiamond']
  delete activityObject['labdiamond']
  delete activityObject['labfancydiamond']
  delete activityObject['suppliers']

  if (FinalObject['naturaldiamond'] ){
    activityObject["sub_diamond_type"]= "N";
  }
  if (FinalObject['naturalfancydiamond']){
    activityObject["sub_diamond_type"]= "NF";
  }
  if (FinalObject['labdiamond']){
    activityObject["sub_diamond_type"]= "L";
  }
  if (FinalObject['labfancydiamond']){
    activityObject["sub_diamond_type"]= "LF";
  }

  axios({
      method: "POST",
      url: `${NODE_API_URL}/API/insertorupdateCCModeRules`,
      headers: { "Content-Type": "application/json" },
      data: FinalObject,
  }).then(res => {
      if(res && res.data && res.data.data){
          // setData(res.data)
          // console.log( res.data,"NOICE")
          if(res.data.data === "Rule Inserted Successfully!" || res.data.data === "Rule Updated Successfully!"){
            // window.location.replace('/ccmode')
            history.push({
              pathname: "/ccmode",
              state: {
                updatestatus:res.data.data === "Rule Updated Successfully!"
              },
            })

            axios({
              method: "POST",
              url: `${NODE_API_URL}/API/storeRuleACtivity`,
              headers: { 
                  "Content-Type": "application/json",
              },
              data: {
                  // "ip_address":"8.8.8.8",
                  "diamond_type":location.state.diamond_type,
                  "user_id":props.userinfo.id,
                  "activity_type":res.data.data === "Rule Inserted Successfully!" ? "RuleCreated" : "RuleUpdated",
                  "user_agent":navigator.userAgent,
                  "activity_data":activityObject
              },
            }).then(response => {
            }).catch(error => {
            })
            
          }
      }
  })
    
  }

  function _handleSubmit(values, actions) {
    setUserValues(values)
    // console.log("HANDLE SUBMIT")
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      // console.log("ELSE PART")
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack(setFieldValue) {
    setActiveStep(activeStep - 1);
    setPrev(true)
    setSupplierChanged(false)
    setFieldValue("showmarkup",false)
    setValidMarkup(false)
    setSupplierData([])
  }
  const [selectallfields,setSelectAllFields] = useState(false)
  const [selectallfieldslab,setSelectAllFieldsLab] = useState(false)

  // console.log(selectallfields,"selectallfields")
  const SaveSuppliers = () => {
    let promises = []
    for (let i = 0; i < supplierdata.length; i++) {
      promises.push(
            axios({
        method: "POST",
        url: `${NODE_API_URL}/API/updateSupplierRuleOnOff`,
        headers: { "Content-Type": "application/json" },
        data: {
            rule_id:supplierdata[i].rule_id,
            user_id:supplierdata[i].user_id,
            on_off:supplierdata[i].on_off,
            supplier_name:supplierdata[i].supplier_name
        },
    }).then(supreq => {
        if(supreq && supreq.data){
            return supreq.data
        }
    })
      )
    }
    Promise.all(promises).then(() => {
      toast.success("Your Feeds are Updated!")
      setSupplierChanged(false)
      setSupplierData([])
      setupdateApi(!updateapi)
    });
    // axios({
    //     method: "POST",
    //     url: `${NODE_API_URL}/API/updateDataFromSupReqable`,
    //     headers: { "Content-Type": "application/json" },
    //     data: {
    //         "fields": [
    //             {
    //                 "api_on_off": switchvalue
    //             }
    //         ],
    //         "condition": [
    //             {
    //                 "sr_id": d.sr_id
    //             }
    //         ]
    //     },
    // }).then(supreq => {
    //     if(supreq && supreq.data){
    //         if(supreq.data){
    //         setUpdate(d)
    //         if(switchvalue === 1){
    //             toast.success(`Your Feed Turned on for ${d.supplier_name_o}`)
    //         }else{
    //             toast.error(`Your Feed Turned off for ${d.supplier_name_o}`)
    //         }
    //         }
    //     }
    // })
  }
  const CancelSuppliers = ()=> {
    setSupplierChanged(false)
    setSupplierData([])
    setupdateApi(!updateapi)
  } 
  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values,setValues }) => (
              <Form id={formId} className='bg-white'>
               <div className="p-5" style={{minHeight: "60vh"}}>{_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,props.userinfo,selectallfields,selectallfieldslab,props.language,setValues,location,setSelectAllFields,setSelectAllFieldsLab,setSupplierChanged,setSupplierData,supplierdata,updateapi,setValidMarkup)}</div>
                <Grid className="mt-10 sticky-top bg-gray-100 shadow-bottom" container justify={"space-between"} alignItems="center"  style={{bottom:"0"}}>
                  <div className="ml-2">
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={() => _handleBack(setFieldValue)} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{props.language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  {
                      activeStep !== 4?
                      <Button 
                      onClick={() => window.location.replace('/ccmode')} 
                      className={`btn btn-danger`}
                      >
                         Cancel Rule
                    </Button>:""
                    }
                    </div>
                  <div className={classes.wrapper}>
                      {activeStep === 0 && (location.state.naturaldiamond || location.state.naturalfancydiamond)?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFields(true)}>Select Required</Button>:""}
                        {activeStep === 0 && (location.state.labdiamond || location.state.labfancydiamond)?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFieldsLab(true)}>Select Required</Button>:""}
                        {/* {activeStep === 4 && supplierchanged?<>
                        <Button
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={SaveSuppliers}
                    >
                      Save
                    </Button>
                    <ErrorButton
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={CancelSuppliers}
                    >
                      Cancel
                    </ErrorButton>
                    </>:activeStep === 4?<Button
                     variant="contained"
                     color="primary"
                     className={classes.button}
                     onClick={() => window.location.replace('/diamondfeed')}
                     >
                      Complete Setup
                    </Button>:""}
                    {!supplierchanged && activeStep !== 4?<Button
                      disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0) || (values.showmarkup) || (validmarkup)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Move To Supplier" : props.language.PROFILE_NEXT}
                    </Button>:""} */}
                    <Button
                      disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0) || (values.showmarkup) || (validmarkup)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Save Rule" : props.language.PROFILE_NEXT}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
