/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { SwalWarn } from "../../../../_partials/Popup";
import { propTypes } from "react-bootstrap/esm/Image";

export function AsideMenuList({ layoutProps,language,userinfo }) {
  const [swalWarnOpen,setSwalWarnOpen] = useState(false)
  const [alertMessage,setAlertMessage] = useState()
  const userData = React.useMemo(() => localStorage.getItem("userData"), []);
  const user = JSON.parse(userData);
  
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const DetailSemiAprrove = () => {
      setSwalWarnOpen(true)
      setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
  }

  return (
    <>
      {/* begin::Menu Nav */}
      {userinfo.v_customer === "12"?<ul className={`menu-nav pt-2 ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/dashboard-icon.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_DASHBOARD}</span>
          </NavLink>
        </li>
        {userinfo.kyc_status === "KYC_APPR"?<li className="menu-section ">
          <span className="menu-text">{language.SIDEBAR_OTHER_UTILITIES}</span>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>:""}

        
        {/* <li
          className={`menu-item ${getMenuItemActive("/confirmgoods", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/confirmgoods">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/General/confirm-goods-aside.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_OTHER_CONFIRM_DIAMONDS}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item holdLink ${getMenuItemActive("/hold-diamonds", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/hold-diamonds">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/General/hold-diam-aside.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_OTHER_HOLD_DIAMONDS}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/invoices", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/invoices">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/General/invoice_menu.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_OTHER_INVOICES}</span>
          </NavLink>
        </li> */}
              {userinfo.kyc_status === "KYC_APPR"?<>
              <li
                  className={`menu-item ${getMenuItemActive("/confirmgoods", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/confirmgoods">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/confirm-goods-aside.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_CONFIRM_DIAMONDS}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/invoices", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/invoices">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/invoice_menu.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_INVOICES}</span>
                  </NavLink>
                </li>
              </>:""}
            
          {/* <li
            className={`menu-item ${getMenuItemActive("/ccmode", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/ccmode">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/ccmode_aside_icon.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_CCMODE}</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/diamondfeed", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/diamondfeed">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/diamond_feed_sidebar.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_DIAMOND_FEED}</span>
            </NavLink>
          </li> */}
      </ul>:<ul className={`menu-nav pt-2 ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/dashboard-icon.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_DASHBOARD}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{language.SIDEBAR_NATURAL_DIAMONDS}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item natTab ${getMenuItemActive("/searchdiamond/white", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/searchdiamond/white',
                                  option: "white"  
                                    }}>
            <span className="svg-icon menu-icon natural-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/diamond.svg")} />
            </span>
            <span className="menu-text">{language.DASHBOARD_SEARCH_NATURAL_DIAMOND}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item natTab fancy ${getMenuItemActive("/searchdiamond/fancy", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/searchdiamond/fancy',
                                  option: "fancy"  
                                    }}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/diamond.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_NATURAL_FANCY_DIAMONDS}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/parcel-diamond", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/parcel-diamond',
                                  option: "PN"  
                                    }}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/parcel_diam.svg")} />
            </span>
            <span className="menu-text">Natural Parcel Diamond</span>
          </NavLink>
        </li>
        
        {/*end::1 Level*/}
        {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">{language.SIDEBAR_LAB_GROWN_DIAMONDS}</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item labTab ${getMenuItemActive("/labsearchdiamond/white", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/labsearchdiamond/white',
                                  option: "white"  
                                    }}>
            <span className="svg-icon menu-icon lab-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/lab_diamond.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_LAB_GROWN_DIAMOND}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item labTab fancy ${getMenuItemActive("/labsearchdiamond/fancy", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/labsearchdiamond/fancy',
                                  option: "fancy"  
                                    }}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/lab_diamond.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_LAB_GROWN_FANCY_DIAMOND}</span>
          </NavLink>
        </li>
         {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/lab-parcel-diamond", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={{
                                  pathname:'/lab-parcel-diamond',
                                  option: "PL"  
                                    }}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/parcel_diam.svg")} />
            </span>
            <span className="menu-text">Lab-Grown Parcel Diamond</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li className="menu-section ">
          <span className="menu-text">{language.SIDEBAR_OTHER_UTILITIES}</span>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        
       
          {(user.CustomerType !=="7" && user.CustomerType !=="11") 
            ? (
              <>
              <li
                  className={`menu-item holdLink ${getMenuItemActive("/hold-diamonds", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/hold-diamonds">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/hold-diam-aside.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_HOLD_DIAMONDS}</span>
                  </NavLink>
                </li>
              <li
                  className={`menu-item ${getMenuItemActive("/confirmgoods", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/confirmgoods">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/confirm-goods-aside.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_CONFIRM_DIAMONDS}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/tracklist", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tracklist">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/wishlist-menu.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_TRACKLIST}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/invoices", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/invoices">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/invoice_menu.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_INVOICES}</span>
                  </NavLink>
                </li>
                <li
            className={`menu-item ${getMenuItemActive("/ccmode", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/ccmode">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/ccmode_aside_icon.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_CCMODE}</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/diamondfeed", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/diamondfeed">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/diamond_feed_sidebar.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_DIAMOND_FEED}</span>
            </NavLink>
          </li>
              </>
            ):(
            <>
              <li
                  className={`menu-item holdLink ${getMenuItemActive("/hold-diamonds", false)}`}
                  aria-haspopup="true"
                  onClick={() => DetailSemiAprrove() }
                >
                  <span className="menu-link">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/hold-diam-aside.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_HOLD_DIAMONDS}</span>
                  </span>
                </li>
              <li
                  className={`menu-item ${getMenuItemActive("/confirmgoods", false)}`}
                  aria-haspopup="true"
                  onClick={() => DetailSemiAprrove() }
                >
                  <span className="menu-link">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/confirm-goods-aside.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_CONFIRM_DIAMONDS}</span>
                  </span>
                </li>
                
                <li
                  className={`menu-item ${getMenuItemActive("/tracklist", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/tracklist">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/wishlist-menu.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_TRACKLIST}</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/invoices", false)}`}
                  aria-haspopup="true"
                  onClick={() => DetailSemiAprrove() }
                >
                  <span className="menu-link" >
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/General/invoice_menu.svg")} />
                    </span>
                    <span className="menu-text">{language.SIDEBAR_OTHER_INVOICES}</span>
                  </span>
                </li>
                <li
            className={`menu-item ${getMenuItemActive("/ccmode", false)}`}
            aria-haspopup="true"
            onClick={() => DetailSemiAprrove() }
          >
            <span className="menu-link">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/ccmode_aside_icon.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_CCMODE}</span>
            </span>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/diamondfeed", false)}`}
            aria-haspopup="true"
            onClick={() => DetailSemiAprrove() }
          >
            <span className="menu-link" to="/diamondfeed">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/diamond_feed_sidebar.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_DIAMOND_FEED}</span>
            </span>
          </li>
              </>
            )
          }
          {/* <li
            className={`menu-item ${getMenuItemActive("/ccmode", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/ccmode">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/ccmode_aside_icon.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_CCMODE}</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/diamondfeed", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/diamondfeed">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/General/diamond_feed_sidebar.svg")} />
              </span>
              <span className="menu-text">{language.SIDEBAR_DIAMOND_FEED}</span>
            </NavLink>
          </li> */}
          <li
            className={`menu-item ${getMenuItemActive("/contact", false)}`}
            aria-haspopup="true"
          >
          <NavLink className="menu-link" to="/contact">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/General/contact_menu.svg")} />
            </span>
            <span className="menu-text">{language.SIDEBAR_CONTACT_US}</span>
          </NavLink>
        </li>
      </ul>}
      <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
      {/* end::Menu Nav */}

    </>
  );
}
