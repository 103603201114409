import React from "react";
import { Field } from "formik";

export const Treatment = ({language,formik}) => {
    const TreatmentData = ([
         {
            id: 2,
            Treatment:"As Grown"
        },
        {
            id: 3,
            Treatment:"Treated"
        },
        {
            id: 4,
            Treatment:"Unknown"
        }
    ]);
    
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.MUI_TREATMENT}</p>
                </div>
                <div className="col-lg-10 d-flex flex-wrap">
                    {TreatmentData.map((item) => (
                        <label key={`${item.id}_Treatment`} className="search_btn_check">
                            <Field type="radio" name="Treatment" id={`${item.Treatment}_Treatment`} value={item.Treatment} onClick={(e) => e.target.value === formik.values.Treatment?formik.setFieldValue("Treatment",""):formik.setFieldValue("Treatment",e.target.value)} checked={formik.values.Treatment === item.Treatment}/>
                            <div className="treatBox selectBox">
                                <span>{item.Treatment}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};