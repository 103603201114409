import React from "react";

export default function Sorting(props) {
    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }

    return (
        <div>
            <select
                className="custom-select border border-primary rounded-0"
                onChange={(e) => {
                    props.setSortObj(JSON.parse(e.target.value))
                }}
                value={JSON.stringify(props.sortobj) || ""}
            >
                <option value={JSON.stringify({id:"",sortorder:""})} disabled selected>
                    Sort By
                </option>
                <optgroup label="Carat">
                    <option selected={props.sortobj.id === "C_Weight" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Weight",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Weight" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Weight",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Stock ID">
                    <option selected={props.sortobj.id === "Stock ID" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Stock ID",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Stock ID" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Stock ID",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Country">
                    <option selected={props.sortobj.id === "country" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"country",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "country" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"country",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Shape">
                    <option selected={props.sortobj.id === "Shape" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Shape",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Shape" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Shape",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Color">
                    <option selected={props.sortobj.id === "Color" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Color",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Color" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Color",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Clarity">
                    <option selected={props.sortobj.id === "C_Clarity" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Clarity",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Clarity" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Clarity",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Cut">
                    <option selected={props.sortobj.id === "C_Cut" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Cut",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Cut" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Cut",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Polish">
                    <option selected={props.sortobj.id === "C_Polish" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Polish",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Polish" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Polish",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Symmetry">
                    <option selected={props.sortobj.id === "C_Symmetry" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Symmetry",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Symmetry" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Symmetry",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Fluorescence">
                    <option selected={props.sortobj.id === "C_Fluorescence" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_Fluorescence",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_Fluorescence" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_Fluorescence",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Lab">
                    <option selected={props.sortobj.id === "Lab" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Lab",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Lab" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Lab",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Our Rate">
                    <option selected={props.sortobj.id === "OurRate" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"OurRate",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "OurRate" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"OurRate",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Our Price">
                    <option selected={props.sortobj.id === "OurPrice" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"OurPrice",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "OurPrice" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"OurPrice",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Measurement">
                    <option selected={props.sortobj.id === "Meas." && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Meas.",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Meas." && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Meas.",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="TableP">
                    <option selected={props.sortobj.id === "C_TableP" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_TableP",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_TableP" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_TableP",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="DefthP">
                    <option selected={props.sortobj.id === "C_DefthP" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"C_DefthP",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "C_DefthP" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"C_DefthP",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Certificate">
                    <option selected={props.sortobj.id === "Certificate" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Certificate",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Certificate" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Certificate",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
                <optgroup label="Ratio">
                    <option selected={props.sortobj.id === "Ratio" && props.sortobj.sortorder === "desc"} value={JSON.stringify({id:"Ratio",sortorder:"desc"})}>
                        high to low
                    </option>
                    <option selected={props.sortobj.id === "Ratio" && props.sortobj.sortorder === "asc"} value={JSON.stringify({id:"Ratio",sortorder:"asc"})}>
                        low to high
                    </option>
                </optgroup>
            </select>
        </div>
    );
}
