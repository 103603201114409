/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react";
import { Field } from "formik";
import {MultiSelect} from '../MultiSelect'
import { GetCookies,SetCookies,RemoveCookies } from "../../../../_helpers/Cookies";
export const Color = ({formik,language,option}) => {
    useEffect(() => {
        if(option === "white"){
            SetCookies("white",1)
            RemoveCookies("fancy")
            onwhiteClick()
        }
        else if(option === "fancy"){
            SetCookies("fancy",1)
            RemoveCookies("white")
            onfancyClick()
        }
        else{
            GetCookies("white").then((res) => {
                if(res && res === "1"){
                    onwhiteClick()
                }
            })
            GetCookies("fancy").then((res) => {
                    if(res && res === "1"){
                        onfancyClick()
                    }
                })
                GetCookies("whitebasic").then((res) => {
                    if(res && res === "1"){
                        onwhiteClick()
                    }
                })
                GetCookies("fancybasic").then((res) => {
                    if(res && res === "1"){
                        onfancyClick()
                    }
                })    
        }
    },[option])
    const IntensityData = ([
        {
            id: "int_1",
            value:"FAINT",
            intensity:"FAINT"
        },
        {
            id: "int_2",
            value:"VERY LIGHT",
            intensity:"VERY LIGHT"
        },
        {
            id: "int_3",
            value:"LIGHT",
            intensity:"LIGHT"
        },
        {
            id: "int_4",
            value:"FANCY LIGHT",
            intensity:"FANCY LIGHT"
        },
        {
            id: "int_5",
            value:"FANCY",
            intensity:"FANCY"
        },
        {
            id: "int_6",
            value:"FANCY DARK",
            intensity:"FANCY DARK"
        },
        {
            id: "int_7",
            value:"FANCY INTENSE",
            intensity:"FANCY INTENSE"
        },
        {
            id: "int_8",
            value:"FANCY VIVID",
            intensity:"FANCY VIVID"
        },
        {
            id: "int_9",
            value:"FANCY DEEP",
            intensity:"FANCY DEEP"
        }
    ]);
    const ColorData = ([
        {
            id: 1,
            value:"YELLOW",
            color:"YELLOW"
        },
        {
            id: 2,
            value:"PINK",
            color:"PINK"
        },
        {
            id: 3,
            value:"BLUE",
            color:"BLUE"
        },
        {
            id: 4,
            value:"RED",
            color:"RED"
        },
        {
            id: 5,
            value:"GREEN",
            color:"GREEN"
        },
        {
            id: 6,
            value:"PURPLE",
            color:"PURPLE"
        },
        {
            id: 7,
            value:"ORANGE",
            color:"ORANGE"
        },
        {
            id: 8,
            value:"VIOLET",
            color:"VIOLET"
        },
        {
            id: 10,
            value:"GREY",
            color:"GREY"
        },
        {
            id: 11,
            value:"BLACK",
            color:"BLACK"
        },
        {
            id: 12,
            value:"BROWN",
            color:"BROWN"
        },
        {
            id: 13,
            value:"Champagne",
            color:"Champagne"
        },
        {
            id: 14,
            value:"Cognac",
            color:"Cognac"
        },
        {
            id: 15,
            value:"Chameleon",
            color:"Chameleon"
        },
        {
            id: 16,
            value:"WHITE",
            color:"WHITE"
        },
        {
            id: 17,
            value:"Other",
            color:"Other"
        }
    ]);
    const OvertoneData = ([
        {
            id: 1,
            value:"NONE",
            overtone:"NONE"
        },
        {
            id: 0,
            value:"YELLOW",
            overtone:"YELLOW"
        },
        {
            id: 2,
            value:"Yellowish",
            overtone:"Yellowish"
        },
        {
            id: 3,
            value:"Pink",
            overtone:"Pink"
        },
        {
            id: 4,
            value:"Pinkish",
            overtone:"Pinkish"
        },
        {
            id: 5,
            value:"Blue",
            overtone:"Blue"
        },
        {
            id: 6,
            value:"Blueish",
            overtone:"Blueish"
        },
        {
            id: 7,
            value:"Red",
            overtone:"Red"
        },
        {
            id: 8,
            value:"Reddish",
            overtone:"Reddish"
        },
        {
            id: 9,
            value:"Green",
            overtone:"Green"
        },
        {
            id: 10,
            value:"Greenish",
            overtone:"Greenish"
        },
        {
            id: 11,
            value:"Purple",
            overtone:"Purple"
        },
        {
            id: 12,
            value:"Purplish",
            overtone:"Purplish"
        },
        {
            id: 13,
            value:"Orange",
            overtone:"Orange"
        },
        {
            id: 14,
            value:"Orangy",
            overtone:"Orangy"
        },
        {
            id: 15,
            value:"VIOLET",
            overtone:"VIOLET"
        },
        {
            id: 16,
            value:"Violetish",
            overtone:"Violetish"
        },
        {
            id: 17,
            value:"Gray",
            overtone:"Gray"
        },
        {
            id: 18,
            value:"Grayish",
            overtone:"Grayish"
        },
        {
            id: 19,
            value:"Black",
            overtone:"Black"
        },
        {
            id: 20,
            value:"Brown",
            overtone:"Brown"
        },
        {
            id: 21,
            value:"BROWNISH",
            overtone:"BROWNISH"
        },
        {
            id: 22,
            value:"Champagne",
            overtone:"Champagne"
        },
        {
            id: 23,
            value:"Cognac",
            overtone:"Cognac"
        },
        {
            id: 24,
            value:"Chameleon",
            overtone:"Chameleon"
        },
        {
            id: 25,
            value:"WHITE",
            overtone:"WHITE"
        }
        ,
        {
            id: 26,
            value:"Other",
            overtone:"Other"
        }
    ]);
    const whitecolorData = ([
        {
            id: 0,
            value:"D"
        },
        {
            id: 1,
            value:"E"
        },
        {
            id: 2,
            value:"F"
        },
        {
            id: 3,
            value:"G"
        },
        {
            id: 4,
            value:"H"
        },
        {
            id: 5,
            value:"I"
        },
        {
            id: 6,
            value:"J"
        },
        {
            id: 7,
            value:"K"
        },
        {
            id: 8,
            value:"L"
        },
        {
            id: 9,
            value:"M"
        },
        {
            id: 10,
            value:"N"
        },
        {
            id: 11,
            value:"OP"
        },
        {
            id: 12,
            value:"QR"
        },
        {
            id: 13,
            value:"ST"
        },
        {
            id: 14,
            value:"UV"
        },
        {
            id: 15,
            value:"WX"
        },
        {
            id: 16,
            value:"YZ"
        }
    ]);
    const fancyNav = React.useRef(null);
    const whiteNav = React.useRef(null);
    const fancyBtn = React.useRef(null);
    const whiteBtn = React.useRef(null);
    const onfancyClick = (e) => {
        SetCookies("fancy",1)
        RemoveCookies("white")
        formik.setFieldValue("Color","")
        fancyNav.current.classList.add("show");
        whiteNav.current.classList.remove("show");
        fancyBtn.current.classList.remove("text-primary");
        whiteBtn.current.classList.add("text-primary");
      };
      const onwhiteClick = (e) => {
        SetCookies("white",1)
        RemoveCookies("fancy")
        formik.setFieldValue("Fancy_Color","")
        formik.setFieldValue("Fancy_Color_Overtone","")
        formik.setFieldValue("Fancy_Color_Intensity","")
        fancyNav.current.classList.remove("show");
        whiteNav.current.classList.add("show");
        fancyBtn.current.classList.add("text-primary");
        whiteBtn.current.classList.remove("text-primary");
      };
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-0 mb-2">{language.CARAT_COLOR}</p>
                    <div className="submenu_link">
                        <span className="submenu_link pr-1" onClick={onwhiteClick} ref={whiteBtn}>{language.CARAT_WHITE}</span>/
                        <span className="text-primary submenu_link pl-1" onClick={onfancyClick} ref={fancyBtn}>{language.CARAT_FANCY}</span>
                    </div>
                </div>
                <div className="col-lg-12 color " ref={fancyNav}>
                    <div className="row w-100">
                        <div className="col-md-12 mb-1 font-weight-bold">
                            Intensity
                        </div>
                        <div className="col-md-12">
                            <MultiSelect language={language} options={IntensityData} label={"Select Intensity"} name={"Fancy_Color_Intensity"} formik={formik}/>
                            {/* {IntensityData.map((item) => (
                                <label key={item.id} className="search_btn_check iframe_setup">
                                    <Field type="checkbox" name="Fancy_Color_Intensity" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.intensity}</span>
                                    </div>
                                </label>
                            ))} */}
                        </div>
                    </div>
                    <div className="row mt-5 w-100">
                        <div className="col-md-12 mb-1 font-weight-bold">
                            Overtone
                        </div>
                        <div className="col-md-12">
                            <MultiSelect language={language} options={OvertoneData} label={"Select Overtone"} name={"Fancy_Color_Overtone"} formik={formik}/>
                            {/* {OvertoneData.map((item) => (
                                <label key={item.id} className="search_btn_check iframe_setup">
                                    <Field type="checkbox" name="Fancy_Color_Overtone" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.overtone}</span>
                                    </div>
                                </label>
                            ))} */}
                        </div>
                    </div>
                    <div className="row mt-5 w-100">
                        <div className="col-md-12 mb-1 font-weight-bold">
                            Color
                        </div>
                        <div className="col-md-12">
                            <MultiSelect language={language} options={ColorData} label={"Select Color"} name={"Fancy_Color"} formik={formik}/>
                            {/* {ColorData.map((item) => (
                                <label key={item.id} className="search_btn_check iframe_setup">
                                    <Field type="checkbox" name="Fancy_Color" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.color}</span>
                                    </div>
                                </label>
                            ))} */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 color show" ref={whiteNav}>
                    <MultiSelect language={language} options={whitecolorData} label={"Select Color"} name={"Color"} formik={formik}/>
                {/* {whitecolorData.map((item) => (
                        <label key={item.id} className="search_btn_check iframe_setup">
                            <Field type="checkbox" name="Color" id={`${item.value}_wcolor`} value={item.value}/>
                            <div className="finishBox selectBox">
                                <span>{item.value}</span>
                            </div>
                        </label>
                    ))} */}
                </div>
            </div>
        </>
    );
};
