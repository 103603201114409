import React from "react";
import { Modal, Button } from "react-bootstrap";
export const SwalWarn = (props) => {
    // console.log(props)
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center my-6">
                    <h2 className="mb-5 mt-5 text-center">{ props.title ? props.title : "Warning !"}</h2>
                    <div className="screenAlert-icon screenAlert-warning scaleWarning">
                            <span className="screenAlert-body pulseWarningIns"></span>
                            <span className="screenAlert-dot pulseWarningIns"></span>
                        </div>
                    <p className="font-size-h4 font-weight-bold my-10">{props.message ? props.message : "Warning"}</p>
                   {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4 btn-square px-10"
                    >
                        cancel
                    </Button> : ""}
                    <Button className="btn-square px-10"
                        variant={`${props.msgOnOk === "Natural Parcel Diamond" ? "primary" : props.msgOnOk === "Lab-Grown Parcel Diamond" ? "success" : "warning"}`}
                        onClick={() => {props.responseOnOk ? responseClose(props): closeModel(props) }}
                    >
                       {props.responseOnOk ? props.msgOnOk ? props.msgOnOk : "yes" : "close"}
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};
