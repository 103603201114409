import React, { useState,useEffect } from "react";
import { SearchResult } from "./parcelSearchResult/searchResult/SearchResult";
import { ParcelTrackList } from "./parcelSearchResult/trackList/ParcelTrackList";
import { BsLightningFill,BsArrowLeftShort } from "react-icons/bs";
import { ConfirmGoodsParcel } from "../Popup/ConfirmGoodsParcel";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import axios from "axios"
import { NODE_API_URL } from "../../../env_config";
import { SwalWarn } from "../Popup";

export const ParcelSearchResult = ({ userinfo, language,resultData,setResultData,setParcelSearchOpen,setParcelResultOpen,conversionrate,symbol }) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalWarnOpenCtPcs,setSwalWarnOpenCtPcs] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [totalOrder, setTotalOrder] = useState("0");
    const [totalDiamonds, setTotalDiamonds] = useState("0");
    const [totalDollarCarat, setTotalDollarCarat] = useState("0");
    const [totalPrice, setTotalPrice] = useState("0");
    const [totalCarat, setTotalCarat] = useState("0");
    const [searchResultData,setSearchResultData] =useState([])
    const [allResult,setAllResult] =useState([])
    const [trackListData,setTrackListData] =useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [checkMatch,setCheckMatch] = useState(false)
    const [temp, setTemp] = useState(true)
    const [dimondType, setDiamondType] =useState('');
    useEffect(() => {
        if(window.location.pathname === "/parcel-diamond"){
            setDiamondType("PN")
        }
        if(window.location.pathname === "/lab-parcel-diamond"){
            setDiamondType("PL")
        }
    },[])

    const count = () =>{
        let temptotalorder = 0
        let temptotaldiamonds = 0
        let temptotalprice = 0
        //  let temptotaldollarcarat = 0
        let diamondcarat = 0
        let tempTrackListtotalorder = 0
        let tempTrackListtotaldiamonds = 0
        let tempTrackListtotalprice = 0
        //  let tempTrackListtotaldollarcarat = 0
        let diamondTrackListcarat = 0
        searchResultData.map(value => {
            // const finddata = value.find(val => value.original.id.toString() === val.id.toString())
            temptotalorder += 1
            temptotaldiamonds += parseFloat(value.original.pcs)
            temptotalprice += parseFloat(value.original.totalPrice)
            diamondcarat += parseFloat(value.original.carat)
            // temptotaldollarcarat += parseFloat(value.original.totalPrice/value.original.carat)
            return {
                // ...finddata,
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
                // temptotaldollarcarat:temptotaldollarcarat
            }
        })

        trackListData.map(value => {
            // const finddata = value.find(val => value.original.id.toString() === val.id.toString())
            tempTrackListtotalorder += 1
            tempTrackListtotaldiamonds += parseFloat(value.original.pcs)
            tempTrackListtotalprice += parseFloat(value.original.totalPrice)
            diamondTrackListcarat += parseFloat(value.original.carat)
            // tempTrackListtotaldollarcarat += parseFloat(value.original.totalPrice/value.original.carat)
            return {
                // ...finddata,
                diamondTrackListcarat:diamondTrackListcarat,
                tempTrackListtotalprice:tempTrackListtotalprice,
                tempTrackListtotaldiamonds:tempTrackListtotaldiamonds,
                // tempTrackListtotaldollarcarat:tempTrackListtotaldollarcarat
            }
        })
        setTotalOrder(parseFloat(temptotalorder) + parseFloat(tempTrackListtotalorder))
        setTotalDiamonds(parseFloat(temptotaldiamonds) + parseFloat(tempTrackListtotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice) + parseFloat(tempTrackListtotalprice))
        setTotalCarat(parseFloat(diamondcarat) + parseFloat(diamondTrackListcarat))
        setTotalDollarCarat(tempTrackListtotalprice || temptotalprice !== 0 ?  parseFloat(tempTrackListtotalprice + temptotalprice) / parseFloat(diamondcarat + diamondTrackListcarat) : "00")
        const NewData = { data: [...searchResultData, ...trackListData] };
        setAllResult(NewData)
        // console.log("searchResultData",searchResultData)
        // console.log("trackListData",trackListData)
        // console.log("NewData",NewData)
    }

    useEffect(() => {
        count()
    },[searchResultData,trackListData])

    // console.log("tracklistdata",trackListData)
    const buyDiamond = () =>{
        if(searchResultData.length === 0 && trackListData.length === 0){
            alert("Please Select Diamonds")
            return
        }
        
        let showBuyPopUp = true; // Default to true
        const combinedData = [...trackListData, ...searchResultData];

        // Apply the condition check for the merged data
        checkCaratLimits(combinedData, setAlertMessage, setSwalWarnOpenCtPcs);

        // Outside the function, update setBuyPopUp based on the flag
        if (showBuyPopUp) {
            setBuyPopUp(true);
        }
        
        function checkCaratLimits(records, setAlertMessage, setSwalWarnOpenCtPcs) {
            let conditionMet = false;
            let maxCaratConditionMet = false;

            for (const record of records) {
                // if (record.original.carat < 0.05) {
                //     conditionMet = true;
                //     setAlertMessage(`Minimum order quantity is 0.05 carats. Please adjust your Carats. Contact us for assistance. Thank you.`);
                //     setSwalWarnOpenCtPcs(true);
                //     break;
                // }
        
                if (record.original.carat > 200) {
                    maxCaratConditionMet = true;
                    setAlertMessage(`Maximum order quantity is 200.00 carats. Please adjust your Carats. Contact us for assistance. Thank you.`);
                    setSwalWarnOpenCtPcs(true);
                    break;
                }
            }
        
            // If maxCaratConditionMet is true, set the flag based on that condition
            // Otherwise, set the flag based on the general conditionMet
            showBuyPopUp = maxCaratConditionMet ? !maxCaratConditionMet : !conditionMet;
        }

       
        if (searchResultData.length !== 0){

            for(let i = 0;i < trackListData.length;i++){
                let shapematch = false
                let lengthmatch = false
                let widthmatch = false
                let colormatch = false
                let claritymatch = false

                let trackshape = trackListData[i].original.C_Shape
                let searchshape = searchResultData[0].original.C_Shape ? searchResultData[0].original.C_Shape : "ROUND"
                if(trackshape === searchshape){
                    shapematch = true
                }

                let tracklengthmm = trackListData[i].original.mm_to
                let searchlengthmm = searchResultData[0].original.mm_to
                if(tracklengthmm === searchlengthmm){
                    lengthmatch = true
                }

                let trackwidthmm = trackListData[i].original.mm_from
                let searchwidthmm = searchResultData[0].original.mm_from
                if(trackwidthmm === searchwidthmm){
                    widthmatch = true
                }
                
                let trackcolor= trackListData[i].original.color
                let searchcolor= searchResultData[0].original.color
                if(trackcolor === searchcolor){
                    colormatch = true
                }

                let trackclarity= trackListData[i].original.clarities
                let searchclarity= searchResultData[0].original.clarities
                if(trackclarity === searchclarity){
                    claritymatch = true
                }
                
                if(shapematch && lengthmatch && widthmatch && colormatch && claritymatch){
                    setSwalWarnOpen(true)
                    setAlertMessage(`you are buying same parcel category from track list, are you sure you want to buy ?`)
                    setCheckMatch(false)
                    return
                }
            }
        }
        if (showBuyPopUp) {
            setBuyPopUp(true);
        }
        // console.log("here")
    }
    useEffect(() => {
        if(checkMatch){
            setSwalWarnOpen(false)
            setBuyPopUp(true)
            setCheckMatch(false)
            // console.log("searchResultData")
        }
    },[checkMatch])
    // console.log(symbol,conversionrate)
    const addTracklist = () =>{
        if(searchResultData.length === 0 ){
            alert("Please Select Diamonds")
            return
        }
        const finalData = {
            "user_id":userinfo.id,
            "c_shape": searchResultData[0].original.C_Shape ? searchResultData[0].original.C_Shape : "ROUND",
            "weight": searchResultData[0].original.carat,
            "avgweight": searchResultData[0].original.avgweight,
            "dollarPerCarat": searchResultData[0].original.dollarPerCarat,
            "sumprice":searchResultData[0].original.sumprice,
            // "totalPrice": searchResultData[0].original.totalPrice,
            "sieve_mm_id_from": `${searchResultData[0].original.sieve_mm_id_from}`,
            "sieve_mm_id_to": `${searchResultData[0].original.sieve_mm_id_to}`,
            "sieve_from": `${searchResultData[0].original.Sieve_from}`,
            "sieve_to": `${searchResultData[0].original.Sieve_to}`,
            "mm_to":`${searchResultData[0].original.mm_to}`,
            "mm_from":`${searchResultData[0].original.mm_from}`,
            // "clarities":searchResultData[0].original.clarities,
            // "color":searchResultData[0].original.color,
            "clarity_id":  searchResultData[0].original.clarities_id,
            "color_id": searchResultData[0].original.color_id,
            "pieces": searchResultData[0].original.pcs,
            // "country": userinfo.country,
            "diamond_type":dimondType,
            "order_type":searchResultData[0].original.type
        }
        axios({
            method: "POST",
            url: NODE_API_URL + "/parcel/insertTrackListDiamond",
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data) {
                    alert("Stone tracklisted")
                    setTemp(!temp)
                    setResultData([])
                //    console.log(res.data)
                //    setStoneData(res.data)
                }
            })
            .catch((err) => {
                console.log(err.response.data)
            });
    }

    const swalCloseFn = () =>{
        setSwalWarnOpen(false) 
        setSwalWarnOpenCtPcs(false) 
        // setCheckMatch(false)
    }

    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>
            <ConfirmGoodsParcel userinfo={userinfo} show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={allResult} totalOrder={totalOrder} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalCarat={totalCarat} searchResultData={searchResultData} trackListData={trackListData} language={language} dimondType={dimondType} symbol={symbol} conversionrate={conversionrate}/> 
            <div className="parcelResult">
                <div className="row">
                    <div className="col-12">
                        <div className="card card-custom shadow-none">
                            { window.innerWidth > 700 ? 
                              null
                            :
                                <div className="card-header p-5 text-right sticky-top bg-white"style={{top:"50px"}}>
                                    <span onClick={()=>{setParcelResultOpen(false); setParcelSearchOpen(true);}}><BsArrowLeftShort /> Back to Search</span> 
                                </div>
                            }
                            <div className="card-body d-flex justify-content-between align-items-center border-bottom flex-wrap pb-4 mb-5">
                                <div>
                                    <div className="card-label font-weight-bold font-size-h4 text-primary">{language.PARCEL_DIAMONDS_SEARCH_RESULT}</div>
                                    <div className="text-muted font-weight-bold font-size-sm">
                                    {language.PARCEL_DIAMONDS_TOTAL_ORDER}: {totalOrder || "00"} <span className="px-2">|</span>  
                                    {language.SEARCHLIST_TOTALCARAT} : {parseFloat(totalCarat).toFixed(2) || "00"}  <span className="px-2">|</span>  
                                    {language.PARCEL_DIAMONDS_TOTAL}
                                    {conversionrate !== 1 ?
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    $/Ct : ${parseFloat(totalDollarCarat).toFixed(2) || "0"}
                                                </Tooltip>
                                            }
                                        >
                                            <span> {symbol}/Ct : {symbol}{numberWithCommas(parseFloat(totalDollarCarat * conversionrate)) || "0"}</span>
                                        </OverlayTrigger>
                                        : <> {symbol}/Ct : {symbol}{numberWithCommas(parseFloat(totalDollarCarat * conversionrate)) || "0"}</> }
                                    <span className="px-2">|</span>  
                                    <span className="text-primary">{language.PARCEL_DIAMONDS_TOTAL_PRICE} :
                                    {conversionrate !== 1 ?
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    ${(totalPrice).toLocaleString() || "00"}
                                                </Tooltip>
                                            }
                                        >
                                            <span> {symbol}{numberWithCommas(parseFloat(totalPrice * conversionrate)) || "00"}</span>
                                        </OverlayTrigger>
                                        : <>{symbol}{numberWithCommas(parseFloat(totalPrice * conversionrate)) || "00"}</>} 
                                    </span>
                                    </div>
                                </div>
                                {/* <div className="font-size-h5 font-weight-bold">
                                    Search Result
                                </div> 
                                <div className="font-weight-lg">
                                    <p className="mb-1">
                                        <span className="font-weight-bold">
                                            Total Order :
                                        </span>{" "}
                                        <span className="text-primary">{totalOrder || "00"}</span>
                                    </p>
                                    <p className="mb-1">
                                        <span className="font-weight-bold">
                                            Total Carat :
                                        </span>{" "}
                                        <span className="text-primary">{parseFloat(totalCarat).toFixed(2) || "00"}</span>
                                    </p>
                                </div>
                                <div className="font-weight-lg">
                                    <p className="text-muted mb-1">
                                        <span className="font-weight-bold">
                                            Total $/Ct :
                                        </span>{" "}
                                        <span className="text-primary">
                                            ${parseFloat(totalDollarCarat).toFixed(2) || "0"}
                                        </span>
                                    </p>
                                    <p className="mb-1 font-size-h4">
                                        <span className="font-weight-bold">
                                            Total Price :
                                        </span>{" "}
                                        <span className="text-primary">${(totalPrice).toLocaleString() || "00"}</span>
                                    </p>
                                    
                                </div> */}
                                <div className="card-toolbar flex-md-nowrap">
                                    <button
                                        type="button"
                                        className="btn btn-primary font-weight-bold btn-sm mr-2 text-nowrap"
                                        onClick={()=>buyDiamond()}
                                    >
                                        <BsLightningFill /> {language.SEARCHLIST_BUYDIAMONDBTN}
                                    </button>
                                    {trackListData.length === 0 && (resultData.length !== 0 && !resultData.parcel_id)? 
                                        <OverlayTrigger
                                            placement="top"
                                            className="text-capitalize"
                                            overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                                        >
                                            <span className="btn btn-primary btn-icon btn-sm" onClick={()=>addTracklist()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 20.844 24.063" className="btn_whs_svg">
                                                    <path id="Subtraction_1" data-name="Subtraction 1" d="M27.087, 552.075V528H47.952v24.072L37.52, 543.046l-10.433, 9.028h0Zm8.23-21.066a2.6, 2.6, 0, 0, 0-1.841, 4.452l4.045, 4.045, 4.044-4.045a2.6, 2.6, 0, 1, 0-3.683-3.682l-0.362.362-0.363-.362A2.589, 2.589, 0, 0, 0, 35.317, 531.009Z" fill="#fff" stroke="#fff" transform="translate(-27.094 -528)"></path>
                                                </svg>
                                            </span>
                                        </OverlayTrigger> 
                                    : " "}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => swalCloseFn()} responseOnOk={()=> setCheckMatch(true)}/> 
                    <SwalWarn show={swalWarnOpenCtPcs} message={alertMessage} onClose={() => swalCloseFn()} /> 
                    {/* {resultData.length !== 0 ? */}
                        <div className="col-12 pb-5 border-bottom">
                            <SearchResult  symbol={symbol} conversionrate={conversionrate} language={language} userinfo={userinfo} setSearchResultData={setSearchResultData} resultData={resultData} dimondType={dimondType} />
                        </div> 
                    {/* :""} */}
                    <div className="col-12">
                        <ParcelTrackList symbol={symbol} conversionrate={conversionrate} language={language} userinfo={userinfo} setTrackListData={setTrackListData} temp={temp} dimondType={dimondType}  />
                    </div>
                </div>
            </div>
        </>
       
    );
};
