/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import {FaLongArrowAltDown,FaLongArrowAltUp} from "react-icons/fa"
import { Tracklist } from "./tracklist/Tracklist";
import axios from "axios";
import { IVPCURRENCY } from "../../../../env_config";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NODE_API_URL } from "../../../../env_config";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};


export const DashboardTracklist = ({
    symbol,
    conversionrate,
    itemInWishList,
    language
}) => {
    const [data, setData] = useState(null);
    const [currency, setCurrency] = useState("USD");
    const [currentCurrRate,setCurrentCurrRate] = useState("1")
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const finalData = {
            user_id: userData.CustomerId,
        };
        let curr = "cur_inr"
        if(currency === "INR"){
          curr = "cur_inr"
        }
        if(currency === "USD"){
          curr = "cur_usd"
        }
        if(currency === "AUD"){
          curr = "cur_aud"
        }
        if(currency === "CAD"){
          curr = "cur_cad"
        }
        if(currency === "HKD"){
          curr = "cur_hkd"
        }
        if(currency === "CNY"){
          curr = "cur_cny"
        }
        if(currency === "EUR"){
          curr = "cur_eur"
        }
        if(currency === "GBP"){
          curr = "cur_gbp"
        }
        if(currency === "NZD"){
          curr = "cur_nzd"
        }
        if(currency === "JPY"){
          curr = "cur_jpy" 
        }
        if(currency === "CHF"){
          curr = "cur_chf"
        }
        if(currency === "BND"){
            curr = "cur_bnd"
        }
        if(currency === "SGD"){
            curr = "cur_sgd"
        }
        if(currency === "THB"){
            curr = "cur_thb"
        }
        if(currency === "MYR"){
            curr = "cur_myr"
        }
        if(currency === "DKK"){
            curr = "cur_dkk"
        }
        if(currency === "SEK"){
            curr = "cur_sek"
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchTracklistExpandStoneWise`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data && res.data.data) {
                    setData(res.data.data);
                    setCurrency(res.data.data.currency);
                }
            })
            .catch((err) => {
                console.error(err);
            });

            axios({
                method: "POST",
                url: IVPCURRENCY,
                headers: { "Content-Type": "application/json" },
            }).then(response => {
                if(response && response.data){
                    // console.log(response.data.data[0])
                    response.data.data[0].cur_usd = 1
                    const currentCurrency = response.data.data[0][curr];
                    setCurrentCurrRate(Math.round(response.data.data[0][curr]*100)/100)
                    // console.log(curr,currentCurrency)
                }
            })
    }, []);

    return (
        <>
            <div className={`card card-custom gutter-b`}>
                <div className="card-body dasboardSearch">
                    <div className="d-flex justify-content-between ">
                        <div>
                            <span className="small_title">{language.DASHBOARD_MYTRACKLIST}</span>
                            {/* <div>
                        <a href="#" className="text-success"><FaLongArrowAltDown className="text-success" />Price Drop</a>
                        <a href="#" className="text-danger"><FaLongArrowAltUp className="text-danger" />Price Hike</a>
                    </div> */}
                        </div>
                        {itemInWishList === "0" ? (
                            " "
                        ) : (
                            <Link to={{ pathname:'/wishlist' }}>
                                {language.DASHBOARD_MYTRACKLIST_SEEALL}
                            </Link>
                        )}
                    </div>
                    {itemInWishList === "0" ? (
                        <div className="d-flex flex-nowrap tracklistdata justify-content-center align-items-center my-10 pb-4">
                            NO Records Found
                        </div>
                    ) : (
                        <PerfectScrollbar
                            options={perfectScrollbarOptions}
                            className="scroll tracklistdata"
                        >
                            {/* <div className=" custom-scrollbar"> */}
                                {data
                                    ? data.sort((a, b) => {
                                        return new Date(b.tracklisted_date) - new Date(a.tracklisted_date);
                                      }).slice(0, 5).map((val) => {
                                        return (
                                            <Tracklist
                                                key={val.id}
                                                symbol={symbol}
                                                conversionrate={conversionrate}
                                                currentCurrRate={currentCurrRate}
                                                {...val}
                                            />
                                        );
                                    })
                                    : <></>}
                            {/* </div> */}
                        </PerfectScrollbar>

                    )}
                </div>
            </div>
        </>
    );
};
