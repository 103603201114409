import React, {useMemo} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useExpanded } from 'react-table';

// import StockAPI from "../StockAPI.json"
import { MockDocTableColumn } from './Columns'; 
import { FaSortDown, FaSortUp } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";


const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const InvoiceDetailTable = (invoicedata) => {
    // const [pageSize, setPageSize] = React.useState(3)
    // console.log(data.diamonddetail,"data")
    const columns = useMemo(()=> MockDocTableColumn(invoicedata.diamonddetail,invoicedata.language),[] )
    const data = useMemo(()=> invoicedata.diamonddetail,[invoicedata.diamonddetail])
    // const [data,setData] = ([stoneData.stoneData])
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // nextPage,
        // previousPage,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // gotoPage,
        // pageCount,
        // setPageSize,
        // state:{  pageIndex,pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 1000 }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
       
    )
    return (
        <>
            <div className="table-responsive w-100 bg-white">
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll border-bottom"
                    style={{ maxHeight: "342px", position: "relative" }}
                >
                    <table className="table table-hover" {...getTableProps()}>
                        <thead className="custom_datatable_head sticky-top" >
                            {headerGroups.map(headerGroup =>(
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    { headerGroup.headers.map((column)=>(
                                        <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="text-secondary">
                                                {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.length > 0 && page.map((row) => {
                                prepareRow(row)
                                return(
                                    <>
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    </>
                                )
                            }) }
                        </tbody>
                    </table>
                </PerfectScrollbar>
                <div className="text-primary font-weight-bold mt-4 font-italic pl-2 pb-2" >{page.length > 4 ? "Scroll for explore all diamonds":""}</div>
                {/* <div className="d-flex align-items-center justify-content-between p-5 border-top">
                    <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                    <div>
                        <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                        <span className="font-weight-bold text-muted">{'  '}
                            <input
                                className="form-control d-inline-block form-control-sm"
                                type="number"
                                value={pageIndex + 1}
                                // defaultValue={pageIndex + 1}
                                onChange={ e => { 
                                    const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(pageNumber)
                                }}
                                style={{maxWidth:'70px'}}
                            />
                            {'  '}of{'  '} {pageOptions.length}{'  '}
                        </span>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                        <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                    </div>
                    <span className="d-flex align-items-center">
                    <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                        <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                            {
                                [5 ,10, 25, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>{pageSize}</option>
                                ))
                            }
                        </select>
                    </span>
                </div> */}
            </div>
        </>
    )
}