import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';

// import StockAPI from "./StockAPI.json"
import { MockShortListColumn } from './Columns'; 
import { GlobleFilter } from '../../tableFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";
// import { CheckBox } from './CheckBox';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
// import  {ExpandedDetails} from "./Expand/ExpandedDetails"
import axios from 'axios';
import { NODE_API_URL } from '../../../../env_config';
import ApiDataSkeleton from './ApiDataSkeleton'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SwalWarn } from '../../Popup';
export const ApiDataLab = (props) => {
    const [swalopen,setSwalOpen] = useState(false)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    // const renderRowSubComponent = React.useCallback(
    //     (row) => (
    //         <>
    //             <ExpandedDetails row={row}/>
    //         </>
    //     ),[]
    //   )
    const [update,setUpdate] = useState()
    // const [stockexpire,setStockExpire] = useState([])
    const [erpireddate,setExpiredDate] = useState([])
    const [data,setData] = useState([])
    useEffect(() => {
        // const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = {
            "diamond_type":"L"
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchSupplierFeed`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data && res.data.data){
                // setData(res.data.data)
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/API/fetchSupplierRequest`,
                    headers: { "Content-Type": "application/json" },
                    data: {
                        "user_id":props.userinfo.id,
                        "api_id":props.feedsetting.api_id
                    },
                }).then(supreq => {
                    if(supreq && supreq.data && supreq.data.data){
                        // setData(res.data.data)
                        let merged = [];

                        // for (let i = 0; i < supreq.data.data.length; i++) {
                        //     merged.push({
                        //         ...supreq.data.data[i],
                        //         ...(res.data.data.find((itmInner) => itmInner.id === supreq.data.data[i].supplier_id))
                        //     }
                        //     );
                        // }
                        for (let i = 0; i < res.data.data.length; i++) {
                            const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_id === res.data.data[i].id && itmInner.api_id === props.feedsetting.api_id))} 
                            // console.log(fetcheddata,"fetcheddata")
                            merged.push({
                                ...res.data.data[i],
                                ...fetcheddata
                            }
                            );
                        }
                        // console.log(merged,"merged")
                        merged = merged.filter(value => {
                            if(value.supplier_name){
                                return value
                            }
                        })
                        setData(merged,"merged")
                    }
                })
            }
        })
    },[update])
    // console.log(data,"CCMODEDATAAA")
    useEffect(() => {
        let finaloutput = []
        let promises = []
        for(let i = 0;i < data.length; i++){
            promises.push(
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/fetchSingleSupplierDiasense`,
                headers: { "Content-Type": "application/json" },
                data: {
                    "diasencename":data[i].supplier_name_o
                },
            }).then(res => {
                if(res && res.data && res.data.data){
                    finaloutput.push({
                        ...data[i],
                        disanceobj:res.data.data
                    })
                }
            }))

        }
        Promise.all(promises).then(() => setExpiredDate(finaloutput));
    },[data])
    // console.log(erpireddate,"erpireddate")
    const columns = useMemo(()=> MockShortListColumn(setUpdate,update,erpireddate,toast,props.language,props.userinfo,props.feedsetting),[erpireddate] )
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    // Cell: ({ row }) => (
                    //     <span  {...row.getToggleRowExpandedProps()}>
                    //         {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  row.original.ShortListedStones?<FaChevronRight className="text-primary" title="Expand"/>:""}
                    //     </span>
                    // ),
                    // },
                    ...columns,
                ]
            })
        }
    )
    const [apistatus,setApiStatus] = useState()
    const TurnOnorOffAll = (api_on_off) => {
        setSwalOpen(true)
        setApiStatus(api_on_off)
        // axios({
        //     method: "POST",
        //     url: `${NODE_API_URL}/API/turnOnorOffAll`,
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //         user_id:props.userinfo.id,
        //         api_id:props.feedsetting.api_id,
        //         type:"Natural",
        //         api_on_off:api_on_off
        //     },
        // }).then(supreq => {
        //     if(supreq && supreq.data){
        //         if(supreq.data){
        //         setUpdate(supreq.data)
        //         if(api_on_off === 1){
        //             toast("Your All Feed Turn on",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }else{
        //             toast("Your All Feed Turn Off",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }
        //         }
        //     }
        // })
    }
    const FinalOk = (api_on_off) => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/turnOnorOffAll`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id,
                api_id:props.feedsetting.api_id,
                type:"Lab Grown",
                api_on_off:api_on_off
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data){
                setUpdate(supreq.data)
                setSwalOpen(false)

                if(api_on_off === 1){
                    toast.success("Your All Feed Turn on")
                }else{
                    toast.error("Your All Feed Turn Off")
                }
                }
            }
        })
    }
    return (
        <>
        {!data.length > 0 ? <ApiDataSkeleton /> : 
        <div>
          <SwalWarn show={swalopen} message={apistatus === 1?"are you sure you want to turn on all?":"are you sure you want to turn off all?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => FinalOk(apistatus)}/> 
            <div className="row">
                <div className="col-12 py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div><GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} /></div>
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                    <div  className="p-3" >
                                        <label className="checkbox my-2 border p-2">
                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                            <span className="mr-2"></span>
                                            Show All
                                        </label> 
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    <div className="search_result_dropdown">
                        <Dropdown >
                            <Dropdown.Toggle size="sm" variant="clear">
                                <button className='btn btn-icon btn-light-success p-0'><FaEllipsisV /></button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <Dropdown.Item onClick={() => TurnOnorOffAll(1)}>Turn On All</Dropdown.Item>
                                <Dropdown.Item onClick={() => TurnOnorOffAll(2)}>Turn Off All</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-12 pt-7 pb-0 mt-n3">
                    <div className="table-responsive w-100">
                        <table className="table table-striped table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {/* {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null} */}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}