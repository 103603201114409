import React from "react";
import { RecentDiamondCard } from "./RecentDiamond/RecentDiamondCard";
// import { Tracklist } from "./tracklist/Tracklist";
import axios from "axios";
import { FETCH_RECENTLY_DATA } from "../../../../env_config";

export const RecentDiamondTile = (prop) => {
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);

    const [data, setData] = React.useState([]);
    const [updaterecently,setUpdateRecently] = React.useState()
    function getData() {
        const finalData = {
            user_id: user.CustomerId,
        };

        axios({
            method: "POST",
            url: FETCH_RECENTLY_DATA,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                // console.log("data", res);
                if (res && res.data) {
                    setData(res.data);
                }
            })
            .catch((res) => {
                console.error("rec_data", res);
                // console.error("finalData", finalData);
            });
    }

    React.useEffect(() => {
        getData();
    }, [updaterecently]);

    if (data === undefined) {
        return;
    }

    return (
        <>
            {/* {console.log("dataaaa", data.length)} */}
            <div className={`card card-custom gutter-b`}>
                <div className="card-body recentlyViewedDash">
                    <span className="small_title">
                        {prop.language.DASHBOARD_RECENTLYVIEWED}
                    </span>
                    {data.length == "0" ? (
                        <div className="d-flex flex-nowrap justify-content-center align-items-center customRecently my-10 pb-4">
                            No Records Found
                        </div>
                    ) : (
                        <div className="d-flex flex-nowrap customRecently custom-scrollbar my-10">
                            {/* <div className="custom-scrollbar"> */}
                                {data
                                    ? data.slice(0, 4).map((val) => {
                                          return (
                                              <RecentDiamondCard
                                                  key={val.id}
                                                  {...prop}
                                                  {...val}
                                                  setUpdateRecently={setUpdateRecently}
                                              />
                                          );
                                      })
                                    : null}
                            {/* </div> */}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
