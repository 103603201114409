import React, { useState,useEffect,useRef} from "react";
import { useParams } from "react-router-dom";
// import { GetDiamondDB,StoreDiamondDB } from "../../_helpers/IndexedDB";
import {Modal,Button,OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { FaCalendarAlt,FaInfoCircle,FaShareAlt } from "react-icons/fa";
import {toAbsoluteUrl} from "../../_helpers";
import { NODE_API_URL} from "../../../env_config";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Paper,
    Tabs,
    Tab,
    Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import { BsArrowLeftShort,BsLightningFill } from "react-icons/bs";
import { FaExternalLinkAlt} from "react-icons/fa";
import VideoIframe from "./VideoIframe";
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError } from "../Popup";
import DetailSkeleton from "./DetailSkeleton"
import { BuyDiamond } from "../Popup/BuyDiamond";
import { ImageSharePopup } from "../widgets/tiles/ImageSharePopup";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function DetailPageHC({conversionrate,symbol,language,symbolname,userinfo,logStoredRef}){
    // console.log(conversionrate,"DETAILSS")
    const [value, setValue] = React.useState(0); // for tab
    const {OrderId} = useParams()
    const [diamondsearch,setDiamondSearch] = useState([])
    // const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);
    const [confid,setConfId] = useState("")
    const [supstatus,setSupStatus] = useState(null)
    const [swalerror,setSwalError] = useState(false)
    const [errormsg,setErrorMsg] = useState("")
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(false)
    // const swalWarnOpenfn = (props) => {
    //     setSwalWarnOpen(true)
    // }

    const holdWarn = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("This stone already in your hold order")
    }

    // const closeSwal = (props) => {
    //     // props.onHide()
    //     setSwalWarnOpen(false)
    // }

    function handleTabChange(event, newValue) {
        setValue(newValue);
      }

    useEffect(() => {
        setLoader(true)
        const Order_id = OrderId.split('-')[1]
        const userData = JSON.parse(localStorage.getItem("userData"))

        const finalData = {
            "order_id":Order_id,
            "user_id":userData.CustomerId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/HoldConfirmDetail`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(async(res) => {
            setLoader(false)

            if(res && res.data){
                setData(res.data)
                if(res.data.is_hold === 1){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/API/GetHoldOrder`,
                        headers: { "Content-Type": "application/json" },
                        data: {
                            user_id:userData.CustomerId,
                            certificate:res.data.Certi_NO
                        },
                    }).then(conres => {
                        if(conres && conres.data && conres.data.success){
                            setConfId(conres.data.conform_good_id)
                            setSupStatus(conres.data.sup_status)
                        }
                    })
                }
                if(!logStoredRef.current && userinfo.id){
                    logStoredRef.current = true
                    const getipadress = await axios.get("https://api.ipify.org")
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/API/expandAndDetailsLogAPI`,
                        headers: { "Content-Type": "application/json" },
                        data: {
                            userid:userinfo.id,
                            Certi_no:res.data.Certi_NO,
                            C_Name:res.data.C_Name,
                            type:"Details",
                            ip:getipadress.data
                        },
                    }).then(response => {
                    })
                }
                // console.log(data, res.data)
            }
        }).catch(err => {
            setLoader(false)

        })
    },[updatewishlist])
    const [updatewishlist,setUpdateWishlist] = useState()

    // console.log(data)
    useEffect(() => {
        if(data.length !==0){
            const userData = JSON.parse(localStorage.getItem("userData"))
            // const Diamond_Type = OrderId.split('-')[0]
            // const Certi_No = OrderId.split('-')[1]
            const finalData = {
                "user_id":userData.CustomerId,
                "productid":data.Certi_NO,
                "WLDiamondType":data.diamond_type === "L"?"L":"N"
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/StoreRecentlyViewedDiamond`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then(res => {
                // if(res && res.data){
                //     setData(res.data)
                //     console.log(data, res.data)
                // }
            })
        }
    },[data])
    // console.log(wishlistdata,"wishlistdata")
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    let ans ;
    if (diamondsearch !== undefined) {
        const cxProduct = data;
            ans = diamondsearch.filter((ele) => {
            if (ele.C_Weight>=cxProduct.C_Weight-0.1 && ele.C_Weight<=cxProduct.C_Weight+0.1 ) 
                {
                    if (ele.ProductId !== cxProduct.ProductId &&
                    ele.C_Shape === cxProduct.C_Shape &&
                    ele.C_Color === cxProduct.C_Color &&
                    ele.C_Cut === cxProduct.C_Cut &&
                    (ele.ClarityCalc>=cxProduct.ClarityCalc-1 && ele.ClarityCalc<=cxProduct.ClarityCalc+1))
                    {
                        ele.absPrice = Math.abs(cxProduct.OurPrice - ele.OurPrice)
                        return ele;
                    }
                }
            })
        function sortByProperty(property) {
            return function (a, b) {
                if (a[property] > b[property])
                    return 1;
                else if (a[property] < b[property])
                    return -1;

                return 0;
            }
        }
        ans.sort(sortByProperty("absPrice"))
    }
      // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------

    
    function handleClose(event, reason) {
        setSuccessOpen(false)
        setOpen(false);
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }
  
    const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }

    function certificateLink(){
        if (data.Lab === "IGI"){
            const  certificate_link = `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
            return certificate_link
        } else if (data.Certificate_link){
            const certificate_link = data.Certificate_link
            return certificate_link
        }
    }

    const [diamondselected,setDiamondSelected] = useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [buyholdpopup,setBuyHoldPopUp] = useState(false)
    const [showimage,setShowImage] = useState(false)
    // console.log(showimage,":showimage")
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)

 
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    const BuyDiamondBtn = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate
            }
        ]
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(diamondcount)
        // console.log(selectedvalues,"selectedvalues")
      }
      const BuyDiamondHoldBtn = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        if(supstatus === 2 || supstatus === 4){
            setSwalError(true)
            setErrorMsg("Stone not available to buy, Please contact to your Sales Executive ..")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate,
                shippingdays:data.shipping_days,
                "OurPrice(USD)":data.our_price,
                "USD/CT":data.our_rate,
                diamond_type:data.diamond_type === "L"?"L":"N",
                conform_good_id:confid
            }
        ]
        setDiamondSelected(selectedvalues)
        setBuyHoldPopUp(true)
        setBuyPopUpInr(data.T_C_Rate)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(1)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate,
                discount_main:data.our_discount
            }
        ]
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(diamondcount)
        // console.log(selectedvalues,"selectedvalues")
      }
      const ShareBtn = () => {
        navigator.clipboard.writeText(window.location.href)
        setShowImage(true)
      }
      function numberWithCommas(x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
return (
        <>
        {loader ? <DetailSkeleton/> :
            data.length !==0 ?
                <div className={`container product_detail ${ data.diamond_type === "L" ? "labTab" : "natTab" }`}>
                    <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                    <SwalError show={swalerror} message={errormsg} onClose={() => setSwalError(false)} responseOnOk={() => setSwalError(false)}/>
                    <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                    <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>
                    <BuyDiamond show={buyholdpopup} onHide={() => setBuyHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} diamondcount={diamondcount} totalourprice={buypopupctotal} language={language}/>  
                    <ImageSharePopup show={showimage} onClose={() => setShowImage(false)} src={window.location.href} msg={"Stone URL Copied"}/>
                    
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-body">
                            <div className="row">
                                {/* data: {JSON.stringify(data)} */}
                                {/* <div className="col-12">
                                    <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {language.DETAIL_GOTORESULT}</span>
                                </div> */}
                                <div className="col-md-6 d-flex flex-lg-nowrap flex-wrap justify-content-center">
                                    
                                    <div className="detail_img_box mt-4 order-lg-2 ribbon ribbon-top ribbon-left">
                                    {data.diamond_type === "N" || data.diamond_type === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                                        {data.aws_image ? (
                                            <img
                                                className="img-fluid"
                                                alt={data.C_Shape}
                                                src={data.aws_image}
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/${data.C_Shape ? data.C_Shape.toLowerCase() : ""}-no.png`
                                                        )
                                                    }}
                                            />
                                        ) : (
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                )}
                                            />
                                        )}
                                    </div>
                                    <ul className="img_thumb_box mt-4 ml-5 pl-0 d-flex d-lg-block order-lg-1">
                                        <li className="img_thumb mr-md-0 mr-1">
                                            {data.aws_image ? (
                                                <img
                                                    className="img thumb"
                                                    alt={data.C_Shape}
                                                    src={data.aws_image}
                                                />
                                            ) : (
                                                <img
                                                    className="img thumb"
                                                    alt="shape"
                                                    
                                                    src={toAbsoluteUrl(
                                                        `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                    )}
                                                />
                                            )}
                                        </li>
                                        {data.video  ? (
                                            <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openVideoModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                                {/* <FaVideo className="font-size-sm text-primary cursor-pointer"  /> */}
                                                {/* <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/> */}
                                            </li>
                                        ):('')}
                                        {data.aws_heart ? (
                                            <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openHeartModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                            </li>
                                            
                                        ):('')}
                                        {data.aws_arrow ? (
                                            <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openArrowModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                            </li>
                                        ):('')}
                                        {data.aws_asset ? (
                                            <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openAssetModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                            </li>
                                        ):('')}
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12 my-3 d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold border-right pr-4 mr-4" >
                                                {data.Lab}:{data.Certi_NO ? (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-primary mx-2 font-weight-bold text-left"
                                                        href={
                                                            data.Lab === "IGI"
                                                                ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                                : data.Lab === "GIA"
                                                                ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                : data.Lab === "HRD"
                                                                ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                                : data.Lab === "GCAL"
                                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                : data.Certificate_link
                                                        }
                                                    >
                                                        {data.Certi_NO} <FaExternalLinkAlt />
                                                    </a>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="font-weight-bold">{language.MUI_STOCKNO}: <div className="text-primary font-weight-bold d-inline-block">{data.diamond_type === "W" ? "N" : data.diamond_type === "L" ? "L" : " "}-{data.id}</div>
                                                <span>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `Business Process`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `Guaranteed`
                                                                    : data.availability === "Not Available"
                                                                    ? `Not Available`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `Subject to Prior sale`
                                                                    : `Unknown`
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="img-fluid availIco ml-2"
                                                            alt={data.availability}
                                                            src={toAbsoluteUrl(`/media/svg/${
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `availability_primary.svg`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `availability_success.svg`
                                                                    : data.availability === "Not Available"
                                                                    ? `availability_danger.svg`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `availability_warning.svg`
                                                                    : `availability_blank_white.svg`
                                                            }`)}
                                                        />
                                                    
                                                    </OverlayTrigger>
                                                </span>
                                            </div>
                                            {/* <span className="font-weight-bold"> 
                                                {language.SEARCHLIST_LOCATION}: {data.country ? data.country : "-"}
                                                {data.country ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        className="text-capitalize"
                                                        overlay={<Tooltip id="tooltip">{data.country}</Tooltip>}
                                                    >
                                                        <img
                                                            className="img-fluid flagIco mx-4"
                                                            alt={data.country}
                                                            src={toAbsoluteUrl(`/media/map/${data.country.toLowerCase()}.png`)}
                                                        />
                                                    </OverlayTrigger>
                                                ) : (
                                                    null
                                                )}
                                            </span> */}
                                           
                                        </div>
                                        <div className="col-10 align-self-center">
                                            <span className="text-dark font-weight-bold text-capitalize font-size-h2">
                                                <span className="pr-2 font-size-h2">
                                                    {data.C_Shape}
                                                </span>
                                                <span className="pr-2 font-size-h2">
                                                    {data.C_Weight.toFixed(2)}
                                                </span>
                                                { (data.C_Color === "fancy") ?
                                                    (
                                                        <>
                                                            <span className="pr-2 font-size-h2">{data.f_intensity}</span>
                                                            <span className="pr-2 font-size-h2">{data.f_overtone}</span>
                                                            <span className="pr-2 font-size-h2">{data.f_color}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="pr-2 font-size-h2">{data.C_Color}</span>
                                                        </>
                                                    )
                                                }
                                                <span className="pr-2 font-size-h2">{data.C_Clarity}</span>
                                                <span className="pr-2 font-size-h2">{data.C_Cut}</span>
                                                <span className="pr-2 font-size-h2">{data.C_Polish}</span>
                                                <span className="pr-2 font-size-h2">{data.C_Symmetry}</span>
                                                <span className="pr-2 font-size-h2">{data.C_Fluorescence}</span>
                                            </span>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <>
                                                {/* shade */}
                                                {renderShadeLabel(data.shade)}
                                        
                                                { data.Milky && data.Milky.toLowerCase() === "no milky" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    NO MILKY
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }

                                                { data.Milky && data.Milky.toLowerCase() === "luster low" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    Luster Low
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }

                                                { data.Milky && data.Milky.toLowerCase() === "light milky" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    LIGHT MILKY
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }

                                                { data.Milky && data.Milky.toLowerCase() === "milky" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    MILKY
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }
                                                        {/* eyeclean */}
                                                        { data.EyeC && data.EyeC.toLowerCase() === "yes" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    100% Eye Clean
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }
                                                { data.EyeC && data.EyeC.toLowerCase() === "no" ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    No Eye Clean
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }

                                                {/* growthtype */}
                                                { data.diamond_type === "L" && (data.brown && data.brown === "CVD") ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    CVD
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }
                                                { data.diamond_type === "L" && (data.brown && data.brown === "HPHT") ?
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    HPHT
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                :
                                                    null
                                                }
                                                
                                                { (data.diamond_type === "L" ) && (data.lab_treat && data.lab_treat.toLowerCase() === "as grown") ? 
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    As Grown
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                : 
                                                    null
                                                }
                                                {/* natural treated */}
                                                { (data.diamond_type === "N" || data.diamond_type === "W") && (data.green && data.green.toLowerCase() !== "none")  ? 
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    Treated
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                                        </OverlayTrigger>
                                                    </span>
                                                : 
                                                    null
                                                }
                                            </>
                                        </div>
                                        <div className="col-12 mt-4 d-flex">
                                            {data.show_supplier === 0 ? (""):(
                                                <>
                                                        <span className="font-size-sm mr-5"><span className="text-dark font-size-sm font-weight-bold text-capitalize">{language.MUI_SUPPLIER}:</span><span className="text-capitalize">{data.C_Name}</span ></span>
                                                        <span className="font-weight-bold"> 
                                                            {language.SEARCHLIST_LOCATION}: {data.country ? data.country : "-"}
                                                            {data.country ? (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    className="text-capitalize"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">{data.country}</Tooltip>
                                                                    }
                                                                >
                                                                    <img
                                                                        className="flagIco mr-1"
                                                                        alt={data.country}
                                                                        src={toAbsoluteUrl(
                                                                            `/media/map/${data.country.toLowerCase()}.png`
                                                                        )}
                                                                        onError={e => {
                                                                            e.currentTarget.src =toAbsoluteUrl(
                                                                                `/media/map/other.png`
                                                                                )
                                                                            }}
                                                                    />
                                                                </OverlayTrigger>
                                                            ) : (
                                                                null
                                                            )}
                                                        </span>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-12 mt-4 d-flex">
                                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${data.list_rate}</span>
                                            <span className="font-size-lg text-dark-50 ml-8"><FaCalendarAlt className="text-danger mr-2" /><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {data.shipping_days}{" "}{language.MUI_DAYS} </>}</span>
                                        </div>
                                        {data.ct !== 1 ?<>
                                            <div className="col-12 mt-4">
                                                <div className="row">
                                                    {data.show_supplier === 0 ? (""):(
                                                        <div className="col-5">
                                                            <div className="price_border_right"></div>
                                                            <div className="px-0">
                                                                <h5 className="font-weight-bold">
                                                                    {language.MUI_SUPPLIERPRICE}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                Price Provideed by Supplier
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                                <hr className="w-25 ml-0 my-2"/>
                                                                <div className={`d-flex small ${(data.supplier_discount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.supplier_discount}% {(data.supplier_discount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                                {
                                                                    symbol !=="$" ? <>
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="tooltip">
                                                                                    ${parseFloat((data.supplier_price).toFixed(2)).toLocaleString()}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className="h1">
                                                                        <span className="small">{symbol}</span > <>{parseFloat((data.supplier_price * conversionrate).toFixed(2)).toLocaleString()}</>
                                                                    </span>
                                                                        </OverlayTrigger>
                                                                    </> :
                                                                    <span className="h1">
                                                                        <span className="small">{symbol}</span > <>{parseFloat((data.supplier_price * conversionrate).toFixed(2)).toLocaleString()}</>
                                                                    </span>
                                                                } 
                                                                {
                                                                    symbol !=="$" ? <>
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="tooltip">
                                                                                    ${parseFloat((data.supplier_rate).toFixed(2)).toLocaleString()}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{parseFloat((data.supplier_rate * conversionrate).toFixed(2)).toLocaleString()}</span></span>
                                                                        </OverlayTrigger>
                                                                    </> :
                                                                    <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{parseFloat((data.supplier_rate * conversionrate).toFixed(2)).toLocaleString()}</span></span>
                                                                } 
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col-5">
                                                        <div className="px-0">
                                                            <h5 className="font-weight-bold">
                                                                {language.MUI_OURPRICE}
                                                                {data.show_supplier === 0 ? (
                                                                        ""
                                                                    ):(
                                                                        <>
                                                                            <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                                            <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                                                {props => (
                                                                                    <Tooltip id="overlay-example" {...props}>
                                                                                    Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </Overlay>
                                                                        </>
                                                                    )}
                                                            </h5>
                                                            <hr className="w-25 ml-0 my-2"/>
                                                            <div className={`d-flex small ${(data.our_discount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.our_discount}% {(data.our_discount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                        
                                                            {
                                                                symbol !=="$" ? <>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                ${parseFloat((data.our_price).toFixed(2)).toLocaleString()}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span className="h1">
                                                                            <span className="small">{symbol}</span > <>{parseFloat((data.our_price * conversionrate).toFixed(2)).toLocaleString()}</>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </> :
                                                                <span className="h1">
                                                                    <span className="small">{symbol}</span > <>{parseFloat((data.our_price * conversionrate).toFixed(2)).toLocaleString()}</>
                                                                </span>
                                                            } 
                                                            {
                                                                symbol !=="$" ? <>
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                ${parseFloat((data.our_rate).toFixed(2)).toLocaleString()}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{parseFloat((data.our_rate * conversionrate).toFixed(2)).toLocaleString()}</span></span>
                                                                    </OverlayTrigger>
                                                                </> :
                                                                <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{parseFloat((data.our_rate * conversionrate).toFixed(2)).toLocaleString()}</span></span>
                                                            }  
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-4 d-flex">
                                                <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold"  onClick={() => (data.is_hold !== 1) ?  BuyDiamondBtn([data.id]) : BuyDiamondHoldBtn([data.id]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                                                <button type="button" className="btn btn-light-primary px-6 mr-3 font-rubik font-weight-bold btn-pill" onClick={() => (data.is_hold !== 1) ?  HoldDiamond([data.id]) : holdWarn() }> 
                                                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.51 319.47" height="20px">
                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                        <path className="cls-1" d="M54.24.26a16.67,16.67,0,0,0-9.87,6.83C42.63,9.83,1.08,89,.54,90.58a15.49,15.49,0,0,0,.2,8.77c.49,1.27,22.45,32.16,74.43,104.7,68,94.94,73.84,103,75.12,103.62,2.49,1.26,5,.76,7.28-1.45S306,104.93,307.46,102.09a12,12,0,0,0,1.06-8.74c-.34-1.36-7.15-14.69-22.46-44C274,26.23,263.7,6.74,263.21,6.05a14.45,14.45,0,0,0-6.85-5.3l-1.83-.7L155.18,0C93.07,0,55.23.07,54.24.26Zm79.2,21.32L113.27,50.76c-10.15,14.7-18.53,26.62-18.61,26.48S76.71,23,75.72,19.83l-.22-.69h59.65l-1.71,2.44Zm101.71-.77c-4.1,11.73-18.67,53.05-18.72,53.09S174.11,19.83,174,19.45c-.07-.21,10.47-.31,30.82-.31h30.93l-.59,1.67Zm-168,35.1c5.49,16.69,10,30.41,10,30.52s-11.86.21-26.34.21-26.34-.07-26.34-.15,7.26-14,16.14-30.92c9.78-18.68,16.22-30.63,16.35-30.38s4.71,14,10.21,30.72ZM178,56.36l23.26,30.12-22.32.08c-12.27,0-32.41,0-44.74,0l-22.43-.08L133,56.28c11.68-16.63,21.34-30.17,21.48-30.13S165.18,39.8,178,56.36Zm90.71,1c8.26,15.9,15,29,15,29.1s-11.57.18-25.74.18c-24.35,0-25.73,0-25.59-.57.21-.79,20.47-57.61,20.65-57.91.09-.15.24-.15.38,0s7,13.3,15.27,29.19ZM104.37,168.08c26.84,81.54,27,82.1,26.56,81.6-.29-.32-84.52-117.81-102-142.32l-1.06-1.48H83.89l20.48,62.2Zm100.49-62c0,.66-51.61,147.65-51.77,147.46s-48.55-147-48.55-147.46,100.32-.29,100.32,0Zm76.27.34c-4.43,6.19-105.72,143.29-105.8,143.21s11.2-32.46,25-72l25.16-71.82h28c27.06,0,28,0,27.6.56Z" fill="#0037bf"/>
                                                        <path className="cls-1" d="M325.92,202.66a99.35,99.35,0,0,0-194.9-3.1c-1.67,8-2,11.73-2,21.11s.53,14.27,2.19,21.46a99.93,99.93,0,0,0,82.34,76.57c6.36,1,22.87,1,29.22,0A99,99,0,0,0,299.94,289a93.36,93.36,0,0,0,17.5-25.16A98.5,98.5,0,0,0,325.92,202.66Z" fill="#0037bf"/>
                                                        <path className="cls-2" d="M272.8,287.88a14.71,14.71,0,0,1-6.12,5.58c-2,.93-2.22,1-5.71,1-6.33,0-4.21,1.68-29.79-23.9-20.27-20.27-22-22-22.82-23.88a17.82,17.82,0,0,1-1.22-4.09c-.42-2.91-.44-82.44,0-85.11a13.85,13.85,0,0,1,10.65-11.21,16.28,16.28,0,0,1,7.37.61,14.45,14.45,0,0,1,6.51,5.14c2.5,3.73,2.31.21,2.41,44l.09,39,18.9,19c11.56,11.59,19.21,19.49,19.68,20.32A14.46,14.46,0,0,1,272.8,287.88Z" fill="#efefef"/>
                                                    </g>
                                                </svg>
                                                    {language.MUI_HOLDDIAMONDBTN}
                                                </button>
                                                <button type="button" className="btn btn-light-primary px-6 mr-3 font-rubik font-weight-bold btn-pill" onClick={() => ShareBtn()}><FaShareAlt/>Share</button>
                                            </div>
                                        </> :("")}
                                        {/* <hr className="w-100 mt-5" />
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <h4 className="text-black mb-3">{language.DETAIL_HAVEQUESTION} </h4>
                                                    <p className="mb-3">{language.DETAIL_WRITEUS} <a href="mailto:inquiry@diamondsoncall.com" className="text-primary"> inquiry@diamondsoncall.com </a> </p>
                                                    <button type="button" className="btn btn-light-primary px-5" data-toggle="modal" data-target="#SendInquiry">{language.DETAIL_SENDINQUIRY}</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-header justify-content-center mb-3 min-card-header-height p-0">
                            <div className="card-title m-0">
                            <Paper position="static" className="shadow-none">
                                <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                    <Tab label={language.DETAIL_DIAMONDDETAILS} />
                                    <Tab label={language.MUI_PARAM_DETAILS} />
                                    <Tab label={language.MUI_ADDITIONALDETAILS}/>
                                </Tabs>
                            </Paper>
                                {/* <span className="card-label text-dark">
                                    DESCRIPTION
                                </span> */}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    {value === 0 && (
                                        <TabContainer >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    {/* <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> Diamond Details </div> */}
                                                    {/* <div className="row mb-1">
                                                        <div className="col-6"> Availability </div>
                                                        <div className="col-6 font-weight-bolder"> {data.Availability ? data.Availability : "-"} </div>
                                                    </div> */}
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_STOCKID} </div>
                                                        <div className="col-6 font-weight-bolder"> {data.diamond_type === "L"?"L":"N"}-{data.id ? data.id : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_SHAPE} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Shape ? data.C_Shape : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_CARAT} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Weight ? data.C_Weight.toFixed(2) : "-"} </div>
                                                    </div>
                                                
                                                    { (data.C_Color ==="fancy") ? (
                                                        <>
                                                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary">Fancy Color Details </div>
                                                            <div className="row mb-2">
                                                                <div className="col-6">{language.DETAIL_INTENSITY}</div>
                                                                <div className="col-6 font-weight-bolder">  {data.f_intensity ? data.f_intensity : "-"} </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <div className="col-6">{language.DETAIL_OVERTONE}</div>
                                                                <div className="col-6 font-weight-bolder">  {data.f_overtone ? data.f_overtone : "-"} </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-6">{language.SEARCHLIST_COLOR}</div>
                                                                <div className="col-6 font-weight-bolder">  {data.f_color ? data.f_color : "-"} </div>
                                                            </div>
                                                        </>
                                                    ):(
                                                        <div className="row mb-2">
                                                            <div className="col-6"> {language.SEARCHLIST_COLOR} </div>
                                                            <div className="col-6 font-weight-bolder">  {data.C_Color ? data.C_Color : "-"} </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_CLARITY} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Clarity ? data.C_Clarity : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_CUT} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Cut ? data.C_Cut : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_POL} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Polish ? data.C_Polish : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_SYMM} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Symmetry ? data.C_Symmetry : "-"} </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.SEARCHLIST_FLOU}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Fluorescence ? data.C_Fluorescence : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.SEARCHLIST_LAB}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.Lab ? data.Lab : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.SEARCHLIST_CERTIFICATE}</div>
                                                        <div className="col-6 font-weight-bolder">
                                                            <a
                                                                id={data.Certi_NO}
                                                                href={certificateLink()}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="font-size-sm text-primary"
                                                            >
                                                                {data.Certi_NO}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.SEARCHLIST_LOCATION}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.country ? data.country : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.SEARCHGRID_SHIPPINGDAYS}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.shipping_days ? <> {data.shipping_days}{" "}{language.MUI_DAYS} </> : "-"} </div>
                                                    </div>
                                                </div>
                                            </div >

                                        </TabContainer>
                                    )}
                                    {value === 1 && (
                                        <TabContainer >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_MEASUREMENT}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_Length} * {data.C_Width} * {data.C_Depth}  </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_TABLE} %</div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_TableP ? `${data.C_TableP}%` : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_DEPTH} %</div>
                                                        <div className="col-6 font-weight-bolder">  {data.C_DefthP ? `${data.C_DefthP}%` : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_CROWN}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.Crn_Ht ? `${data.Crn_Ht}%` : "-"}, {data.Crn_Ag ? `${data.Crn_Ag}°` : "-"}  </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_PAVILION}</div>
                                                        <div className="col-6 font-weight-bolder">  {data.Pav_Dp ? `${data.Pav_Dp}%` : "-"}, {data.Pav_Ag ? `${data.Pav_Ag}°` : "-"}  </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_GIRDLE}</div>
                                                        <div className="col-6 font-weight-bolder">   {data.gridle_per ? data.gridle_per : "-"}  </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_GIRDLECONDTION}</div>
                                                        <div className="col-6 font-weight-bolder">   {data.gridle ? data.gridle : "-"}  </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_GIRDLETHICK}</div>
                                                        <div className="col-6 font-weight-bolder">   {data.girdle_thick ? data.girdle_thick : "-"}  </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_GIRDLETHIN}</div>
                                                        <div className="col-6 font-weight-bolder">   {data.girdle_thin ? data.girdle_thin : "-"}  </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.MUI_CULET}</div>
                                                        <div className="col-6 font-weight-bolder">   {data.cutlet ? data.cutlet : "-"}  </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </TabContainer>
                                    )}
                                    {value === 2 && (
                                        <TabContainer >
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_SHADE} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.shade ? data.shade : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_MILKY} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.Milky ? data.Milky : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2"> 
                                                        <div className="col-6"> {language.MUI_EYECLEAN} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.EyeC ? data.EyeC : "-"} </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    {data.diamond_type === "L" ? 
                                                        <>
                                                            <div className="row mb-2">
                                                                <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                                <div className="col-6 font-weight-bolder">  {data.brown ? data.brown : "-"} </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                                <div className="col-6 font-weight-bolder">  {data.lab_treat === "Treated"?"Post Growth Treatment": data.lab_treat?data.lab_treat : "-"} </div>
                                                            </div>
                                                        </>
                                                    :   
                                                        <>
                                                            <div className="row mb-2">
                                                                <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                                <div className="col-6 font-weight-bolder">  {data.brown ? data.brown : "-"} </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <div className="col-6"> {language.MUI_BRAND} </div>
                                                                <div className="col-6 font-weight-bolder">  {data.canada_mark ? data.canada_mark : "-"} </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                                <div className="col-6 font-weight-bolder">  {data.green ? data.green : "-"} </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row mb-2"> 
                                                        <div className="col-6"> {language.MUI_KEY} </div>
                                                        <div className="col-6 font-weight-bolder">  {data.Key_Symbols ? data.Key_Symbols : "-"} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabContainer>
                                    )}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-header justify-content-center mb-3">
                            <div className="card-title m-0 text-primary">
                                {language.DETAIL_DIAMONDCERTIFICATE}
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <iframe frameBorder="0" src={data.Lab === "IGI" ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`  :  data.Lab === "GIA"  ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}` : data.Lab === "HRD" ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}` : data.Lab === "GCAL" ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}` : data.Certi_link} width="100%" height="600px" title="certificate"></iframe>
                        </div>
                    </div>
                    
                    <VideoIframe
                        showVideo={showVideo}
                        handleClose={handleClose}
                        // src={data.diamond_type === "L"?"L":"N" ? `https://pro360video.com/video.php?refno=${data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}` }
                        src={data.diamond_type === "L" ? `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}`:`https://pro360video.com/video.php?refno=${data.Certi_NO}`   }
                        // src={data.ext_video || ""}
                        data={data}                           
                    />
                    <Modal
                        show={showHeartImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_heart} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showArrowImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_arrow} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showAssetImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.aws_asset} alt={data.C_Shape} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={handleClose}
                            variant="error"
                            message="Stone already in tracklist"
                        />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={successOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={handleClose}
                            variant="success"
                            message="Stone tracklisted successfully"
                        />
                    </Snackbar>
                </div>
            :
                <div className="card custom-card gutter-b">
                    <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                        <div className="text-center">
                            <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                            <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>No Records Found</strong></div>
                            {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
