import React from "react";
import { Field } from "formik";

export const Brand = ({language}) => {
    const BrandData = ([
        {
            id: 1,
            Brand:"Canada Mark",
            value:"Canada Mark"
        },
        {
            id: 3,
            Brand:"Argyle",
            value:"argyle"
        },
        {
            id: 4,
            Brand:"Forever Mark",
            value:"Forever Mark"
        },
        {
            id: 5,
            Brand:"Other",
            value:"other"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.MUI_BRAND}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {BrandData.map((item) => (
                            <label key={`${item.id}_brand`} className="search_btn_check">
                                <Field type="checkbox" name="Brand" id={`${item.Brand}_Brand`} value={item.value}/>
                                <div className="treatBox selectBox">
                                    <span>{item.Brand}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};