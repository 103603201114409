/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react";
import axios from "axios"
import {
    CcmodeRequest,
    CcmodeRequested,
    CcmodeRejected,
    // CcmodeSetup,
    // CcmodeApproved,
    CcmodeSetupNew
} from "./"
import { NODE_API_URL } from "../../../env_config";
// import CcmodeRule from "./CcmodeRule/CcmodeRule";
import {CcmodeDashboard} from "./CcmodeDashboard";
import { useLocation} from "react-router-dom";
// import { Alert } from "react-bootstrap";
import { CCModeLogout } from "./CCModeLogOut";
export const Ccmode = (props) => {
    // console.log(edit,"setEdit")
    // console.log(userinfo.kyc_status,"userinfo")
    const [ccmodedata,setCCModeData] = useState()
    const [ccmodewhitelabeldata,setCCModeWhiteLabelData] = useState()
    const [ccmodesetting,setCCModeSetting] = useState()
    const [ccmoderules,setCCModeRules] = useState([])
    const [updaterule,setUpdateRules] = useState(false)
    const location = useLocation()
    const [AllowLogin,setAllowLogin] = useState(1)
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = {
            "user_id":userData.CustomerId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchCCMode`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data.AllowLogin,"CCMODEEEE")
                setCCModeData(res.data.data)
                // if(res.data.data.AllowLogin.data[0]){
                //     setAllowLogin(res.data.data.AllowLogin.data[0])
                // }
                // else{
                //     setAllowLogin(0)
                // }
            }
        })
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchCCModeWhiteLable`,
            headers: { "Content-Type": "application/json" },
            data: {
                CustomerId:finalData.user_id
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeWhiteLabelData(res.data.data)
            }
        })
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchCCModeSetting`,
            headers: { "Content-Type": "application/json" },
            data: {
                CustomerId:finalData.user_id
            },
        }).then(res => {
            if(res && res.data && res.data.data && res.data.data.length){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeSetting(res.data.data)
                // if(res.data.data[0].AllowLogin.data[0]){
                //     setAllowLogin(res.data.data[0].AllowLogin.data[0])
                // }
            }
        })
    },[])
    useEffect(() => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchCCModeRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id
            },
        }).then(res => {
            if(res && res.data && res.data.data && res.data.data.length){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                setCCModeRules(res.data.data)
            }
            else{
                setCCModeRules([])
            }
        })
    },[updaterule])
    // console.log(ccmoderules,"ccmoderulesccmoderules")
    return (
        <div className="container-fluid">
           {props.userinfo.kyc_status === "KYC_APPR"?<div className="card custom-card">
                <div className="card-body">
                  {ccmodedata === "Record Not Found!"?<CcmodeRequest language={props.language}/>:""}
                  {ccmodedata && ccmodedata.Status === 1?<CcmodeRequested language={props.language}/>:""}
                  {/* {ccmodedata && ccmodedata.Status === 2 && !ccmodewhitelabeldata?<CcmodeSetup userinfo={props.userinfo} language={props.language}/>:""} */}
                  {ccmodedata && ccmodedata.Status === 2 && !ccmodesetting?<CcmodeSetupNew userinfo={props.userinfo} language={props.language}/>:""}
                  {ccmodedata && ccmodedata.Status === 3?<CcmodeRejected language={props.language}/>:""}
                  {/* {ccmodewhitelabeldata?<CcmodeApproved userinfo={props.userinfo} language={props.language}/>:""} */}
                  {ccmodedata && ccmodedata.Status === 2 && ccmodesetting ? <CcmodeDashboard ccmodesetting={ccmodesetting} userinfo={props.userinfo} language={props.language} tabvalue={location.state} ccmoderules={ccmoderules} setUpdateRules={setUpdateRules} updaterule={updaterule}/>:""}
                  {/* <CcmodeDashboard userinfo={props.userinfo} language={props.language}/>
                  <CcmodeRule userinfo={props.userinfo} language={props.language}/> */}
                </div >
            </div >:<CCModeLogout/>}
        </div>
    );
};
