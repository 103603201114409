import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import axios from "axios";
import { symbol } from "prop-types";
import { NODE_API_URL } from "../../../env_config";
import { useHistory } from "react-router-dom";
import { SwalSuccess ,SwalError } from "./";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Backdrop,CircularProgress,} from "@material-ui/core";
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const ConfirmGoodChurned = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()

    const [loading, setLoading] = React.useState(false);
    const [isSubmitting ,SetIsSubmitting] = useState(false)
    const [openLoader, setOpenLoader] = React.useState(false);
    const [ip,setIp] = React.useState()
    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        SetIsSubmitting(true)
    };
    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        SetIsSubmitting(false)
    };

    const history = useHistory()
    useEffect(() => {
        SetComment(null)
        setShowConfirm(props.show)
        axios({
            method: 'GET',
            // url: "http://ip-api.com/json/?fields=status,country,region,regionName,city,district,zip,isp,mobile,proxy,hosting,query",
            url:"https://api.ipify.org",
            // headers: { 
            //     'Access-Control-Allow-Origin': '*', 
            //     'Content-Type': 'application/json'
            //   },
            })
            .then(function (res) {
                // setIpdata({query:res.data})
                if(res && res.data){
                    setIp(res.data)                    
                }
            })
            .catch((res) => {
            });
    },[props.show])
    const closeModel = () => {
        props.onHide()
        setShowConfirm(false)
    }
    const closeSwal = (props) => {
        // props.onHide()
        setShowConfirm(true)
        setSwalSuccessOpen(false)
    }
    const swalErrorOpenfn = (props) => {
        closeModel()
        setSwalErrorOpen(true)
    }
    
    const swalSuccessOpenfn = (props) => {
        closeModel()
        setSwalSuccessOpen(true)
    }
   
    // console.log(props,"props")
    const ConfirmDiamonds = (diamonds) => {
        // console.log(diamonds,"diamonds")
        const userData = JSON.parse(localStorage.getItem("userData"))
        let dianondinfo = diamonds.diamondselected.map(value => {
            return {
                diamond_id:value.id,
                diamond_type:value.diamond_type === "L"  ? value.diamond_type:"N",
                Certi_NO:value.Certi_NO
            }
        })
        const finalData = {
            "user_id":userData.CustomerId,
            "operation":"buy",
            "diamond_info":dianondinfo,
            "customer_comment":comment,
            "ip":ip
        }
        // console.log(finalData,"finalData")
        enableLoading()
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/churnedCXholdDiamond`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            disableLoading()
            // console.log("12346",res.data.success)
            if (res.data.success === false){
                swalErrorOpenfn()
                setAlertMessage(res.data.error)
            }
            if(res && res.data && res.data.success){
                // console.log("1234",res.data)
                // swalSuccessOpenfn()
                // setSwalSuccessOpen(true)
                // alert(JSON.stringify(res.data.data))
                setAlertMessage(res.data.data[0].status)
                for(let i = 0 ; i < res.data.data.length;i++){
                    if(res.data.data[i].issuccess === true){
                        swalSuccessOpenfn()
                        reDirectToConfirm()
                    } else if (res.data.data[i].issuccess === false || res.data.data[i].ssuccess===false){
                        swalErrorOpenfn()
                    }
                }
                SetComment(null)
            }
        }).catch(err => {
            disableLoading()
        })
    }

    const reDirectToConfirm = () => {
        history.push({
            pathname: `/confirmgoods`
        })
    }
    const [comment,SetComment] = useState()
    // console.log(comment,"COMMENT")
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <div>
            <Modal
                show={showConfirm}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND}</Modal.Title>
                    <div className="d-flex">
                        <button type="button" className="close" onClick={() => closeModel(props)}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3">
                            <span>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND} : {props.diamondselected.length}</span>
                            <div>
                                {((props.diamondselected.length) - props.diamondcount ) == 0 ||  props.diamondcount == 0? "":
                                 <div className="d-flex font-weight-bold">
                                    <div className="text-success mr-2">{props.diamondcount} Diamonds are valid</div> 
                                    <div className="text-danger">{((props.diamondselected.length) - props.diamondcount )} Diamonds are not available for delivery</div>
                                </div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll border-bottom"
                                style={{ maxHeight: "322px", position: "relative" }}
                            >
                                <table className="table border-bottom">
                                    <thead>
                                        <tr className="bg-gray-100 sticky-top">
                                            <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</th>
                                            <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION} <br /> {props.language.CONFIRM_GOODS_POPUP_DIAMOND_SHIPPING_DAYS}</th>
                                            <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEUSD} <br />
                                                USD/CT
                                            </th>
                                            {/* <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEINR} <br />
                                                INR/CT
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {props.diamondselected.map((value,index) => (
                                        <tr className={`position-relative`} key={index}>
                                            <td>
                                                {/* {value.country !== "INDIA"?
                                                    <span className="CanNotHold"> Unfortunately, Diamond location outside India cant be ordered</span>
                                                :""} */}
                                                {/* {value.can_hold === 1 ? "":  <span style={{fontSize:"8px",color:"red"}}> DIAMOND NOT AVAILABLE FOR HOLD</span> } */}
                                                <div className={`row`}>
                                                    <div className="col-2 text-center p-0">
                                                        {value.aws_image ? (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={value.aws_image} width="50px" />
                                                        ) : (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={toAbsoluteUrl(`/media/shape/${value.C_Shape.toLowerCase()}-no.png`)} width="50px" style={{ backgroundColor:"#f4f4f4", }} />
                                                        )}
                                                    </div>
                                                    <div className="col-10 pl-2 p-0">
                                                        <div className="mb-1">
                                                            <span
                                                                className={`${
                                                                    value.diamond_type === "N" || value.diamond_type === "W"
                                                                        ? value.C_Color === "fancy" ? "F" : "W"
                                                                        : value.C_Color === "fancy" ? "LF" : "L"
                                                                }_diamond mr-3 small`}
                                                            >
                                                                {value.diamond_type === "N" || value.diamond_type === "W"
                                                                    ? value.C_Color === "fancy" ? "F" : "W"
                                                                    : value.C_Color === "fancy" ? "LF" : "L" }
                                                            </span>
                                                            <span className="text-primary small">
                                                                {value.Lab}-
                                                                {value.Certi_NO}
                                                            </span>
                                                            <span className="small ml-2">
                                                                {value.diamond_type === "N" || value.diamond_type === "W" ? "N" : "L"}-{value.id}
                                                            </span>
                                                        </div>

                                                        <span className="text-dark font-weight-bold d-block">
                                                            {value.C_Shape}{" "}
                                                            {value.C_Weight.toFixed(2)}{" "}
                                                            {value.C_Color ===
                                                            "fancy" ? (
                                                                <>
                                                                    {value.f_intensity}{" "}
                                                                    {value.f_overtone}{" "}
                                                                    {value.f_color}{" "}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {value.C_Color}{" "}
                                                                </>
                                                            )}
                                                            {value.C_Clarity}{" "}
                                                            {value.C_Cut}{" "}
                                                            {value.C_Polish}{" "}
                                                            {value.C_Symmetry}{" "}
                                                            {value.C_Fluorescence}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`text-center `}>
                                                {value.country} <br />
                                                {value.shipping_days} days
                                            </td> 
                                            <td className={`text-right text-primary `}>
                                                ${numberWithCommas(value.OurPrice)}<br/>
                                                <span className="text-muted small">USD/CT {numberWithCommas(value.OurRate)}</span>
                                            </td>
                                            {/* <td className={`text-right text-primary `}>
                                                ₹{numberWithCommas(value.OurPrice)} <br />
                                                <span className="text-muted small">₹/CT {numberWithCommas(value.OurRate)}</span>
                                            </td> */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </PerfectScrollbar>
                            <div className="text-primary font-weight-bold mt-4 font-italic" >{props.diamondselected.length > 4 ? "Scroll for explore all diamonds":""}</div>
                            <div className="row mt-5">
                                <div className="col-12 font-size-h6 text-right">
                                Per USD/CT Price : ${numberWithCommas(props.totalinrcar)}
                                </div>
                                <div className="col-12 font-size-h6 mt-2 text-right font-weight-bolder">
                                    Total USD : ${numberWithCommas(props.totalourprice)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Form.Group controlId="Notes">
                                        <Form.Label>
                                            {props.language.CONFIRM_GOODS_POPUP_NOTES}:
                                        </Form.Label>
                                        <Form.Control as="textarea" rows="3" onChange={(e) => SetComment(e.target.value)} maxLength={256} placeholder="Please enter your notes if any"/>
                                    </Form.Group>
                                    <span className="p-2">255 Characters Remaining</span>
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(props)}</pre> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between py-3">
                    {props.conversionrate !== 1?<span className="font-weight-bold"> All purchase are under USD</span>:""}
                    <div>
                        <Button variant="secondary" className="mr-2 font-weight-bold" onClick={() => closeModel(props)}>
                            {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                        </Button>
                        <Button disabled={isSubmitting} className="px-8 font-weight-bold" variant="primary" onClick={() => ConfirmDiamonds(props)}>
                        Confirm Diamond
                            {loading && <span className="ml-1 spinner spinner-white"></span>}
                            <Backdrop
                                sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                open={openLoader}
                                // onClick={handleCloseLoader}
                            >
                                <CircularProgress color="secondary" />
                            </Backdrop>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <SwalSuccess show={swalSuccessOpen} message={alertMessage} onClose={() => setSwalSuccessOpen(false)} responseOnOk={reDirectToConfirm}/> 
            <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} closeBtn={true}/> 
        </div>
    );
};
