/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from 'react-router-dom';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import moment from "moment-timezone";

// function formattedDate(x) {
//     const originalTimestamp = new Date(x);
//     return originalTimestamp
// }

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const MockDocTableColumn = (conversionrate,symbol,history,RouteToProforma,data,language,userinfo) => [
    {
        Header: language.CONFIRM_DIAMOND_ORDERNO,
        className: "align-middle text-center",
        accessor: (d) => {
            return (
                <>
                    <span>{d.conform_good_id}</span>
                    <p className="m-0 small text-muted">
                        {/* {d.created_date} */}
                        { d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                    </p>
                </>
            );
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_DIAMONDDETAILS,
        accessor: (d) => (
            <>
            {d.diamond_type === "PN" || d.diamond_type === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.C_Shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.C_Shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamond_type}_diamond mr-3 small`}>
                                {d.diamond_type}
                            </span>
                            <span className={`text-primary cursor-pointer small ml-2`}>
                                {d.diamond_type}-{d.Stock_NO}
                            </span>
                            <span className={`text-primary text-capitalize small ml-2`}>
                                By: {d.type === "carat" ? "Cts." : "Pcs."}
                            </span>
                            <span className={`text-primary text-capitalize small ml-2`}>
                            {d.length_mm ? d.length_mm.toFixed(2):"" }-{d.width_mm?d.width_mm.toFixed(2):"" } MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight ? d.C_Weight.toFixed(2):""}Ct.{" "}
                            {d.pieces}Pcs.{" "}
                            {d.C_Color}{" "}
                            {d.C_Clarity}{" "}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image &&  d.aws_image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape ? d.C_Shape.toLowerCase() : ""}-no.png`
                                )}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamond_type === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                {d.diamond_type === "N" || d.diamond_type === "W" ? d.C_Color === "fancy"  ? "F" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <a href={`/details/hc-${d.conform_good_id}`} target="_blank" rel="noreferrer" className={`text-primary cursor-pointer small ml-2`} >
                                {d.diamond_type === "N" ? "N" : "L"}-{d.Stock_NO}
                            </a>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.Lab === "IGI"
                                        ? `https://www.igi.org/verify-your-report/?r=LG${d.Certi_NO}`
                                        : d.Lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.Certi_NO}`
                                        : d.Lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.Certi_NO}`
                                        : d.Lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.Certi_NO}`
                                        : d.Certi_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight}{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    // {
    //     Header:'Tag',
    //     className:"text-center align-middle mr-3",
    //     Cell: (row) => {
    //         return  <div style={{ maxWidth: "180px"}}>
    //             <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">NO SHADE</div>
    //             <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">EYECLEAN</div>
    //             <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">MILKY</div>
    //         </div>;
    //       }
    // },
    {
        Header: language.CONFIRM_DIAMOND_LOCATIONANDDELIVERY,
        accessor: (d) => (
            <>
                <span className="font-weight-bolder">{d.country}</span>
                <br />
                <span className="text-muted small">{d.shippingdays} days</span>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.CONFIRM_DIAMOND_STATUS,
        accessor: (d) => (
            <div>
                <div
                    className={`label ${
                            d.Dimond_Status === "Order Approve" ? "label-primary"
                            : d.Dimond_Status === "Pending" ? "label-warning"
                            : d.Dimond_Status === "Ready For Invoice" ? "label-success" 
                            : d.Dimond_Status === "Ready to Ship" ? "label-success" 
                            : d.Dimond_Status === "Rejected" ? "label-danger" 
                            : d.Dimond_Status === "QC Rejected" ? "label-danger" 
                            : d.Dimond_Status === "QC Review" ? "label-warning" 
                            : d.Dimond_Status === "QC Approved" ? "label-success" 
                            : ""
                    } label-inline  mr-2`}
                >
                    {d.Dimond_Status}
                </div>
            <br />
            {/* {d.perfoma_no ? <Link to={{pathname:"proforma-detail",query:{perfoma_no:d.perfoma_no,conform_good_id:d.conform_good_id}}}  className="label label-light-info label-inline label-sm small" >Proforma generated</Link> : ""} */}
            {d.perfoma_no ? <a onClick={() => RouteToProforma({query:{perfoma_no:d.perfoma_no,conform_good_id:d.conform_good_id}},data)} className="label label-light-info label-inline label-sm small">Pro Forma Inv No {d.perfoma_created_at}</a> : ""}
        
        </div>
        ),
        className: "text-center align-middle",
       
    },
    // {
    //     Header:'Diamond Journey',
    //     accessor:d => (
    //         <>
    //             <span className="font-weight-bolder">{d.canada_mark}</span><br />
    //             <span className="text-muted small">1-2 days</span>
    //         </>
    //       ),
    //     className:"text-center align-middle",
    // },
    {
        Header: language.CONFIRM_DIAMOND_DISCOUNT,
        className: "text-center text-danger align-middle",
        accessor: (d) => Number(d.discount_main).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return (
                <>
                    {row.row.original.diamond_type === "PN" || row.row.original.diamond_type === "PL" ? "-" :
                        <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>
                    }
                </>
            );
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_OURPRICECT,
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${numberWithCommas(parseFloat(d["OurPrice(INR)"]))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT {numberWithCommas(parseFloat(d["INR/Ct"]))}
                </span>
            </>
        ),
        className: "text-right pr-12 align-middle",
    },
    {
        Header: language.CONFIRM_DIAMOND_TRACK,
        accessor: (d) => (
            <>
                <span className="font-weight-bolder">
                {d["track"]}
                </span>
               
            </>
        ),
        className: "text-center align-middle",
    },
    // {
    //     Header:'Payment Status',
    //     accessor:'CUT',
    //     className:"text-center align-middle",
    // }
];
