import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Crown } from "./Preference1/Crown";
import { Girdle } from "./Preference1/Girdle";
import { GirdleCondition } from "./Preference1/GirdleCondition";
import { Measurement } from "./Preference1/Measurement"
import { Metrics } from "./Preference1/Metrics";
import { Pavallion } from "./Preference1/Pavallion";
import { Percentage } from "./Preference1/Percentage";

export const Preference1 = (props) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {props.language.SEARCHDIAMOND_PREFERENCE}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Measurement language={props.language} />
                           <Percentage language={props.language}/>
                           <Metrics language={props.language}/>
                           <Crown language={props.language}/>
                           <Pavallion language={props.language}/>
                           {/* <Girdle language={props.language}/> */}
                           {/* <GirdleCondition language={props.language}/> */}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
