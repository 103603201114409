/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from 'react'
import { useParams } from "react-router-dom";
import {InvoiceDetailTable} from "./InvoiceDetailTable"
import {
    Paper,
    Tabs,
    Tab,
    Typography,
    makeStyles,
    Snackbar,
    SnackbarContent,
} from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";
import PropTypes from "prop-types";
import { NODE_API_URL } from '../../../../env_config';
import axios from 'axios';
import InvoiceDetailSkeleton from '../InvoiceDetailSkeleton';
import { BsArrowLeftShort } from "react-icons/bs";
import moment from "moment-timezone";

  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const InviceDetail = (props) => {
    const [value, setValue] = React.useState(0); // for tab
    const [data,setData] = React.useState([])
    const [invData,setInvData] = React.useState([])
    const [stoneData, setStoneData] = React.useState();
    const {OrderId} = useParams()
    // console.log("OrderId",OrderId)
    function handleTabChange(event, newValue) {
        setValue(newValue);
    }
    const [cgst,setCGST] = useState(0)
    const [sgst,setSGST] = useState(0)
    const [tax,setTAX] = useState(0)
    const [chargetax,setChargeTax] = useState(0)
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState();
    function handleClose(event, reason) {
        setAlertOpen(false);
    }
  
    const useStyles = makeStyles((theme) => ({
        success: {
            backgroundColor: green[600],
        },
        
    }));
  
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
  
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }
    useEffect(() => {
        // alert(data.length)
        
        const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = { 
            "order_id":OrderId,
            "client_id":userData.CustomerId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/invoiceDetailPage`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then((res) => {
            // console.log("INVOICE DATA", res.data);
            if (res && res.data) {
                // setDashData(res.data);
                // setSalesData(res.data.salesExecutiveDetail);
                setInvData(res.data)
                setData(res.data[0])
                // console.log(res.data,"DATATAT111")
                setStoneData(res.data[0].expand)
                setChargeTax((((res.data[0].convenience_Fee + res.data[0].discount_extra) * 18)/100))
                if(res.data[0].state === "maharashtra"){
                    setCGST(((res.data[0].diamond_price_INR * 0.75)/100))
                    setSGST(((res.data[0].diamond_price_INR * 0.75)/100)) 
    
                }else{
                    setTAX(((res.data[0].diamond_price_INR * 1.5)/100))
                }

            }
        }).catch(error => {
            // console.log(finalData,"ERROR")
        })
    },[])
    function numberWithCommas(x) {
        if(x){
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }
    const GetPDF = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://diamondsoncall.online/assets/pdf_invoice/${data.bill_invoice_sale}`, "_blank")
        }
        else{
            window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${data.bill_invoice_sale}`, "_blank")
        }
    }
    const SendEmail = () => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = {
            "client_id":userData.CustomerId,
            "order_id":OrderId,
            "conform_good_id":data.confirm_goods_id.split(',')
        }
       
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/emailInvoice`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data){
                setAlertMessage(res.data)
                if(res.data){
                    setAlertOpen(true)
                    // alert(res.data)
                }
            }
        }).catch(err => {

        })
    }
    const TrackOrder = (invoicedata) => {
        if(invoicedata.pre_carriage === "UPS"){
            window.open(`https://wwwapps.ups.com/WebTracking?AgreeToTermsAndConditions=yes&loc=en_IN&tracknum=${invoicedata.tracking_no}&Requester=trkinppg/trackdetails`)
        }
        if(invoicedata.pre_carriage === "FEDEX"){
            window.open(`https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${invoicedata.tracking_no}&locale=en_US&cntry_code=us`)
            
        }
    }
    return (
        <>
        {invData.length > 0 ?
           <>
           {data ? <>
                <div className="row bg-white m-0 border-left-primary mb-5">
                    <div className="col-12 px-5 py-2">
                        <div className="mb-0 font-size-h2 font-weight-bolder d-block tex-dark"> {props.language.PROFORMA_DETAIL_DOCLLP} </div>
                        {/* <div className="text-muted">{props.language.INVOICE_DETAIL_GSTIN} 27AASFD2652D1ZF</div > */}
                        <div className="text-muted">{props.language.INVOICE_DETAIL_ADDRESS}: UNIT E-F, 8/F, 8 Hart Avenue,Tsim Sha Tsui, Kowloon,Hong Kong</div >
                    </div>
                </div>
                <div className="card custom-card gutter-b">
                    <div className="card-header py-3 px-5 d-flex justify-content-between align-items-center border-bottom-0">
                        <div className="card-title mb-0 ">
                            <span className="font-size-h2 font-weight-bolder d-block">{props.language.INVOICE_DETAIL_INVOICE_DETAIL}: #{invData[0].invoice_country === "IND-EXP"?invData[0].export_no:data.invoicenumber} {invData[0].invoice_country === "IND-EXP"?"(Shipment From India)":""}</span>
                            <span className="text-muted d-block">
                                {props.language.INVOICE_DETAIL_INVOICED_ON}
                                {/* {data.invoice_date} */}{" "}
                                { data.invoice_date ? moment.tz(data.invoice_date, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                                </span >
                        </div>
                        <div className="card-toolbar">
                    <span className="btn btn-light-primary mr-3"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {props.language.BACK}</span>
                            <button className="btn btn-light-primary mr-3" onClick={SendEmail}>{props.language.PROFORMA_DETAIL_SEND_EMAIL}</button>
                            <button className="btn btn-light-primary" onClick={GetPDF}>{props.language.PROFORMA_DETAIL_SEND_PDF}</button>
                        </div>
                    </div>
                    <div className="card-body p-0 pb-8">
                        <Paper position="static" className="shadow-none border-bottom">
                            <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                <Tab label={props.language.PROFORMA_DETAIL_DETAILS} />
                                {/* <Tab label="History" disabled /> */}
                                {/* <Tab label="Additional Details"/> */}
                            </Tabs>
                        </Paper>
                         {value === 0 && (
                            <TabContainer >
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_SHIPPING_ADDRESS}</div>
                                        <div className="text-capitalize">
                                           {data.shipping_address ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  data.shipping_address.replace(/[']/g, '')} } />: '-'}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold  mb-2">{props.language.PROFORMA_DETAIL_BILLING_ADDRESS}</div>
                                        <div className="text-capitalize">
                                           {data.billing_address ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  data.billing_address.replace(/[']/g, '')} } />: data.shipping_address ?  <div dangerouslySetInnerHTML={ {__html:  data.shipping_address.replace(/[']/g, '')} } />: '-'}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_LOGISTICS_INFORMATION}</div>
                                        <div className=""> {data.pre_carriage ? data.pre_carriage : null} </div>
                                        {data.tracking_no?<div>Track Your Package: <a onClick={() => TrackOrder(data)} target="_blank" rel='referal' href='#'>{data.tracking_no}</a></div>:""}
                                        {/* <a href='#' target="_blank" rel='referal'>Track Your Package</a> */}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}</div>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.PROFORMA_DETAIL_DIAMONDS}:</span>
                                                <span> {data.total_diamond ? data.total_diamond : null} {props.language.PROFORMA_DETAIL_PCS}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.SEARCHLIST_TOTALCARAT}:</span>
                                                <span>{data.total_carat ? data.total_carat : null}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.SEARCHGRID_TOTAL} $/CT:</span>
                                                <span>${numberWithCommas(parseFloat(data["totalPriceCT(INR)"]))}</span>
                                            </div>
                                            <div className="col-12 d-flex justify-content-between">
                                                <span>{props.language.INVOICE_DETAIL_TOTAL_PRICE}:</span>
                                                <span>${numberWithCommas(parseFloat(data.diamond_price_INR))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabContainer>
                        )}
                        {/* {value === 1 && (
                            <TabContainer >
                                2
                            </TabContainer>
                        )} */}
                    </div>
                </div>
                <div className="card custom-card gutter-b">
                    <div className="card-header py-5 border-bottom-0">
                        <div className="card-title mb-0 font-size-h4 font-weight-bolder">{props.language.PROFORMA_DETAIL_DIAMONDS_INFORMATION}</div>
                    </div>
                    <div className="card-body px-2 p-0">
                       {stoneData ? <InvoiceDetailTable diamonddetail={stoneData} language={props.language}/> : null }
                        <div className="row p-5 mt-5">
                            <div className="col-md-9">
                                {/* <div className="font-size-h4 font-weight-bold mb-2">NOTES</div>
                                <div>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                </div> */}
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>USD/CT:</span>
                                        <span className='font-size-h5 font-weight-bold'>{data["totalPriceCT(INR)"] ?"$" + numberWithCommas(parseFloat( data["totalPriceCT(INR)"])) : null}</span>
                                    </div>
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.INVOICE_DIAMOND_PRICE}:</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(data.diamond_price_INR))}</span>
                                    </div>
                                    {data.discount_extra?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{data.discount_extra>0?props.language.INVOICE_EXTRA_CHARGES:props.language.INVOICE_DISCOUNT}</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(data.discount_extra)}</span>
                                </div>:""}
                                    {data.convenience_Fee?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{invData[0].invoice_country === "IND-EXP"?"Shipping Charges":props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(data.convenience_Fee)}</span>
                                    </div>:""}
                                    {/* {cgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_CGST}(0.75%):</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(cgst)}</span>
                                </div>:""} */}
                                {/* {sgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_SGST}(0.75%):</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(sgst)}</span>
                                </div>:""} */}
                                {/* {tax?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_IGST}(1.5%):</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(tax)}</span>
                                </div>:""} */}
                                {/* <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_CHARGE_TAX}(18%):</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(chargetax))}</span>
                                    </div> */}
                                    {data.vip_discount?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}:</span>
                                        <span className='font-size-h5 font-weight-bold text-danger'>${-numberWithCommas(data.vip_discount)}</span>
                                    </div>:""}
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.REQ_TOTAL_DIAMOND_PRICE}:</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(data.diamond_price_INR + data.convenience_Fee - data.vip_discount + data.discount_extra))}</span>
                                    </div>
                                    {data.credit_card_charge?<div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.INVOICE_CREDIT_CARD_CHARGES}:</span>
                                        <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(data.credit_card_charge))}</span>
                                    </div>:""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card custom-card gutter-b">
                    <div className="card-body">
                        <div className="font-size-h4 font-weight-bold  mb-2">{props.language.INVOICE_DETAIL_PAYMENT_METHOD}</div>
                        <div className="">
                            {data.payment_method}
                        </div>
                    </div>
                </div>
            </> :  <div className="col-12 text-center">No Records Found for this invoice number</div> }
           </>
           : <InvoiceDetailSkeleton /> } 
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={alertMessage}
                />
            </Snackbar>
        </>
    )
}