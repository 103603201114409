import React ,{useState} from "react";
import { Grid, InputLabel,TextField,makeStyles,Snackbar,SnackbarContent } from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";
// import { InputField, DiamTypeCheck ,DatePickerField, PhoneInput, SelectField,MultiSelect } from "../FormFields";
import { Accordion, Card ,OverlayTrigger,Tooltip,Button} from "react-bootstrap";
import { FaCopy } from 'react-icons/fa';
import { TbRefresh } from 'react-icons/tb';
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { Alert } from "react-bootstrap";
import { NODE_API_URL } from "../../../../../env_config";
const useStyles = makeStyles(theme => ({
    // container: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    // },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1),
      width: 400,
      backgroundColor: '#F3F6F9',
    },
    dense: {
        color: '#000000b8'
    //   marginTop: theme.spacing(2),
    },
    success: {
        backgroundColor: green[600],
    },
    // menu: {
    //   width: 200,
    // },
  }));


export default function EmbededCode(props) {
    const [alertOpen, setAlertOpen] = React.useState(false);
    function handleClose(event, reason) {
        setAlertOpen(false);
    }
    const classes = useStyles();

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    const copylabcode = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_ENV === "production"?`<iframe src='https://plugin.diazoom.com/docplugin/${props.customeriframe.lab_diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`:`<iframe src='https://betaplugin.diamondsupload.com/docplugin/${props.customeriframe.lab_diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`)
        // alert("Copied to clipboard")
        setAlertOpen(true);
    }

const copynaturalcode = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_ENV === "production"?`<iframe src='https://plugin.diazoom.com/docplugin/${props.customeriframe.diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`:`<iframe src='https://betaplugin.diamondsupload.com/docplugin/${props.customeriframe.diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`)
        // alert("Copied to clipboard")
        setAlertOpen(true);
    }

    const {
        // formField: {
        //   naturaldiamond,
        //   naturalfancydiamond,
        //   lgwhitediam,
        //   lgfancydiam
        // },
        // setFieldValue,
        values
    } = props;
    const history = useHistory();
    const [regenerate,setRegenerate] = useState()
    const Regenerate = (key) => {
        axios({
            method: "POST",
            url: NODE_API_URL + "/API/generateIframeToken",
            headers: { "Content-Type": "application/json" },
            data: {
              "key":key,
              "user_id":props.customeriframe.customer_id,
              "api_id":props.customeriframe.api_id
            },
          }).then((res) => {
            if (res && res.data) {
                props.setUpdateKey(res.data)
                setRegenerate(true)
            }
          });
    }
    return (
        <div style={{height:'50vh'}}>
            <Accordion className="ccmode_accordion mb-10" defaultActiveKey="1">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="1" key="1">
                        {props.language.SETTING_EMBEDDED}
                    </Accordion.Toggle>
                    {regenerate === true?<Alert variant="success">
                    Your New Embedded Code generated..
                </Alert>:""}
                    <Accordion.Collapse eventKey="1">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                            {values.naturaldiamond || values.naturalfancydiamond?
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={4}>
                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                {props.language.DASHBOARD_SEARCH_NATURAL_DIAMOND}
                                            </InputLabel>
                                        </Grid>
                                            <Grid className="d-flex align-items-end position-relative" item xs={8}>
                                                <TextField
                                                    value={process.env.REACT_APP_ENV === "production"?`<iframe src='https://plugin.diazoom.com/docplugin/${props.customeriframe.diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`:`<iframe src='https://betaplugin.diamondsupload.com/docplugin/${props.customeriframe.diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`}
                                                    className={clsx(classes.textField, classes.dense)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    multiline
                                                    label="Natural Diamond"
                                                    rows="6"
                                                    disabled={true}
                                                />
                                                <span className="embIcons">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Copy embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FaCopy
                                                            onClick={() => copynaturalcode()}
                                                        />
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Re-generate embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <TbRefresh className="ml-2"
                                                        onClick={() => Regenerate("N")}
                                                        />
                                                    </OverlayTrigger>
                                                </span>
                                                <Button className="btn btn-sm btn-primary mb-1 ml-2" onClick={() => history.push({
                                                        pathname: "/iframe-preview",
                                                        state: props.customeriframe.diamond_key,
                                                            // state: decompress(res.data.ProductDetails),
                                                        })} >{props.language.SETTING_DIAMOND_PREVIEW}</Button>
                                                {/* <button className="btn btn-sm btn-primary mb-1 ml-2">{props.language.SETTING_DIAMOND_PREVIEW}</button> */}
                                            </Grid>
                                    </Grid>
                                    <hr className="w-100 m-0 mt-8" />
                                </Grid>
                            :""}
                            {values.lgwhitediam || values.lgfancydiam?
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={4}>
                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                {props.language.DASHBOARD_SEARCH_LAB_DIAMOND}
                                            </InputLabel>
                                        </Grid>
                                            <Grid className="d-flex align-items-end position-relative" item xs={8}>
                                                <TextField
                                                    value={process.env.REACT_APP_ENV === "production"?`<iframe src='https://plugin.diazoom.com/docplugin/${props.customeriframe.lab_diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`:`<iframe src='https://betaplugin.diamondsupload.com/docplugin/${props.customeriframe.lab_diamond_key}' title='Embeded Code' height='100%' width='100%' style='border: none;'></iframe>`}
                                                    className={clsx(classes.textField, classes.dense)}
                                                    margin="dense"
                                                    variant="outlined"
                                                    multiline
                                                    label="Lab Diamond"
                                                    rows="6"
                                                    disabled={true}
                                                />
                                               <span className="embIcons">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Copy embeded code
                                                            </Tooltip>
                                                        }

                                                    >
                                                        <FaCopy 
                                                        onClick={() => copylabcode()}
                                                        />
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Re-generate embeded code
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <TbRefresh className="ml-2"
                                                        onClick={() => Regenerate("L")}
                                                        />
                                                    </OverlayTrigger>
                                                </span>
                                                <Button className="btn btn-sm btn-primary mb-1 ml-2" onClick={() => history.push({
                                                        pathname: "/iframe-preview",
                                                        state: props.customeriframe.lab_diamond_key,
                                                            // state: decompress(res.data.ProductDetails),
                                                        })}>{props.language.SETTING_DIAMOND_PREVIEW}</Button>
                                            </Grid>
                                    </Grid>
                                    <hr className="w-100 m-0 mt-8" />
                                </Grid>
                            :""}
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={alertOpen}
                    autoHideDuration={2000}
                    onClose={handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={handleClose}
                        variant="success"
                        message={`Embedded code copied to clipboard`}
                    />
                </Snackbar>
        </div>
    );
}