/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from "react";
import { FaCog,FaTrashAlt,FaRegEdit,FaUser } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import teal from "material-ui/colors/teal";
import { OverlayTrigger, Tooltip,Alert } from "react-bootstrap";
import {
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
    Typography,
    withStyles
  } from '@material-ui/core';
  import PropTypes from "prop-types";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { SwalWarn } from "../Popup";
import { toast } from "react-toastify";
import {ShortListDetail} from "./ShortList/ShortListDetail/ShortListDetail"
import { ShortListTable } from "./ShortList/ShortListTable";
import { ShortListTableByMail } from "./ShortList/shortListByMail/ShortListTableByMail";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const CustomSwitch = withStyles({
    switchBase: {
      color: teal[300],
      '&$checked': {
        color: teal[500],
      },
      '&$checked + $track': {
        backgroundColor: teal[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

export const IframeDashboard = (props) => {
    const history = useHistory()
    const [shortlistSwitch ,setShortlistSwitch] = useState(true)
    const [shortlistSwitchByMail ,setShortlistSwitchByMail] = useState(false)
    const [shortlistDataByMail ,setShortlistDataByMail] = useState([])
    const [updaterule] = useState("")
    const [swalopen,setSwalOpen] = useState(false)
    const [deleteObj,setDeleteObj] = useState(null)
    const [value, setValue] = useState(0);
    // console.log(props.customeriframeorapi[0])
    const DeleteRule = () => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/iframeDeleteRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:deleteObj.user_id,
                rule_id:deleteObj.rule_id
            },
        }).then(res => {
            if(res && res.data){
                // console.log(res.data.data,"CCMODEEEEWHITELABEL")
                props.setUpdateRules(!props.updaterule)
                setSwalOpen(false)
                setDeleteObj(null)
            }
        })
    }
    const SetDeleteRule = (rule) => {
        setDeleteObj(rule)
        setSwalOpen(true)
    }
    function handleTabChange(event, newValue) {
        window.glob = newValue
        setValue(newValue);
    }
    const UpdateStatus = (rule) => {
        let rulestatus
        if(rule.status === 1){
            rulestatus = 0
        }
        else{
            rulestatus = 1
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/updateIframeRuleStatus`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:rule.user_id,
                rule_id:rule.rule_id,
                status:rulestatus
            },
        }).then(res => {
            if(res && res.data && res.data.data){
                props.setUpdateRules(!props.updaterule)
                if (rulestatus === 1) {
                    toast.success(`Rule Name ${rule.rule_name} turned on`)
                }
                else {
                    toast.error(`Rule Name ${rule.rule_name} turned off`)
                }
            }
        })
    }
    const ViewSupplier = (data) => {
        history.push({
            pathname: "/iframe-rule-suppliers",
            state: {
                ...data,
                api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""
            }
                // state: decompress(res.data.ProductDetails),
            })
    }
    return (
        <>
        {updaterule?<Alert variant="success">
                    {updaterule}
              </Alert>:""}
             
            <SwalWarn show={swalopen} message={"Are you sure want to delete Rule?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => DeleteRule()}/>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12 border-bottom pb-4 mb-4">
                    <h3>Iframe Dashboard</h3>
                </div>
                {props.customeriframeorapi && (props.customeriframeorapi.allow_natural_rule || props.customeriframeorapi.allow_labgrown_rule)?<>
                <div className="col-12 d-md-flex align-items-center justify-content-between border-bottom"> 
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="Mark-up rules Manager" />
                            <Tab label="Customer Inquiry List" />
                        </Tabs>
                    <div className="d-flex align-items-center m-1"> 
                        {
                            value !== 1 ?
                            <button className="btn font-weight-bold btn-secondary btn-sm px-5 mr-2" 
                                onClick={() => history.push({
                                    pathname: "/supplier-management-iframe",
                                    // state: decompress(res.data.ProductDetails),
                                })}>
                                    Supplier Management
                            </button> : null
                        }
                        {
                            value === 1 ?
                            <div className="d-flex align-items-center m-1 border rounded px-5">
                                    <span className="font-weight-bold text-muted small">Stone Wise</span>
                                    <CustomSwitch
                                        checked={shortlistSwitch}
                                        onClick={() => setShortlistSwitch(!shortlistSwitch)}
                                    />
                                    <span className="font-weight-bold text-muted small">User Wise</span>
                                </div>
                            : null
                        }
                        <div className="m-1">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Iframe Settings
                                    </Tooltip>
                                }
                            >
                                <button className="btn btn-icon btn-primary btn-sm"  onClick={() => history.push({ pathname: "/iframe-setting", state: "embeddedcode"})}><FaCog/></button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                    {value === 0 && (
                        <div className="col-12">
                            <TabContainer>
                                {props.iframerules.length ===0 ? <Alert variant="warning">
                                    Please setup at least one rule to Display Data in Iframe
                                </Alert>
                                :props.iframerules.map(value => value.status).every(element => element === 0) ? <Alert variant="warning">
                                    Please activate at least one rule to Display Data in Iframe
                                </Alert>:"" }
                                <div className="mt-2">Your Multi-level Markup for different types of Diamonds</div>
                                <div className="table-responsive w-100 mt-10">
                                    <table className="table table-striped table-hover">
                                        <thead className="custom_datatable_head">
                                            <tr>
                                                <th className="text-left align-middle">Diamond Type</th>
                                                <th className="text-center align-middle">Diamond Criteria</th>
                                                <th className="text-center align-middle">Rule Status</th>
                                                <th className="text-center align-middle">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.iframerules.filter(val => val.naturaldiamond === 1).length?<tr className="bg-natural-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
    Natural Colorless Diamonds
  </div>
</OverlayTrigger>:<div>
    Natural Colorless Diamonds
  </div>}
                                                    </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
><div>
                                                    {props.iframerules.filter(val => val.naturaldiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.naturaldiamond === 1)[0].min_carat + '-' + props.iframerules.filter(val => val.naturaldiamond === 1)[0].max_carat + " | " + (props.iframerules.filter(val => val.naturaldiamond === 1)[0].naturaldiamond?props.iframerules.filter(val => val.naturaldiamond === 1)[0].color.toString().slice(0,10):props.iframerules.filter(val => val.naturaldiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.naturaldiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                    </div>
                                                    </OverlayTrigger>:
                                                    <div>
                                                    {props.iframerules.filter(val => val.naturaldiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.naturaldiamond === 1)[0].min_carat + '-' + props.iframerules.filter(val => val.naturaldiamond === 1)[0].max_carat + " | " + (props.iframerules.filter(val => val.naturaldiamond === 1)[0].naturaldiamond?props.iframerules.filter(val => val.naturaldiamond === 1)[0].color.toString().slice(0,10):props.iframerules.filter(val => val.naturaldiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.naturaldiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                    </div>
                                                    }
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <Switch
                                                        checked={props.iframerules.filter(val => val.naturaldiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.naturaldiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                        // disabled={disabled}
                                                        disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}
                                                    />
                                                    </OverlayTrigger>:
                                                    <Switch
                                                    checked={props.iframerules.filter(val => val.naturaldiamond === 1)[0].status === 1}
                                                    onClick={() => UpdateStatus(props.iframerules.filter(val => val.naturaldiamond === 1)[0])}
                                                    value={1}
                                                    color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}
                                                />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/iframe-update-rule",state: {
                                                        rule_name:"Natural White Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:1,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        rule_id:props.iframerules.filter(val => val.naturaldiamond === 1)[0].rule_id,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""
                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                    >
                                                        <button className="btn btn-info mr-1 btn-sm" onClick={() => ViewSupplier(props.iframerules.filter(val => val.naturaldiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaUser/></button>
                                                    </OverlayTrigger> */}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm  mr-1" onClick={() => SetDeleteRule(props.iframerules.filter(val => val.naturaldiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr className="bg-natural-10">
                                                <td className="text-left text-nowrap align-middle">
                                               { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
    Natural Colorless Diamonds
  </div>
</OverlayTrigger>:<div>
    Natural Colorless Diamonds
  </div>}

                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <button className="btn btn-primary add-rule-natural-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Natural White Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:1,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}>Add Rule</button>
                                                    </OverlayTrigger>:<button className="btn btn-primary add-rule-natural-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Natural White Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:1,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}>Add Rule</button>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                    </OverlayTrigger>:<FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                    >
                                                        <button className="btn btn-info mr-1 btn-sm" disabled={true}><FaUser/></button>
                                                    </OverlayTrigger> */}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm mr-1" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                            {props.iframerules.filter(val => val.naturalfancydiamond === 1).length?<tr className="bg-natural-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Natural Fancy Color Diamonds
  </div>
</OverlayTrigger>:<div>
Natural Fancy Color Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
><div>
                                                {props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].min_carat + '-' + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].max_carat + " | " + (props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].naturaldiamond?props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].color.toString().slice(0,10):props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                </div>
                                                </OverlayTrigger>:
                                                <div>
                                                {props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].min_carat + '-' + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].max_carat + " | " + (props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].naturaldiamond?props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].color.toString().slice(0,10):props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].diamondfancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].clarity.toString().slice(0,10) + "..."}
                                                </div>}
                                                
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <Switch
                                                        checked={props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.naturalfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}
                                                    />
                                                    </OverlayTrigger>:<Switch
                                                        checked={props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.naturalfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/iframe-update-rule",state: {
                                                        rule_name:"Natural Fancy Diamonds",
                                                        diamond_type:"N",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:1,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        rule_id:props.iframerules.filter(val => val.naturalfancydiamond === 1)[0].rule_id,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                    >
                                                        <button className="btn btn-info mr-1 btn-sm" onClick={() => ViewSupplier(props.iframerules.filter(val => val.naturalfancydiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaUser/></button>
                                                    </OverlayTrigger> */}
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm  mr-1" onClick={() => SetDeleteRule(props.iframerules.filter(val => val.naturalfancydiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr className="bg-natural-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Natural Fancy Color Diamonds
  </div>
</OverlayTrigger>:<div>
Natural Fancy Color Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <button className="btn btn-danger add-rule-natural-fancy-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Natural Fancy Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:1,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}>Add Rule</button>
                                                    </OverlayTrigger>:<button className="btn btn-danger add-rule-natural-fancy-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Natural Fancy Rule",
                                                        diamond_type:"N",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:1,
                                                        labdiamond:0,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule}>Add Rule</button>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Natural Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                    </OverlayTrigger>:
                                                    <FormControlLabel
                                                    className='m-0'
                                                    control={
                                                        <Switch
                                                            // checked={}
                                                            // onClick={handleChange}
                                                            value={1}
                                                            color="secondary"
                                                            disabled={true}
                                                        />
                                                    }
                                                />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-info mr-1 btn-sm" disabled={true}><FaUser/></button>
                                                </OverlayTrigger> */}
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_natural_rule?"Natural Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm mr-1" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}                                            
                                            {props.iframerules.filter(val => val.labdiamond === 1).length?<tr className="bg-lab-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Lab Colorless Diamonds
  </div>
</OverlayTrigger>:<div>
Lab Colorless Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
><div>
                                                    {props.iframerules.filter(val => val.labdiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_min_carat + '-' + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_max_carat + " | " + (props.iframerules.filter(val => val.labdiamond === 1)[0].labdiamond?props.iframerules.filter(val => val.labdiamond === 1)[0].lab_color.toString().slice(0,10):props.iframerules.filter(val => val.labdiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                    </div>
                                                    </OverlayTrigger>:
                                                    <div>
                                                    {props.iframerules.filter(val => val.labdiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_min_carat + '-' + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_max_carat + " | " + (props.iframerules.filter(val => val.labdiamond === 1)[0].labdiamond?props.iframerules.filter(val => val.labdiamond === 1)[0].lab_color.toString().slice(0,10):props.iframerules.filter(val => val.labdiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.labdiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                    </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <Switch
                                                        checked={props.iframerules.filter(val => val.labdiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.labdiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}
                                                    />
                                                    </OverlayTrigger>:<Switch
                                                        checked={props.iframerules.filter(val => val.labdiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.labdiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/iframe-update-rule",state: {
                                                        rule_name:"Lab Grown White Diamonds",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:1,
                                                        labfancydiamond:0,
                                                        rule_id:props.iframerules.filter(val => val.labdiamond === 1)[0].rule_id,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-info mr-1 btn-sm" onClick={() => ViewSupplier(props.iframerules.filter(val => val.labdiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaUser/></button>
                                                </OverlayTrigger> */}
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm  mr-1" onClick={() => SetDeleteRule(props.iframerules.filter(val => val.labdiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr className="bg-lab-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Lab Colorless Diamonds
  </div>
</OverlayTrigger>:<div>
Lab Colorless Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <button className="btn btn-success add-rule-lab-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Lab White Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:1,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}>Add Rule</button>
                                                    </OverlayTrigger>:<button className="btn btn-success add-rule-lab-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Lab White Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:1,
                                                        labfancydiamond:0,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}>Add Rule</button>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                    </OverlayTrigger>:<FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-info mr-1 btn-sm" disabled={true}><FaUser/></button>
                                                </OverlayTrigger> */}
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm mr-1" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                            {props.iframerules.filter(val => val.labfancydiamond === 1).length?<tr className="bg-lab-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Lab Fancy Color Diamonds
  </div>
</OverlayTrigger>:<div>
Lab Fancy Color Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
><div>
                                                {props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_min_carat + '-' + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_max_carat + " | " + (props.iframerules.filter(val => val.labfancydiamond === 1)[0].labdiamond?props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_color.toString().slice(0,10):props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                </div></OverlayTrigger>:<div>
                                                {props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_shape.toString().slice(0,10) + "... | " + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_min_carat + '-' + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_max_carat + " | " + (props.iframerules.filter(val => val.labfancydiamond === 1)[0].labdiamond?props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_color.toString().slice(0,10):props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_fancy_color.toString().slice(0,10)) + "... | " + props.iframerules.filter(val => val.labfancydiamond === 1)[0].lab_clarity.toString().slice(0,10) + "..."}
                                                </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <Switch
                                                        checked={props.iframerules.filter(val => val.labfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.labfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}
                                                    />
                                                    </OverlayTrigger>:<Switch
                                                        checked={props.iframerules.filter(val => val.labfancydiamond === 1)[0].status === 1}
                                                        onClick={() => UpdateStatus(props.iframerules.filter(val => val.labfancydiamond === 1)[0])}
                                                        value={1}
                                                        color="secondary"
                                                    // disabled={disabled}
                                                    disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => history.push({ pathname: "/iframe-update-rule",state: {
                                                        rule_name:"Lab Fancy Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:1,
                                                        rule_id:props.iframerules.filter(val => val.labfancydiamond === 1)[0].rule_id,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""

                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                   
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-info mr-1 btn-sm" onClick={() => ViewSupplier(props.iframerules.filter(val => val.labfancydiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaUser/></button>
                                                </OverlayTrigger> */}
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm  mr-1" onClick={() => SetDeleteRule(props.iframerules.filter(val => val.labfancydiamond === 1)[0])} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>:<tr className="bg-lab-10">
                                                <td className="text-left text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
  <div>
  Lab Fancy Color Diamonds
  </div>
</OverlayTrigger>:<div>
Lab Fancy Color Diamonds
  </div>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <button className="btn btn-warning add-rule-lab-fancy-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Lab Fancy Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:1,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""
                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}>Add Rule</button>
                                                    </OverlayTrigger>:<button className="btn btn-warning add-rule-lab-fancy-btn btn-sm" onClick={() => history.push({ pathname: "/iframe-create-rule",state: {
                                                        rule_name:"Lab Fancy Rule",
                                                        diamond_type:"L",
                                                        naturaldiamond:0,
                                                        naturalfancydiamond:0,
                                                        labdiamond:0,
                                                        labfancydiamond:1,
                                                        currency:props.customeriframeorapi?props.customeriframeorapi.api_currency:"USD",
                                                        api_id:props.customeriframeorapi?props.customeriframeorapi.api_id:""
                                                    }})} disabled={props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule}>Add Rule</button>}
                                                </td>
                                                <td className="text-center text-nowrap align-middle">
                                                { props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?<OverlayTrigger
  placement="top"
  overlay={<Tooltip id="tooltip">Lab Grown Diamonds Iframe Disabled , contact your customer representative</Tooltip>}
>
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />
                                                    </OverlayTrigger>:<FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Switch
                                                                // checked={}
                                                                // onClick={handleChange}
                                                                value={1}
                                                                color="secondary"
                                                                disabled={true}
                                                            />
                                                        }
                                                    />}
                                                </td>
                                                <td className="text-nowrap text-center align-middle">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to edit rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-primary mr-1 btn-sm" disabled={true}><FaRegEdit/></button>
                                                    </OverlayTrigger>
                                                    
                                                    {/* <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Click to View Suppliers
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-info mr-1 btn-sm" disabled={true}><FaUser/></button>
                                                </OverlayTrigger> */}
                                                <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {props.customeriframeorapi && !props.customeriframeorapi.allow_labgrown_rule?"Lab Grown Diamonds Iframe Disabled , contact your customer representative":"Click to delete rule"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <button className="btn btn-danger btn-sm mr-1" disabled={true}><FaTrashAlt /></button>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </TabContainer>
                        </div>
                    )}
                        {value === 1 && (
                            <div className="col-12">
                                <TabContainer className="p-0">
                                    {!shortlistSwitch ? 
                                        <ShortListDetail userinfo={props.userinfo} language={props.language} conversionrate={props.conversionrate} symbol={props.symbol}/>
                                        : !shortlistSwitchByMail ?
                                            <ShortListTable userinfo={props.userinfo} language={props.language} conversionrate={props.conversionrate} symbol={props.symbol} setShortlistSwitchByMail={setShortlistSwitchByMail} setShortlistDataByMail={setShortlistDataByMail} /> 
                                            : <ShortListTableByMail userinfo={props.userinfo} language={props.language} conversionrate={props.conversionrate} symbol={props.symbol} shortlistDataByMail={shortlistDataByMail} setShortlistSwitchByMail={setShortlistSwitchByMail}/>
                                    }
                                </TabContainer>
                            </div>
                        )}
                    </>:<Alert variant="warning" style={{width:"100%"}}>
                        Please Contact Your Sales Representative
                        </Alert>}
            </div>
        </>
    );
};
