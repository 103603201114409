import React, { useState, useEffect } from "react";
import { GetCookies } from "../../../../_helpers/Cookies";
import { Grid, Typography, InputLabel, TextField } from "@material-ui/core";
import {
    InputField,
    MuiUploader,
    PhoneInput,
    SelectField,
    ColorPicker,
    MultiSelect,
} from "../FormFields";
import { Accordion, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import SelectFieldMarkup from "../FormFields/SelectFieldMarkup";
import {
    FaAward,
    FaTrashAlt,
    FaRegEdit,
    FaSave,
    FaRegTimesCircle,
} from "react-icons/fa";
import { useField } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SwalWarn } from "../../../Popup";
export default function ColorMarkup(props) {
    const [field, meta, helper] = useField(props);
    // console.log(meta.error.caratpricemarkups, "meta.error");
    const {
        formField: {
            markupvalue,
            markupoption,
            multi_currency,
            suppliers,
            caratrangeonefrom,
            caratrangeoneto,
            caratrangeonemarkupvalue,
            caratrangeonemarkupoption,
            caratrangetwofrom,
            caratrangetwoto,
            caratrangetwomarkupvalue,
            caratrangetwomarkupoption,
            caratrangethreefrom,
            caratrangethreeto,
            caratrangethreemarkupvalue,
            caratrangethreemarkupoption,

            pricerangeonefrom,
            pricerangeoneto,
            pricerangeonemarkupvalue,
            pricerangeonemarkupoption,
            pricerangetwofrom,
            pricerangetwoto,
            pricerangetwomarkupvalue,
            pricerangetwomarkupoption,
            pricerangethreefrom,
            pricerangethreeto,
            pricerangethreemarkupvalue,
            pricerangethreemarkupoption,
        },
        values,
        setFieldValue,
    } = props;
    const [inr, setInr] = useState(0);
    const [usd, setUsd] = useState(0);
    const [cad, setCad] = useState(0);
    const [aud, setAud] = useState(0);
    const [hkd, setHkd] = useState(0);
    const [cny, setCny] = useState(0);
    const [eur, setEur] = useState(0);
    const [gbp, setGbp] = useState(0);
    const [nzd, setNzd] = useState(0);
    const [jpy, setJpy] = useState(0);
    const [chf, setChf] = useState(0);
    const [updatelocal, setUpdateLocal] = useState(false);
    const [edit, setEdit] = useState(null);
    const [swalopen,setSwalOpen] = useState(false)
    const [swalopenmarkup,setSwalOpenMarkup] = useState(false)
    const [deleteObj,setDeleteObj] = useState(null)
    const [showmarkup,setShowMarkup] = useState(false)
    const [selectedmarkupoption,setSelectedMarkupOption] = useState(null)
    const [rangemsg,setRangeMessage] = useState("")
    // const [markups,setMarkups] = useState([])
    useEffect(() => {
        console.log(values,"valuesvalues")
        if(props.values && props.values["caratpricemarkups"]){
            let validationarray = []
            props.values["caratpricemarkups"].map(value => {
                const checkvalidation = validatemarkups(value)
                if(checkvalidation.msg){
                    validationarray.push(true)
                }
            })
            if(validationarray.includes(true)){
                props.setValidMarkup(true)
            }
            else{
                props.setValidMarkup(false)
            }
        }
        function areRangesCovered(min, max, array) {
            min = parseFloat(min)
            max = parseFloat(max)
            console.log(min,max)
            // Sort the array by fromrange
            array.sort((a, b) => parseFloat(a.fromrange) - parseFloat(b.fromrange));
          
            // Check if the first element starts from min
            if (parseFloat(array[0].fromrange) !== min) {
              return false;
            }
          
            // Check if the last element ends at max
            if (parseFloat(array[array.length - 1].torange) !== max) {
              return false;
            }
          
            // Check if each consecutive range is covered
            for (let i = 0; i < array.length - 1; i++) {
              if (parseFloat(array[i].torange) < Math.round((parseFloat(array[i + 1].fromrange) - 0.01)*100)/100) {
                return false;
              }
            }
          
            return true;
          }
          if(props.values && props.values["caratpricemarkups"] && props.values["caratpricemarkups"].length){
        if(values.markupoption === "Carat"){
            let mincarat = 0
            let maxcarat = 0
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                mincarat = values["carat_from"]
                maxcarat = values["carat_to"]
            }
            else{
                mincarat = values["lab_carat_from"]
                maxcarat = values["lab_carat_to"]
            }
            let rangecovered = areRangesCovered(mincarat,maxcarat,props.values["caratpricemarkups"])
            console.log(rangecovered,"rangecovered")
            if(!rangecovered){
                setRangeMessage("Please Cover All Ranges")
                props.setValidMarkup(true)
            }
            else{
                setRangeMessage("")
                props.setValidMarkup(false)
            }
        }
        else{
            let minprice = 0
            let maxprice = 0
            console.log(values,"valuesvalues")
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                minprice = values["total_price_from"]
                maxprice = values["total_price_to"]
            }
            else{
                minprice = values["lab_total_price_from"]
                maxprice = values["lab_total_price_to"]
            }
            let rangecovered = areRangesCovered(minprice,maxprice,props.values["caratpricemarkups"])
            console.log(rangecovered,"rangecovered")
            console.log(props.values["caratpricemarkups"])
            if(!rangecovered){
                setRangeMessage("Please Cover All Ranges")
                props.setValidMarkup(true)
            }
            else{
                setRangeMessage("")
                props.setValidMarkup(false)
            }
        }  
    }
    else{
        console.log("ELSE Part")
        setRangeMessage("Please Cover All Ranges")
        props.setValidMarkup(true)
    }
    }, [props.values]);
    // useEffect(() => {
    //     let local = []
    //     const getlocalstorage = localStorage.getItem("markup")
    //     if(getlocalstorage){
    //         local = JSON.parse(getlocalstorage)
    //     }
    //     setMarkups(local)
    // },[updatelocal])
    // console.log(inr,usd,"inr,usd")
    const muti_currency_data = [
        {
            value: "USD",
            label: `USD $ - ${usd.toFixed(2)}`,
        },
        {
            value: "CAD",
            label: `CAD $ - ${cad.toFixed(2)}`,
        },
        {
            value: "AUD",
            label: `AUD $ - ${aud.toFixed(2)}`,
        },
        {
            value: "HKD",
            label: `HKD $ - ${hkd.toFixed(2)}`,
        },
        {
            value: "CNY",
            label: `CNY ¥ - ${cny.toFixed(2)}`,
        },
        {
            value: "EUR",
            label: `EUR € - ${eur.toFixed(2)}`,
        },
        {
            value: "GBP",
            label: `GBP £ - ${gbp.toFixed(2)}`,
        },
        {
            value: "NZD",
            label: `NZD $ - ${nzd.toFixed(2)}`,
        },
        {
            value: "JPY",
            label: `JPY ¥ - ${jpy.toFixed(2)}`,
        },
        {
            value: "CHF",
            label: `CHF ₣ - ${chf.toFixed(2)}`,
        },
    ];
    const markupoption_data = [
        {
            value: "Absolute",
            label: `Absolute`,
        },
        {
            value: "Percentage",
            label: `Percentage %`,
        },
    ];
    const supplier_data = [
        {
            value: "DHARMANANDAN DIAMONDS PVT. LTD.",
            label: "DHARMANANDAN DIAMONDS PVT. LTD.",
        },
        {
            value: "Marie Doc Testing Company",
            label: "Marie Doc Testing Company",
        },
        {
            value: "Sagar Enterprise",
            label: `Sagar Enterprise`,
        },
        {
            value: "Starlight",
            label: `Starlight`,
        },
        {
            value: "Parishi Diamond",
            label: `Parishi Diamond`,
        },
        {
            value: "C. Dinesh And Co. Pvt. Ltd.",
            label: `C. Dinesh And Co. Pvt. Ltd.`,
        },
        {
            value: "Vaibhav gems",
            label: `Vaibhav gems`,
        },
        {
            value: "ANGEL STAR HK LTD",
            label: `ANGEL STAR HK LTD`,
        },
        {
            value: "Vru Star (HK) LIMITED",
            label: `Vru Star (HK) LIMITED`,
        },
    ];
    const markup_data = [
        {
            value: "Carat",
            label: "Carat",
        },
        {
            value: "Price",
            label: "Price",
        },
    ];
    const validatemarkups = (markupobj, markuparray) => {
        let isvalidobj = {
            isvalid:true,
            msg:null
        };
        // for (let i = 0; i < markuparray.length; i++) {
        //     console.log(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange),`${parseFloat(markupobj.fromrange)} >= ${parseFloat(markuparray[i].fromrange)}`)
        //     console.log(parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange),`${parseFloat(markupobj.torange)} <= ${parseFloat(markuparray[i].torange)}`)
        //     if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
        //         console.log("PROPER1")
        //         isvalidobj = {
        //             isvalid:false,
        //             msg:`You can not create markup, Please check range values`
        //         }
        //     }
        //     if(parseFloat(markupobj.fromrange) < parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) > parseFloat(markuparray[i].torange)){
        //         console.log("PROPER2")
        //         isvalidobj = {
        //             isvalid:false,
        //             msg:`You can not create markup, Please check range values`
        //         }
        //     }
        //     if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.fromrange) <= parseFloat(markuparray[i].torange)){
        //         console.log("PROPER3")
        //         isvalidobj = {
        //             isvalid:false,
        //             msg:`You can not create markup, Please check range values`
        //         }
        //     }
        //     if(parseFloat(markupobj.torange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
        //         console.log("PROPER4")
        //         isvalidobj = {
        //             isvalid:false,
        //             msg:`You can not create markup, Please check range values`
        //         }
        //     }
        //     if(parseFloat(markupobj.fromrange) >= parseFloat(markupobj.torange)){
        //         console.log("PROPER5")
        //         isvalidobj = {
        //             isvalid:false,
        //             msg:`Minimum Range Should be less than Maximum Range`
        //         }
        //     }
        // }
        if(markupobj.markupname === "Carat"){
            let mincarat = 0
            let maxcarat = 0
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                mincarat = values["carat_from"]
                maxcarat = values["carat_to"]
            }
            else{
                mincarat = values["lab_carat_from"]
                maxcarat = values["lab_carat_to"]
            }
            // console.log(mincarat,maxcarat,"mincarat,maxcarat")
            if(parseFloat(markupobj.fromrange) < parseFloat(mincarat)){
                isvalidobj = {
                    isvalid:false,
                    msg:`You can not create markup, Please check range values`
                }
            }
            if(parseFloat(markupobj.torange) > parseFloat(maxcarat)){
                isvalidobj = {
                    isvalid:false,
                    msg:`You can not create markup, Please check range values`
                }
            }
        }
        else{
            let minprice = 0
            let maxprice = 0
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                minprice = values["total_price_from"]
                maxprice = values["total_price_to"]
            }
            else{
                minprice = values["lab_total_price_from"]
                maxprice = values["lab_total_price_to"]
            }
            // console.log(minprice,maxprice,"minprice,maxprice")
            if(parseFloat(markupobj.fromrange) < parseFloat(minprice)){
                isvalidobj = {
                    isvalid:false,
                    msg:`You can not create markup, Please check range values`
                }
            }
            if(parseFloat(markupobj.torange) > parseFloat(maxprice)){
                isvalidobj = {
                    isvalid:false,
                    msg:`You can not create markup, Please check range values`
                }
            }
        }
        // if(isvalidobj.msg){
        //     props.setValidMarkup(true)
        // }
        return isvalidobj;
    };
    const AddMarkup = () => {
        const validatemarkups = (markupobj, markuparray) => {
            let isvalidobj = {
                isvalid:true,
                msg:null
            };
            const numbersonly =/^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/
            if(!numbersonly.test(markupobj.fromrange)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            if(!numbersonly.test(markupobj.torange)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            if(!numbersonly.test(markupobj.markupvalue)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            for (let i = 0; i < markuparray.length; i++) {
                // console.log(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange),`${parseFloat(markupobj.fromrange)} >= ${parseFloat(markuparray[i].fromrange)}`)
                // console.log(parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange),`${parseFloat(markupobj.torange)} <= ${parseFloat(markuparray[i].torange)}`)
                if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER1")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) < parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) > parseFloat(markuparray[i].torange)){
                    // console.log("PROPER2")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.fromrange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER3")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER4")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) >= parseFloat(markupobj.torange)){
                    // console.log("PROPER5")
                    isvalidobj = {
                        isvalid:false,
                        msg:`Minimum Range Should be less than Maximum Range`
                    }
                }
            }
            if(markupobj.markupname === "Carat"){
                let mincarat = 0
                let maxcarat = 0
                if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                    mincarat = values["carat_from"]
                    maxcarat = values["carat_to"]
                }
                else{
                    mincarat = values["lab_carat_from"]
                    maxcarat = values["lab_carat_to"]
                }
                // console.log(mincarat,maxcarat,"mincarat,maxcarat")
                if(parseFloat(markupobj.fromrange) < parseFloat(mincarat)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) > parseFloat(maxcarat)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
            }
            else{
                let minprice = 0
                let maxprice = 0
                if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                    minprice = values["total_price_from"]
                    maxprice = values["total_price_to"]
                }
                else{
                    minprice = values["lab_total_price_from"]
                    maxprice = values["lab_total_price_to"]
                }
                // console.log(minprice,maxprice,"minprice,maxprice")
                if(parseFloat(markupobj.fromrange) < parseFloat(minprice)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) > parseFloat(maxprice)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
            }
            return isvalidobj;
        };
        // console.log(values, "valuesvalues");
        if (
            (values["caratrangeonefrom"]  || values["caratrangeonefrom"] === 0) &&
            (values["caratrangeoneto"]  || values["caratrangeoneto"]  === 0) &&
            (values["caratrangeonemarkupvalue"] || values["caratrangeonemarkupvalue"] === 0) &&
            values["caratrangeonemarkupoption"]
        ) {
            let markuparray = props.values["caratpricemarkups"];
            if(markuparray.length >= 4){
                toast.error("You can create maximum 4 Markups");
                return;
            }
            let MarkupObj = {
                fromrange: values["caratrangeonefrom"],
                torange: values["caratrangeoneto"],
                markupvalue: values["caratrangeonemarkupvalue"],
                markuptype: values["caratrangeonemarkupoption"],
                markupname: values["markupoption"],
            };
            // console.log(MarkupObj,"MarkupObj")
            const validate = validatemarkups(MarkupObj, markuparray);
            if (!validate.isvalid) {
                toast.error(validate.msg);
                return;
            }
            // setUpdateLocal(!updatelocal)
            markuparray.push(MarkupObj);
            props.setFieldValue("caratpricemarkups", markuparray);
            props.setFieldValue("caratrangeonefrom", "");
            props.setFieldValue("caratrangeoneto", "");
            props.setFieldValue("caratrangeonemarkupvalue", "");
            props.setFieldValue("caratrangeonemarkupoption", "");
            props.setFieldValue("showmarkup", false);
            setShowMarkup(false)
            toast.success("Markup Created Succesfully");
        } else {
            toast.error("Please Enter All Values for Mark UP");
        }
    };
    const DeleteMarkup = (value) => {
        const deletevalue = props.values["caratpricemarkups"].filter(
            (val) =>
                val.fromrange !== value.fromrange &&
                val.torange !== value.torange
        );
        props.setFieldValue("caratpricemarkups", deletevalue);
        setSwalOpen(false)
        setDeleteObj(null)
    };
    const EditMarkup = (value, index) => {
        // console.log(value, "EditMarkup", index);
        const deletevalue = props.values["caratpricemarkups"].filter(
            (val) =>
                val.fromrange !== value.fromrange &&
                val.torange !== value.torange
        );
        const validatemarkups = (markupobj, markuparray) => {
            let isvalidobj = {
                isvalid:true,
                msg:null
            };
            const numbersonly =/^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/
            if(!numbersonly.test(markupobj.fromrange)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            if(!numbersonly.test(markupobj.torange)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            if(!numbersonly.test(markupobj.markupvalue)){
                isvalidobj = {
                    isvalid:false,
                    msg:`Only Numeric Values are Allowed`
                }
            }
            for (let i = 0; i < markuparray.length; i++) {
                // console.log(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange),`${parseFloat(markupobj.fromrange)} >= ${parseFloat(markuparray[i].fromrange)}`)
                // console.log(parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange),`${parseFloat(markupobj.torange)} <= ${parseFloat(markuparray[i].torange)}`)
                if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER1")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) < parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) > parseFloat(markuparray[i].torange)){
                    // console.log("PROPER2")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.fromrange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER3")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) >= parseFloat(markuparray[i].fromrange) && parseFloat(markupobj.torange) <= parseFloat(markuparray[i].torange)){
                    // console.log("PROPER4")
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.fromrange) >= parseFloat(markupobj.torange)){
                    // console.log("PROPER5")
                    isvalidobj = {
                        isvalid:false,
                        msg:`Minimum Range Should be less than Maximum Range`
                    }
                }
            }
            if(markupobj.markupname === "Carat"){
                let mincarat = 0
                let maxcarat = 0
                if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                    mincarat = values["carat_from"]
                    maxcarat = values["carat_to"]
                }
                else{
                    mincarat = values["lab_carat_from"]
                    maxcarat = values["lab_carat_to"]
                }
                // console.log(mincarat,maxcarat,"mincarat,maxcarat")
                if(parseFloat(markupobj.fromrange) < parseFloat(mincarat)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) > parseFloat(maxcarat)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
            }
            else{
                let minprice = 0
                let maxprice = 0
                if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                    minprice = values["total_price_from"]
                    maxprice = values["total_price_to"]
                }
                else{
                    minprice = values["lab_total_price_from"]
                    maxprice = values["lab_total_price_to"]
                }
                // console.log(minprice,maxprice,"minprice,maxprice")
                if(parseFloat(markupobj.fromrange) < parseFloat(minprice)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
                if(parseFloat(markupobj.torange) > parseFloat(maxprice)){
                    isvalidobj = {
                        isvalid:false,
                        msg:`You can not create markup, Please check range values`
                    }
                }
            }
            return isvalidobj;
        };
        // console.log(values, "valuesvalues");
        if (
            (values["caratrangeonefrom"]  || values["caratrangeonefrom"] === 0) &&
            (values["caratrangeoneto"]  || values["caratrangeoneto"]  === 0) &&
            (values["caratrangeonemarkupvalue"] || values["caratrangeonemarkupvalue"] === 0) &&
            values["caratrangeonemarkupoption"]
        ) {
            let markuparray = deletevalue;
            let MarkupObj = {
                fromrange: values["caratrangeonefrom"],
                torange: values["caratrangeoneto"],
                markupvalue: values["caratrangeonemarkupvalue"],
                markuptype: values["caratrangeonemarkupoption"],
                markupname: values["markupoption"],
            };
            // console.log(MarkupObj,"MarkupObj")
            const validate = validatemarkups(MarkupObj, markuparray);
            if (!validate.isvalid) {
                toast.error(validate.msg);
                return;
            }
            // setUpdateLocal(!updatelocal)
            // markuparray.push(MarkupObj);
            markuparray.splice(index,0,MarkupObj)
            // console.log(index,"indexindex")
            props.setFieldValue("caratpricemarkups", markuparray);
            props.setFieldValue("caratrangeonefrom", "");
            props.setFieldValue("caratrangeoneto", "");
            props.setFieldValue("caratrangeonemarkupvalue", "");
            props.setFieldValue("caratrangeonemarkupoption", "");
            setEdit(null);
            props.setFieldValue("showmarkup", false);
            setShowMarkup(false)
            props.setValidMarkup(false)
            toast.success("Markup Updated Succesfully");
        } else {
            toast.error("Please Enter All Values for Mark UP");
        }
    };
    const SetEdit = (index,value) => {
        // console.log(value,"value")
        setEdit(index);
        props.setFieldValue("caratrangeonefrom", value.fromrange);
        props.setFieldValue("caratrangeoneto", value.torange);
        props.setFieldValue("caratrangeonemarkupvalue", value.markupvalue);
        props.setFieldValue("caratrangeonemarkupoption", value.markuptype);
        props.setFieldValue("showmarkup",true)
    };
    const DeleteMarkupConfirm = (value) => {
        setSwalOpen(true)
        setDeleteObj(value)
    }
    const CancelAddMarkup = () => {
        props.setFieldValue("showmarkup", true);
        setShowMarkup(true)
        console.log(props.values["caratpricemarkups"][props.values["caratpricemarkups"].length - 1],"props.values")
        let minvalue = ""
        if(values.markupoption === "Carat"){
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                minvalue = values["carat_from"]
            }
            else{
                minvalue = values["lab_carat_from"]
            }
        }
        else{
            if(props.location && props.location.state && props.location.state.naturaldiamond || props.location && props.location.state && props.location.state.naturalfancydiamond){
                minvalue = values["total_price_from"]
            }
            else{
                minvalue = values["lab_total_price_from"]
            }
        }
        props.setFieldValue("caratrangeonefrom", props.values && props.values["caratpricemarkups"] && props.values["caratpricemarkups"].length?Math.round((parseFloat(props.values["caratpricemarkups"][props.values["caratpricemarkups"].length - 1].torange) + .01)*100)/100:minvalue);
        props.setFieldValue("caratrangeoneto", "");
        props.setFieldValue("caratrangeonemarkupvalue", "");
        props.setFieldValue("caratrangeonemarkupoption", "");
    }
    const ChangeMarkup = () => {
        props.setFieldValue("markupoption",selectedmarkupoption)
        props.setFieldValue("caratpricemarkups",[])
        setSwalOpenMarkup(false)
    }
    //   console.log(props.values["caratpricemarkups"],"props.values")
    const CancelMarkUpEdit = () => {
        props.setFieldValue("showmarkup", false);
        setShowMarkup(false)
    }
    return (
        <> 
        {swalopen?<SwalWarn show={swalopen} message={"Are you sure want to delete Markup?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => DeleteMarkup(deleteObj)}/>:""}
        {swalopenmarkup?<SwalWarn show={swalopenmarkup} message={"If You Change Markup Option then Current Markup Values will be Reset. are you sure you want to Change Markup?"} onHide={() => setSwalOpenMarkup(false)} onClose={() => setSwalOpenMarkup(false)} responseOnOk={() => ChangeMarkup()}/>:""}
        <React.Fragment>
           
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center" >Price Markup</Typography>
            <p className="mb-1 text-center"> Set multilevel price markup by price or carat </p>
            <p className="mb-10 text-center">You can customize or change price any time as per your choice </p>
            <hr className="w-75 pb-15 my-auto" />
            <div className="text-center text-warning font-weight-bold">All Markups are in USD.</div>
            {rangemsg?<div className="text-center text-warning font-weight-bold">Please Cover All Ranges.</div>:""}
            <Grid justify="center" alignItems="center" spacing={3}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="mb-10"
                    spacing={3}
                >
                    {/* <Grid item xs={12} md={7}>
                        <>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                        Select Currency *
                                    </InputLabel>
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <SelectField
                                        name={multi_currency.name}
                                        label={multi_currency.label}
                                        fullWidth
                                        data={muti_currency_data}
                                        disabled={true}
                                    />
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                        </>
                    </Grid> */}
                    {/* Markupsss */}
                    <Grid item xs={12} md={7}>
                        <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            {/* <div className="w-100 m-0 mt-5 text-center text-danger">
                                Disclaimer: If You Change Markup Option then Markup Values will be Reset.
                            </div>
                            <hr className="w-100 mb-8 mt-5" /> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                        Select Markup Option *
                                    </InputLabel>
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <SelectFieldMarkup
                                        name={markupoption.name}
                                        label={markupoption.label}
                                        fullWidth
                                        data={markup_data}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        setSelectedMarkupOption={setSelectedMarkupOption}
                                        setSwalOpenMarkup={setSwalOpenMarkup}
                                    />
                                </Grid>
                                <hr className="w-100 mb-8 mt-8" />
                            </Grid>
                        </>
                    </Grid>

                    {values.markupoption === "Carat" ? (
                        <>
                            {props.values["caratpricemarkups"]
                                ? props.values["caratpricemarkups"].map(
                                        (value, index) => (
                                            <>
                                                <Grid item xs={12} md={7}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Carat Range
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid container spacing={3} >
                                                                <Grid  item xs={6} >
                                                                    {index !== edit ? (
                                                                        <InputField
                                                                            name={ caratrangeonefrom.name }
                                                                            label={ props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                                            fullWidth
                                                                            disabled={index !==edit}
                                                                            value={value.fromrange}
                                                                        />
                                                                    ) : (
                                                                        <InputField
                                                                            name={caratrangeonefrom.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                                            fullWidth
                                                                            // disabled={index !==edit}
                                                                            disabled={true}
                                                                            // value={value.fromrange}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={6} >
                                                                    {index !== edit ? (
                                                                        <InputField
                                                                            name={caratrangeoneto.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                                            fullWidth
                                                                            disabled={index !== edit }
                                                                            value={value.torange}
                                                                        />
                                                                    ) : (
                                                                        <InputField
                                                                            name={ caratrangeoneto.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                                            fullWidth
                                                                            // disabled={index !== edit}
                                                                            disabled={true}
                                                                            // value={value.torange}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} alignItems="center" >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Select Absolute/Percentage *
                                                            </InputLabel>
                                                        </Grid>

                                                        <Grid item xs={12} md={7} >
                                                            {index !==  edit ? (
                                                                <InputField
                                                                    name={caratrangeonemarkupoption.name}
                                                                    label={ caratrangeonemarkupoption.label}
                                                                    fullWidth
                                                                    // data={markupoption_data}
                                                                    disabled={index !==edit}
                                                                    value={value.markuptype}
                                                                />
                                                            ) : (
                                                                <SelectField
                                                                    name={caratrangeonemarkupoption.name}
                                                                    label={caratrangeonemarkupoption.label}
                                                                    fullWidth
                                                                    data={markupoption_data }
                                                                    disabled={index !== edit}
                                                                />
                                                            )}
                                                        </Grid>
                                                        {index !== edit ? (
                                                            <div>
                                                                {index + 1 === props.values["caratpricemarkups"].length?<OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to delete markup
                            </Tooltip>
                        }
                    ><button
                    className="btn btn-danger btn-sm mr-1"
                    type="button"
                    onClick={() => DeleteMarkupConfirm(value)}
                >
                    <FaTrashAlt className="font-size-h5" />
                </button></OverlayTrigger>:""}
                <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to edit markup
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    type="button"
                                                                    onClick={() => SetEdit(index,value)}
                                                                >
                                                                    <FaRegEdit className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Save Markup
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-primary btn-sm mr-1"
                                                                    type="button"
                                                                    onClick={() => EditMarkup(value,index)}
                                                                >
                                                                    <FaSave className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Cancel Edit
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-danger btn-sm mr-1"
                                                                    type="button"
                                                                    onClick={() => {
setEdit(null)
props.setFieldValue("showmarkup",false)
}}
                                                                >
                                                                    <FaRegTimesCircle className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Markup Value *
                                                            </InputLabel>
                                                        </Grid>

                                                        <Grid item xs={8}>
                                                            {index !== edit ? (
                                                                <InputField
                                                                    name={caratrangeonemarkupvalue.name}
                                                                    label={caratrangeonemarkupvalue.label}
                                                                    fullWidth
                                                                    disabled={index !== edit }
                                                                    value={value.markupvalue}
                                                                    // inputPropsEnd="%"
                                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                                />
                                                            ) : (
                                                                <InputField
                                                                    name={caratrangeonemarkupvalue.name}
                                                                    label={caratrangeonemarkupvalue.label}
                                                                    fullWidth
                                                                    disabled={index !== edit}
                                                                    // value={value.markupvalue}
                                                                    // inputPropsEnd="%"
                                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                <div className="text-center text-danger">{validatemarkups(value,props.values["caratpricemarkups"]).msg?validatemarkups(value,props.values["caratpricemarkups"]).msg:""}</div>               
                                                </Grid>
                                                <hr className="w-100 mb-8 mt-8" />
                                            </>
                                        )
                                    )
                                : ""}
                            {edit === null ? (
                                showmarkup?<>
                                    <Grid item xs={12} md={7}>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Carat Range
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={6}>
                                                        <InputField
                                                            name={caratrangeonefrom.name}
                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                            fullWidth
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <InputField
                                                            name={caratrangeoneto.name}
                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Select Absolute/Percentage *
                                                </InputLabel>
                                            </Grid>

                                            <Grid item xs={12} md={7}>
                                                <SelectField
                                                    name={caratrangeonemarkupoption.name}
                                                    label={caratrangeonemarkupoption.label}
                                                    fullWidth
                                                    data={markupoption_data}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Grid container spacing={3} alignItems="center" >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Markup Value *
                                                </InputLabel>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <InputField
                                                    name={caratrangeonemarkupvalue.name}
                                                    label={caratrangeonemarkupvalue.label}
                                                    fullWidth
                                                    // inputPropsEnd="%"
                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        
                                        <div className="text-right">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => AddMarkup()}
                                                type="button"
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-sm btn-danger mr-2"
                                                onClick={() => CancelMarkUpEdit()}
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                            {meta.error.caratpricemarkups ? (
                                                <div className="text-danger font-weight-bold pl-2"> Please Add MarkUp </div>
                                            ) : (
                                                ""
                                            )}
                                            <hr className="w-100 mb-8 mt-8 d-block" />
                                        </div>
                                    </Grid>
                                </>:rangemsg !== ""?<Grid item xs={12} md={7}><div className="text-right"><button className="btn btn-sm btn-primary" onClick={() => CancelAddMarkup()}>Add MarkUp</button></div></Grid>:""
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        ""
                    )}

                    {/* Price */}

                    {values.markupoption === "Price" ? (
                        <>
                            {props.values["caratpricemarkups"]
                                ? props.values["caratpricemarkups"].map(
                                        (value, index) => (
                                            <>
                                                <Grid item xs={12} md={7}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Price Range
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={6}>
                                                                    {index !== edit ? (
                                                                        <InputField
                                                                            name={caratrangeonefrom.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                                            fullWidth
                                                                            disabled={index !== edit}
                                                                            value={value.fromrange}
                                                                        />
                                                                    ) : (
                                                                        <InputField
                                                                            name={caratrangeonefrom.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                                            fullWidth
                                                                            // disabled={index !== edit}
                                                                            disabled={true}
                                                                            // value={value.fromrange}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {index !== edit ? (
                                                                        <InputField
                                                                            name={caratrangeoneto.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                                            fullWidth
                                                                            disabled={index !== edit}
                                                                            value={value.torange}
                                                                        />
                                                                    ) : (
                                                                        <InputField
                                                                            name={caratrangeoneto.name}
                                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                                            fullWidth
                                                                            // disabled={index !==edit}
                                                                            disabled={true}
                                                                            // value={value.torange}
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} alignItems="center"
                                                    >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Select Absolute/Percentage *
                                                            </InputLabel>
                                                        </Grid>

                                                        <Grid item xs={12} md={7} >
                                                            {index !==edit ? (
                                                                <InputField
                                                                    name={caratrangeonemarkupoption.name}
                                                                    label={caratrangeonemarkupoption.label}
                                                                    fullWidth
                                                                    // data={markupoption_data}
                                                                    disabled={index !==edit}
                                                                    value={value.markuptype}
                                                                />
                                                            ) : (
                                                                <SelectField
                                                                    name={caratrangeonemarkupoption.name}
                                                                    label={caratrangeonemarkupoption.label}
                                                                    fullWidth
                                                                    data={markupoption_data}
                                                                    disabled={index !==edit}
                                                                    // value={value.markuptype}
                                                                />
                                                            )}
                                                        </Grid>
                                                        {index !== edit ? (
                                                            <div>
                                                                {index + 1 === props.values["caratpricemarkups"].length?<OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to delete markup
                            </Tooltip>
                        }
                    ><button
                    className="btn btn-danger btn-sm mr-1"
                    type="button"
                    onClick={() => DeleteMarkupConfirm(value)}
                >
                    <FaTrashAlt className="font-size-h5" />
                </button></OverlayTrigger>:""}
                <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to edit markup
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    type="button"
                                                                    onClick={() => SetEdit(index,value)}
                                                                >
                                                                    <FaRegEdit className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Save Markup
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-primary btn-sm mr-1"
                                                                    type="button"
                                                                    onClick={() => EditMarkup(value,index)}
                                                                >
                                                                    <FaSave className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Cancel Edit
                            </Tooltip>
                        }
                    >
                                                                <button
                                                                    className="btn btn-danger btn-sm mr-1"
                                                                    type="button"
                                                                    onClick={() => {
                                                                setEdit(null)
                                                                props.setFieldValue("showmarkup",false)
                                                                }}
                                                                >
                                                                    <FaRegTimesCircle className="font-size-h5" />
                                                                </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={4}>
                                                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                                Markup Value *
                                                            </InputLabel>
                                                        </Grid>

                                                        <Grid item xs={8}>
                                                            {index !== edit ? (
                                                                <InputField
                                                                    name={caratrangeonemarkupvalue.name}
                                                                    label={caratrangeonemarkupvalue.label}
                                                                    fullWidth
                                                                    disabled={index !== edit}
                                                                    value={value.markupvalue}
                                                                    // inputPropsEnd="%"
                                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                                />
                                                            ) : (
                                                                <InputField
                                                                    name={caratrangeonemarkupvalue.name}
                                                                    label={caratrangeonemarkupvalue.label}
                                                                    fullWidth
                                                                    disabled={index !== edit}
                                                                    // value={value.markupvalue}
                                                                    // inputPropsEnd="%"
                                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <div className="text-center text-danger">{validatemarkups(value,props.values["caratpricemarkups"]).msg?validatemarkups(value,props.values["caratpricemarkups"]).msg:""}</div>
                                                </Grid>
                                                <hr className="w-100 m-0 mt-8" />
                                            </>
                                        )
                                    )
                                : ""}
                            {edit === null ? (
                                showmarkup?<>
                                    <Grid item xs={12} md={7}>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Price Range
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={6}>
                                                        <InputField
                                                            name={caratrangeonefrom.name}
                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                                            fullWidth
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <InputField
                                                            name={caratrangeoneto.name}
                                                            label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Select Absolute/Percentage*
                                                </InputLabel>
                                            </Grid>

                                            <Grid item xs={12} md={7}>
                                                <SelectField
                                                    name={caratrangeonemarkupoption.name}
                                                    label={caratrangeonemarkupoption.label}
                                                    fullWidth
                                                    data={markupoption_data}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                                    Markup Value *
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <InputField
                                                    name={caratrangeonemarkupvalue.name}
                                                    label={caratrangeonemarkupvalue.label}
                                                    fullWidth
                                                    // inputPropsEnd="%"
                                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                    
                                    <div className="text-right">
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => AddMarkup()}
                                        type="button"
                                    >
                                        Save
                                    </button>
                                    <button
                                                className="btn btn-sm btn-danger mr-2"
                                                onClick={() => CancelMarkUpEdit()}
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                    {meta.error.caratpricemarkups ? (
                                        <div className="text-danger font-weight-bold pl-2">
                                            <strong>
                                                Please Add MarkUp
                                            </strong>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <hr className="w-100 mb-8 mt-8 d-block" />
                                    </div>
                                    </Grid>
                                </>:rangemsg !== ""?<Grid item xs={12} md={7}><div className="text-right"><button className="btn btn-sm btn-primary" onClick={() => CancelAddMarkup()}>Add MarkUp</button></div></Grid>:""
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
        </>
    );
}
