import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {Switch,FormControlLabel,FormGroup  } from '@material-ui/core';
import { NODE_API_URL } from '../../../../env_config';
import axios from 'axios';
import { FaAward ,FaTrashAlt,FaRegEdit ,FaCopy,FaUser} from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
function countryGroup (x) {
    var country = x.split(',').map(function (x, index) {
        return <span className="label label-outline-primary label-pill label-inline bg-primary-o-30 px-5 mr-1" key={index}>{ x }</span>; 
    });

    return <span>{country}</span>;
}

// function dateFormate(date){
//     let formattedDate = "";
//     if (date) {
//         const dateObj = new Date(date);
//         const year = dateObj.getFullYear();
//         const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
//         const day = ('0' + dateObj.getDate()).slice(-2);
//         const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
//         formattedDate = `${year}-${month}-${day} ${timeString}`;
//     }
//     return formattedDate;
// }    
export const MockShortListColumn = (history,props,setDeleteObj,setSwalOpen,countarray,setCountLoader,countloader,customeriframeorapi) => [

    {
        Header:"Rule Name",
        accessor:'rule_name',
        className:"align-middle text-center",
    },
    {
        Header:"Diamond Criteria",
        accessor: (d) => {
            return (
                <>
                    {(d.naturaldiamond || d.naturalfancydiamond)?d.shape.toString().slice(0,10) + "... | " + d.min_carat + '-' + d.max_carat + " | " + (d.naturaldiamond?d.color.toString().slice(0,10):d.diamondfancy_color.toString().slice(0,10)) + "... | " + d.clarity.toString().slice(0,10) + "...":d.lab_shape.toString().slice(0,10) + "... | " + d.lab_min_carat + '-' + d.lab_max_carat + " | " + (d.labdiamond?d.lab_color.toString().slice(0,10):d.lab_fancy_color.toString().slice(0,10)) + "... | " + d.lab_clarity.toString().slice(0,10) + "..."}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:"Diamond Type",
        accessor: (d) => {
            return (
                <>
                    {d.naturaldiamond?"Natural White Diamonds":
                        d.naturalfancydiamond?"Natural Fancy Diamonds":
                        d.labdiamond?"Lab Grown White Diamonds":
                        d.labfancydiamond?"Lab Grown Fancy Diamonds":
                        ""
                    }
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:"Markup Type",
        accessor: (d) => {
            return (
                <>
                    {d.markupname}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:"Diamond Count",
        accessor: (d) => {
            const GetCount = (d) => {
                setCountLoader(d.rule_id)
                let diamondtype = "N"
                let naturaldiamond = 0
                let naturalfancydiamond = 0
                let lgwhitediam = 0
                let lgfancydiam = 0
                if(d.naturaldiamond){
                  diamondtype = "N"
                  naturaldiamond = 1
                }
                if(d.naturalfancydiamond){
                  diamondtype = "N"
                  naturalfancydiamond = 1
                }
                if(d.labdiamond){
                  diamondtype = "L"
                  lgwhitediam = 1
                }
                if(d.labfancydiamond){
                  diamondtype = "L"
                  lgfancydiam = 1
                }
                let FinalObject = {
                    "user_id":props.userinfo.id,
                    "api_id":customeriframeorapi.api_id,  
                  diamond_type:diamondtype,
                  naturaldiamond:naturaldiamond,
                  naturalfancydiamond:naturalfancydiamond,
                  labdiamond:lgwhitediam,
                  labfancydiamond:lgfancydiam,
                  rule_name:d.rulename,
                  user_id:props.userinfo.id,
                  suppliers:d.suppliers,
                  api_currency:props.userinfo.api_currency,
                  "rule_id":d.rule_id,
                }
                if(d.markupoption === "Dollar"){
                  FinalObject["markupdollar"] = parseFloat(d.markup)
                }
                else{
                  FinalObject["markupperc"] = parseFloat(d.markup)
                  FinalObject["currency"] = d.multi_currency
                }
                if(diamondtype === "N"){
                    if(d.shape){
                        FinalObject["shape"] = d.shape.split(',')
    
                    }
                    if(d.cut){
                        FinalObject["cut"] = d.cut.split(',')
    
                    }
                    if(d.clarity){
                        FinalObject["clarity"] = d.clarity.split(',')
    
                    }
                    if(d.min_carat >= 0){
                        FinalObject["min_carat"] = parseFloat(d.min_carat)
    
                    }
                    if(d.max_carat >= 0){
                        FinalObject["max_carat"] = parseFloat(d.max_carat)
    
                    }
                    if(d.lab){
                        FinalObject["lab"] = d.lab.split(',')
    
                    }
                    if(d.symmetry){
                        FinalObject["symmetry"] = d.symmetry.split(',')
    
                    }
                    if(d.fluorescence){
                        FinalObject["fluorescence"] = d.fluorescence.split(',')
    
                    }
                    if(d.polish){
                        FinalObject["polish"] = d.polish.split(',')
    
                    }
                    if(d.milky){
                        FinalObject["milky"] = d.milky.split(',')
    
                    }
                    if(d.eyeclean){
                        FinalObject["eyeclean"] = d.eyeclean.split(',')
    
                    }
                    if(d.shade){
                        FinalObject["shade"] = d.shade.split(',')
    
                    }
                    if(d.min_dollarperct >= 0){
                        FinalObject["min_dollarperct"] = parseFloat(d.min_dollarperct)
    
                    }
                    if(d.max_dollarperct >= 0){
                        FinalObject["max_dollarperct"] = parseFloat(d.max_dollarperct)
    
                    }
                    if(d.total_price_from >= 0){
                        FinalObject["total_price_from"] = parseFloat(d.total_price_from)
    
                    }
                    if(d.total_price_to >= 0){
                        FinalObject["total_price_to"] = parseFloat(d.total_price_to)
    
                    }
                    if(d.media){
                        FinalObject["media"] = d.media.split(',')
    
                    }
                    if(d.minlength >= 0){
                        FinalObject["minlength"] = parseFloat(d.minlength)
    
                    }
                    if(d.maxlength >= 0){
                        FinalObject["maxlength"] = parseFloat(d.maxlength)
    
                    }
                    if(d.minwidth >= 0){
                        FinalObject["minwidth"] = parseFloat(d.minwidth)
    
                    }
                    if(d.maxwidth >= 0){
                        FinalObject["maxwidth"] = parseFloat(d.maxwidth)
    
                    }
                    if(d.minheight >= 0){
                        FinalObject["minheight"] = parseFloat(d.minheight)
    
                    }
                    if(d.maxheight >= 0){
                        FinalObject["maxheight"] = parseFloat(d.maxheight)
    
                    }
                    if(d.tablemin >= 0){
                        FinalObject["tablemin"] = parseFloat(d.tablemin)
    
                    }
                    if(d.tablemax >= 0){
                        FinalObject["tablemax"] = parseFloat(d.tablemax)
    
                    }
                    if(d.depthmin >= 0){
                        FinalObject["depthmin"] = parseFloat(d.depthmin)
    
                    }
                    if(d.depthmax >= 0){
                        FinalObject["depthmax"] = parseFloat(d.depthmax)
    
                    }
                    if(d.ratiomin >= 0){
                        FinalObject["ratiomin"] = parseFloat(d.ratiomin)
    
                    }
                    if(d.ratiomax >= 0){
                        FinalObject["ratiomax"] = parseFloat(d.ratiomax)
    
                    }
                    if(d.crheightmin >= 0){
                        FinalObject["crheightmin"] = parseFloat(d.crheightmin)
    
                    }
                    if(d.crheightmax >= 0){
                        FinalObject["crheightmax"] = parseFloat(d.crheightmax)
    
                    }
                    if(d.cranglemin >= 0){
                        FinalObject["cranglemin"] = parseFloat(d.cranglemin)
    
                    }
                    if(d.cranglemax >= 0){
                        FinalObject["cranglemax"] = parseFloat(d.cranglemax)
    
                    }
                    if(d.pavheightmin >= 0){
                        FinalObject["pavheightmin"] = parseFloat(d.pavheightmin)
    
                    }
                    if(d.pavheightmax >= 0){
                        FinalObject["pavheightmax"] = parseFloat(d.pavheightmax)
    
                    }
                    if(d.pavanglemin >= 0){
                        FinalObject["pavanglemin"] = parseFloat(d.pavanglemin)
    
                    }
                    if(d.pavanglemax >= 0){
                        FinalObject["pavanglemax"] = parseFloat(d.pavanglemax)
    
                    }
                    if(d.brand){
                        FinalObject["brand"] = d.brand.split(',')
    
                    }
                    if(d.origin){
                        FinalObject["origin"] = d.origin.split(',')
    
                    }
                    if(d.treatment){
                        FinalObject["treatment"] = d.treatment.split(',')
    
                    }
                    if(d.keytosymbol){
                        FinalObject["keytosymbol"] = d.keytosymbol.split(',')
    
                    }
                    if(naturaldiamond){
                        if(d.color){
                            FinalObject["color"] = d.color.split(',')
    
                        }
                    }
                    else{
                        if(d.diamondfancy_color){
                            FinalObject["fancy_color"] = d.diamondfancy_color.split(',')
    
                        }
                        if(d.diamondfancy_intensity){
                            FinalObject["fancy_intensity"] = d.diamondfancy_intensity.split(',')
    
                        }
                        if(d.diamondfancy_overtone){
                            FinalObject["fancy_overtone"] = d.diamondfancy_overtone.split(',')
                            
                        }
                    }
                }
                else{
                  if(d.lab_shape){
                    FinalObject["shape"] = d.lab_shape.split(',')
    
                  }
                  if(d.lab_cut){
                    FinalObject["cut"] = d.lab_cut.split(',')
    
                  }
                  if(d.lab_clarity){
                    FinalObject["clarity"] = d.lab_clarity.split(',')
    
                  } 
                  if(d.lab_min_carat >= 0){
                    FinalObject["min_carat"] = parseFloat(d.lab_min_carat)
    
                  } 
                  if(d.lab_max_carat >= 0){
                    FinalObject["max_carat"] = parseFloat(d.lab_max_carat)
    
                  }
                  if(d.lab_lab){
                    FinalObject["lab"] = d.lab_lab.split(',')
    
                  }
                  if(d.lab_symmetry){
                    FinalObject["symmetry"] = d.lab_symmetry.split(',')
    
                  }
                  if(d.lab_fluorescence){
                    FinalObject["fluorescence"] = d.lab_fluorescence.split(',')
                  }
                  if(d.lab_polish){
                    FinalObject["polish"] = d.lab_polish.split(',')
    
                  }
                  if(d.lab_milky){
                    FinalObject["milky"] = d.lab_milky.split(',')
    
                  }
                  if(d.lab_eyeclean){
                    FinalObject["eyeclean"] = d.lab_eyeclean.split(',')
    
                  }
                  if(d.lab_shade){
                    FinalObject["shade"] = d.lab_shade.split(',')
    
                  }
                  if(d.lab_min_dollarperct >= 0){
                    FinalObject["min_dollarperct"] = parseFloat(d.lab_min_dollarperct)
    
                  }
                  if(d.lab_max_dollarperct >= 0){
                    FinalObject["max_dollarperct"] = parseFloat(d.lab_max_dollarperct)
    
                  }
                  if(d.lab_total_price_from >= 0){
                    FinalObject["total_price_from"] = parseFloat(d.lab_total_price_from)
    
                  }
                  if(d.lab_total_price_to >= 0){
                    FinalObject["total_price_to"] = parseFloat(d.lab_total_price_to)
    
                  }
                  if(d.lab_media){
                    FinalObject["media"] = d.lab_media.split(',')
    
                  }
                  if(d.labminlength >= 0){
                    FinalObject["minlength"] = parseFloat(d.labminlength)
    
                  }
                  if(d.labmaxlength >= 0){
                    FinalObject["maxlength"] = parseFloat(d.labmaxlength)
    
                  }
                  if(d.labminwidth >= 0){
                    FinalObject["minwidth"] = parseFloat(d.labminwidth)
    
                  }
                  if(d.labmaxwidth >= 0){
                    FinalObject["maxwidth"] = parseFloat(d.labmaxwidth)
    
                  }
                  if(d.labminheight >= 0){
                    FinalObject["minheight"] = parseFloat(d.labminheight)
    
                  }
                  if(d.labmaxheight >= 0){
                    FinalObject["maxheight"] = parseFloat(d.labmaxheight)
    
                  }
                  if(d.labtablemin >= 0){
                    FinalObject["tablemin"] = parseFloat(d.labtablemin)
    
                  }
                  if(d.labtablemax >= 0){
                    FinalObject["tablemax"] = parseFloat(d.labtablemax)
    
                  }
                  if(d.labdepthmin >= 0){
                    FinalObject["depthmin"] = parseFloat(d.labdepthmin)
    
                  }
                  if(d.labdepthmax >= 0){
                    FinalObject["depthmax"] = parseFloat(d.labdepthmax)
    
                  }
                  if(d.labratiomin >= 0){
                    FinalObject["ratiomin"] = parseFloat(d.labratiomin)
    
                  }
                  if(d.labratiomax >= 0){
                    FinalObject["ratiomax"] = parseFloat(d.labratiomax)
    
                  }
                  if(d.labcrheightmin >= 0){
                    FinalObject["crheightmin"] = parseFloat(d.labcrheightmin)
    
                  }
                  if(d.labcrheightmax >= 0){
                    FinalObject["crheightmax"] = parseFloat(d.labcrheightmax)
    
                  }
                  if(d.labcranglemin >= 0){
                    FinalObject["cranglemin"] = parseFloat(d.labcranglemin)
    
                  }
                  if(d.labcranglemax >= 0){
                    FinalObject["cranglemax"] = parseFloat(d.labcranglemax)
    
                  }
                  if(d.labpavheightmin >= 0){
                    FinalObject["pavheightmin"] = parseFloat(d.labpavheightmin)
    
                  }
                  if(d.labpavheightmax >= 0){
                    FinalObject["pavheightmax"] = parseFloat(d.labpavheightmax)
    
                  }
                  if(d.labpavanglemin >= 0){
                    FinalObject["pavanglemin"] = parseFloat(d.labpavanglemin)
    
                  }
                  if(d.labpavanglemax >= 0){
                    FinalObject["pavanglemax"] = parseFloat(d.labpavanglemax)
    
                  }
                    // FinalObject["brand"] = d.brand.split(',')
                    if(d.laborigin){
                        FinalObject["origin"] = d.laborigin.split(',')
    
                    }
                    if(d.labtreatment){
                        FinalObject["labtreatment"] = d.labtreatment.split(',')
    
                    }
                    if(d.labkeytosymbol){
                        FinalObject["keytosymbol"] = d.labkeytosymbol.split(',')
    
                    }
                  if(lgwhitediam){
                    if(d.lab_color){
                        FinalObject["color"] = d.lab_color.split(',')
    
                    }
                  }
                  else{
                    if(d.lab_fancy_color){
                        FinalObject["fancy_color"] = d.lab_fancy_color.split(',')
    
                    }
                    if(d.lab_fancy_intensity){
                        FinalObject["fancy_intensity"] = d.lab_fancy_intensity.split(',')
    
                    }
                    if(d.lab_fancy_overtone){
                        FinalObject["fancy_overtone"] = d.lab_fancy_overtone.split(',')
    
                    }
                  }
                }
                console.log(FinalObject,"FinalObject")
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/API/fetchSupplierRules`,
                    headers: { "Content-Type": "application/json" },
                    data: FinalObject,
                }).then(res => {
                    console.log(res.data,"res.data")
                    if(res && res.data && res.data === "Please Turn On Suppliers"){
                        setCountLoader(false)
                        let customerdata = [...props.customerapi]
                for(let i = 0; i < customerdata.length; i++){
                    if(customerdata[i].rule_id === d.rule_id){
                        customerdata[i].diamondcount = 0
                    }
                }
                props.setCustomerAPI(customerdata)   

        
                    }
                    if(res && res.data && res.data.data){
                        // setData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/API/fetchSupplierRuleRequest`,
                            headers: { "Content-Type": "application/json" },
                            data: {
                                "user_id":props.userinfo.id,
                                "rule_id":d.rule_id
                            },
                        }).then(supreq => {
                            setCountLoader(false)
        
                            if(supreq && supreq.data && supreq.data.data){
                                // setData(res.data.data)
                                let merged = [];
        
                                // for (let i = 0; i < supreq.data.data.length; i++) {
                                //     merged.push({
                                //         ...supreq.data.data[i],
                                //         ...(res.data.data.find((itmInner) => itmInner.id === supreq.data.data[i].supplier_id))
                                //     }
                                //     );
                                // }
                                let count = 0
                                for (let i = 0; i < res.data.data.length; i++) {
                                                                    // if(res.data.data[i].whitetotal){
                                //     count += res.data.data[i].whitetotal
                                // }
                                // if(res.data.data[i].fancytotal){
                                //     count += res.data.data[i].fancytotal
                                // }
                                const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_name === res.data.data[i].supplier_name))} 
                                // console.log(supreq.data.data,"supreq.data.data")
                                // console.log(res.data.data,"res.data.data[i].supplier_name")
                                // console.log(fetcheddata,"fetcheddatafetcheddata")
                                let mergedobj = {
                                    ...res.data.data[i],
                                    ...fetcheddata
                                }
                                console.log(mergedobj,"mergedobj")
                                if((d.naturaldiamond || d.labdiamond) && mergedobj.whitetotal && mergedobj.on_off === 1){
                                    count += mergedobj.whitetotal
                                }
                                if((d.naturalfancydiamond || d.labfancydiamond) && mergedobj.fancytotal && mergedobj.on_off === 1){
                                    count += mergedobj.fancytotal
                                }

                                }
                                console.log(count, "countcount", res.data.data)
                                let customerdata = [...props.customerapi]
                                for (let i = 0; i < customerdata.length; i++) {
                                    if (customerdata[i].rule_id === d.rule_id) {
                                        customerdata[i].diamondcount = count
                                    }
                                }
                                props.setCustomerAPI(customerdata)    

                            }
                        })
                    }
                })
            }
            return (
                <>
                    {countloader === d.rule_id?<Skeleton className="ml-20" variant="text" width={80} height={30} />:d.diamondcount >= 0?d.diamondcount:<button className="btn-primary rounded" onClick={() => GetCount(d)} disabled={countloader}>Get Count</button>}
                </>
            );
        },
        className:"align-middle text-center",
    },
    {
        Header:"Rule Status",
        accessor: (d) => {
            const UpdateStatus = () => {
                console.log(d,"UpdateStatus")
                let FinalObject = {
                    user_id:d.user_id,
                    rule_id:d.rule_id,
                    status:d.status === 1?0:1
                  }
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/API/updateRuleStatus`,
                    headers: { "Content-Type": "application/json" },
                    data: FinalObject,
                }).then(res => {
                    if(res && res.data && res.data.data){
                        // setData(res.data)
                        // console.log( res.data,"NOICE")
                        if(res.data.data === "Status Updated Successfully!"){
                          console.log(props.updatedata,"props.updatedata")
                            props.setUpdateData(!props.updatedata)
                            if(FinalObject.status){
                                toast.success(`Rule Name ${d.rule_name} turned on`)
                            }
                            else{
                                toast.error(`Rule Name ${d.rule_name} turned off`)
                            }
                        }
                    }
                })
            }
            return (
                <>
                    <FormControlLabel
                        className='m-0'
                        control={
                        <Switch
                            checked={d.status === 1}
                            onClick={UpdateStatus}
                            value={d.api_on_off}
                            color="secondary"
                        />
                        }
                        // label="Primary"
                    />
                </>
            );
        },
        className:"align-middle text-center",
    },   
    {
        Header:"Action",
        accessor: (d) => {
            const DeleteRule = (data) => {
                setSwalOpen(true)
                setDeleteObj({
                    user_id:data.user_id,
                    rule_id:data.rule_id
                })
            }
            const CopyRule = (d) => {
                // if(!props.supplieron){
                //     toast.error("Please Turn on Suppliers")
                //     return
                // }
                if(props.customerapi.length > 9){
                    toast.error("Maximum 10 Rules are Allowed")
                    return
                }
                history.push({
                    pathname: "/copy-rule",
                    state: {
                        rule_id:d.rule_id,
                        api_id:customeriframeorapi.api_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            const EditRule = (d) => {
                // if(!props.supplieron){
                //     toast.error("Please Turn on Suppliers")
                //     return
                // }
                history.push({
                    pathname: "/update-rule",
                    state: {
                        rule_id:d.rule_id,
                        api_id:customeriframeorapi.api_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            const ViewSupplier = (data) => {
                history.push({
                    pathname: "/rule-suppliers",
                    state: {
                        ...data,
                        api_id:customeriframeorapi.api_id
                    }
                        // state: decompress(res.data.ProductDetails),
                    })
            }
            return (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to edit rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-primary mr-1 btn-sm" onClick={() => EditRule(d)}><FaRegEdit/></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to View Suppliers
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-info mr-1 btn-sm" onClick={() => ViewSupplier(d)}><FaUser/></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to clone rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-warning mr-1 btn-sm" onClick={() => CopyRule(d)}><FaCopy/></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Click to delete rule
                            </Tooltip>
                        }
                    >
                        <button className="btn btn-danger mr-1 btn-sm" onClick={() => DeleteRule(d)}><FaTrashAlt /></button>
                    </OverlayTrigger>
                </>
            );
        },
        className:"text-center align-middle",
    },
   
]