/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
// import { Tabs, Tab } from "react-bootstrap";
import {
  Paper,
  Tabs,
  Tab,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";

function TabContainer(props) {
  return (
      <Typography component="div" style={{ padding: "20px 0px" }}>
          {props.children}
      </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const DashboardSearchTab = (props) => {
  const [value, setValue] = React.useState(0); // for tab

  function handleTabChange(event, newValue) {
    setValue(newValue);
  }

    return (
        <>
            <div className={`card card-custom gutter-b`}>
                <div className="card-body dasboardSearch">
                    <span className="small_title">{props.language.DASHBOARD_SEARCH}</span>
                    <Paper position="static" className="shadow-none border-bottom">
                        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                            <Tab label={props.language.DASHBOARD_SEARCH_NATURAL_DIAMOND} />
                            <Tab label={props.language.DASHBOARD_TITLE_SEARCH_LAB_DIAMOND} />
                            {/* <Tab label="Additional Details"/> */}
                        </Tabs>
                    </Paper>

                  <div className="mt-7">
                      {value === 0 && (
                        <TabContainer >
                          <div className="row">
                              <div className="col-md-4">
                              <Link className="menu-link" to={{ pathname:'/searchdiamond/white', option: "white" }}>
                                  {/* <a href="/searchdiamond/white"> */}
                                      <div className="diamondBox">
                                          <div className="wbg">
                                              <span className="white_dia_icon"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_WHITE_DIAMOND}</p>
                                      </div>
                                  {/* </a> */}
                                  </Link>
                              </div>
                              <div className="col-md-4">
                                <Link className="menu-link" to={{ pathname:'/searchdiamond/fancy', option: "fancy" }}>
                                  {/* <a href="/searchdiamond/fancy"> */}
                                      <div className="diamondBox">
                                          <div className="fbg">
                                              <span className="fancy_dia_icon"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_FANCY_DIAMOND}</p>
                                      </div>
                                  {/* </a> */}
                                  </Link>
                              </div>
                              <div className="col-md-4">
                                <Link className="menu-link" to={{ pathname:'/parcel-diamond', option: "PN" }}>
                                      <div className="diamondBox">
                                          <div className="pbg">
                                              <span className="parcel_dia_icon"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_PARCEL_DIAMOND}</p>
                                      </div>
                                </Link>
                                
                              </div>
                          </div>
  
                        </TabContainer>
                      )}
                      {value === 1 && (
                        <TabContainer >
                          <div className="row">
                            <div className="col-md-4">
                              <Link className="menu-link" to={{ pathname:'/labsearchdiamond/white', option: "white" }}>
                                  {/* <a href="/labsearchdiamond/white"> */}
                                      <div className="diamondBox">
                                          <div className="lbg">
                                              <span className="lab_dia_icon"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_LAB_DIAMOND}</p>
                                      </div>
                                  {/* </a> */}
                                </Link>
                            </div>
                            <div className="col-md-4">
                              <Link className="menu-link" to={{ pathname:'/labsearchdiamond/fancy', option: "fancy" }}>
                                  {/* <a href="/labsearchdiamond/fancy"> */}
                                      <div className="diamondBox">
                                          <div className="lfbg">
                                              <span className="lab_fancy_dia_one"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_LAB_FANCY_DIAMOND}</p>
                                      </div>
                                  {/* </a> */}
                                </Link>
                            </div>
                            <div className="col-md-4">
                              <Link className="menu-link" to={{ pathname:'/lab-parcel-diamond', option: "PL" }}>
                                      <div className="diamondBox">
                                          <div className="lpbg">
                                              <span className="lab_parcel_dia_one"></span>
                                          </div>
                                          <p>{props.language.DASHBOARD_SEARCH_PARCEL_LAB_DIAMOND}</p>
                                      </div>
                                </Link>
                            </div>
                          </div>
  
                        </TabContainer>
                      )}
                      {/* {value === 2 && (
                        <TabContainer >
  3
                        </TabContainer>
                      )} */}
                  </div>
                    {/* <Tabs defaultActiveKey="Diamond" id="search-tab">
                        <Tab eventKey="Diamond" title="Diamond">
                            <div className="row">
                                <div className="col-12">
                                    <p className="mt-5 medium_title">
                                        Natural Diamonds
                                    </p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <a href="/searchdiamond">
                                                <div className="diamondBox">
                                                    <div className="wbg">
                                                        <span className="white_dia_icon"></span>
                                                    </div>
                                                    <p>{props.language.DASHBOARD_SEARCH_WHITE_DIAMOND}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-4">
                                            <a href="/searchdiamond">
                                                <div className="diamondBox">
                                                    <div className="fbg">
                                                        <span className="fancy_dia_icon"></span>
                                                    </div>
                                                    <p>{props.language.DASHBOARD_SEARCH_FANCY_DIAMOND}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-4">
                                          <a href="#">
                                            <div className="diamondBox">
                                              <div className="pbg">
                                                <span className="parcel_dia_icon"></span>
                                              </div>
                                              <p>Parcel Color Diamonds</p>
                                            </div>
                                          </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="mt-10 medium_title">
                                        Lab Diamonds
                                    </p>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <a href="/labsearchdiamond">
                                                <div className="diamondBox">
                                                    <div className="lbg">
                                                        <span className="lab_dia_icon"></span>
                                                    </div>
                                                    <p>{props.language.DASHBOARD_SEARCH_WHITE_DIAMOND}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-4">
                                            <a href="/labsearchdiamond">
                                                <div className="diamondBox">
                                                    <div className="lfbg">
                                                        <span className="lab_fancy_dia_one"></span>
                                                    </div>
                                                    <p>{props.language.DASHBOARD_SEARCH_FANCY_DIAMOND}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-4">
                                          <a href="#">
                                            <div className="diamondBox">
                                              <div className="lpbg">
                                                <span className="lab_parcel_dia_one"></span>
                                              </div>
                                              <p>Parcel Color Diamonds</p>
                                            </div>
                                          </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Jewelery" title="Jewelery" disabled>
                            Jewelery content
                        </Tab>
                        <Tab eventKey="Gemstone" title="Gemstone" disabled>
                            Gemstone content
                        </Tab>
                    </Tabs> */}
                </div>
            </div>
        </>
    );
};
