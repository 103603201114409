/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
import SVG from "react-inlinesvg";

import {toAbsoluteUrl} from "../../../_helpers";
import { TiShoppingCart } from "react-icons/ti";

export const ItemsInCartTile = (props) => {
  return (
    <>
      <div className={`card p-0 gutter-b ${cs.cartTile} ${cs.dashTileHeight}`}  >
        {/* <div className="card-body"> */}
          <a href="/hold-diamonds">
            <div className="small-box cart-info-box">
                <div className="inner">
                    <p className="my-2 mt-5 font-size-h6 font-weight-bold">{props.language.DASHBOARD_ITEMS_IN_YOUR_HOLD}</p>
                    <p className="display-3 mt-5 font-weight-bold">{props.holdCount ? props.holdCount : "0"}</p>
                </div>
                <div className="icon">
                <img alt="" src={toAbsoluteUrl("/media/General/hold-diam-aside.svg")} />
                </div>
            </div>
          </a>
        {/* </div> */}
        {/* <a  href="/wishlist" >
            <div className="card-body">
              <div className="row align-items-end">
                <div className="col-8">
                  <div className={cs.bgIcon}>
                      <TiShoppingCart className={cs.cartIcon} />
                  </div>
                <p className="text-light font-weight-bold font-size-lg mt-2 mb-0">{props.language.DASHBOARD_ITEMS_IN_YOUR_HOLD}</p>
                </div>
                <div className="col-4 text-right">
                  <div className="text-white font-weight-bold display-3 mt-1"> {props.itemInWishList ? props.itemInWishList : "00"} </div>
                </div>
              </div>
            </div>
          </a> */}
      </div>
    </>
  );
}
