import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function SearchGridSkeleton() {
    return (
        <>
             <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={400} height={20} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap align-items-center">
                        {Array.from(Array(3), (e, i) => {
                            return (
                                <Skeleton key={i} className="mr-2" variant="rect" width={100} height={30} />
                            );
                        })}
                        <Skeleton className="mr-3" variant="rect" width={40} height={30} />
                    </div>
                </div>
                <div className="card-body pt-5 pb-0">
                    <div className="d-flex flex-wrap justify-content-between gridData">
                        {Array.from(Array(12), (e, i) => {
                            return (
                                <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                                    <div className="row m-0 card rounded-0">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                            <Skeleton variant="rect" height={220} width={280} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2 align-items-center">
                                            <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                            <Skeleton variant="text" width={200} height={30}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="rect" width={100}  height={15}/>
                                            <Skeleton variant="rect" width={100}  height={15}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={80} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-2">
                                            <Skeleton variant="text" width={180} />
                                            <Skeleton variant="text" width={140} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                            <Skeleton variant="rect" width={160} height={40} />
                                            <Skeleton variant="rect" width={70} height={40}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                
             </div>
        </>
    );
}
