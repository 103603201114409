/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { NODE_API_URL } from '../../../../env_config';
import {InvoiceDetailTable} from '../inoviceDetail/InvoiceDetailTable'
export const ExpandedDetails = (data) => {
    const [expandData, setData] = React.useState(data.data);
    const [stoneData, setStoneData] = React.useState([]);
    const history = useHistory()
    useEffect(() => {
        console.log(data.data,"data.data")
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/invoiceListingExpand`,
            headers: { "Content-Type": "application/json" },
            data:{
                "client_id":data.data.user_id,
                "oh_confirm_good_id":data.data.oh_confirm_good_id
            }
        }).then(res => {
            if(res && res.data && res.data.expand){
                setStoneData(res.data.expand)
            }
        })
    },[])
    const RouteToDetail = (row) => {
        history.push({
            pathname: `/invoice-detail/${row.oh_sale_id}`,
            state:{
                "oh_confirm_good_id":data.data.oh_confirm_good_id
            }
            });
    }
    const TrackOrder = (invoicedata) => {
        if(invoicedata.shippingNew === "UPS"){
            window.open(`https://wwwapps.ups.com/WebTracking?AgreeToTermsAndConditions=yes&loc=en_IN&tracknum=${invoicedata.tracking_no}&Requester=trkinppg/trackdetails`)
        }
        if(invoicedata.shippingNew === "FEDEX"){
            window.open(`https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${invoicedata.tracking_no}&locale=en_US&cntry_code=us`)
            
        }
    }
    console.log(stoneData,"stoneDatastoneData")
    return (
        <>
            <div className="invoiceExpand py-5 px-5">
                <div className="row pt-2 ">
                    <div className="col-12 mb-8 d-flex justify-content-between">
                        <div>
                            <p className='font-size-h1 mb-1'> Shipment Shipped Via {(expandData.shippingNew )} {expandData.invoice_country === "IND-EXP"?"(Shipment From India)":"" }</p>
                            {/* <div className="label label-warning label-inline mr-2">{expandData.shipping_status === 1?"Shipped":expandData.shipping_status} </div> */}
                            <div className={`label label-${expandData.shipping_status === 1?"primary":"warning"} label-inline mr-2`}>{expandData.shipping_status === 0?"Pending":expandData.shipping_status === 1?"Shipped":"Return"} </div>

                        </div>
                        <div>
                            <button className="px-8 btn btn-secondary btn-pill btn-lg mr-3" onClick={(e) =>  TrackOrder(expandData) } disabled={expandData.shipping_status === "Pending"}> {data.language.INVOICE_TRACK_ORDER} </button>
                            <span onClick={(e) =>  RouteToDetail(expandData) } className="px-8 btn-pill btn btn-primary btn-lg" >{data.language.INVOICE_TRACK_VIEW_BILL_INVOICE}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <InvoiceDetailTable diamonddetail={stoneData} language={data.language}/>
                    </div>
                </div>
            </div>
        </>
    )
}