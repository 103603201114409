import React, { useMemo } from "react";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../../_helpers";
import objectPath from "object-path";
import { FaPhoneAlt } from 'react-icons/fa';
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import MultiLingual from "../extras/dropdowns/MultiLingual";
import MultiCurrency from "../extras/dropdowns/MultiCurrency";
export function Topbar(props) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewNotificationsDisplay: objectPath.get( uiService.config,  "extras.notifications.display" ),
      viewQuickActionsDisplay: objectPath.get( uiService.config, "extras.quick-actions.display"  ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      viewSearchDisplay: objectPath.get( uiService.config, "extras.search.display" ),
      // viewQuickPanelDisplay: objectPath.get(uiService.config,"extras.quick-panel.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      <div className="d-flex d-md-none">{layoutProps.viewSearchDisplay && <SearchDropdown {...props} />}</div>
      {/* <MultiLingual/> */}
      <div className="align-self-center mr-2 d-none d-md-block"> <FaPhoneAlt className="mr-1" /> <span className="font-weight-bold">{props.language.TOP_BAR_TOLLFREE}:</span>
        {props.userinfo.country === "Australia" ? <a href="tel:+611800841842" className="text-dark text-hover-primary font-weight-bold">+61 1800 841 842</a> 
            ? props.userinfo.country === "Hong Kong" : <a href="tel:+85235655670"  className="text-dark text-hover-primary font-weight-bold">+852 3565 5670</a>
            ? props.userinfo.country === "India" : <a href="tel:+919106558044"  className="text-dark text-hover-primary font-weight-bold">+91 91065 58044</a>
            ? props.userinfo.country === "Singapore" : <a href="tel:+6560185200"  className="text-dark text-hover-primary font-weight-bold">+65 6018 5200</a>
            ? props.userinfo.country === "United Kingdom" : <a href="tel:+441617795441"  className="text-dark text-hover-primary font-weight-bold">+44 161 779 5441</a>
            ? props.userinfo.country === "United States" || props.userinfo.country === "Canada" : <a href="tel:+13322397300"  className="text-dark text-hover-primary font-weight-bold">+1 (332) 239-7300</a>
            :<a href="tel:+13322397300" className="text-dark text-hover-primary font-weight-bold">+1 (332) 239-7300</a>}
      </div>
      <div className="d-none d-md-flex"><MultiCurrency {...props}/></div>
      <MultiLingual/>
      {/* {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )} */}
      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown language={props.language} userinfo={props.userinfo}/>}
      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}
      {layoutProps.viewCartDisplay && <MyCartDropdown />} */}
      {layoutProps.viewUserDisplay && <QuickUserToggler language={props.language} userinfo={props.userinfo}/>}
    </div>
  );
}
