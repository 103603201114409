/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState} from "react";
import { Tab , Nav } from "react-bootstrap";

import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { useLocation } from "react-router-dom";
import CcmodeThemeSetting from "./CcmodeThemeSetting";
import CcmodeTaxSetting from "./CcmodeTaxSetting"
import CcmodeLoginSetting from "./CcmodeLoginSetting"

export const CcmodeSettingNew = (props) => {
    
    // const [updatekey,setUpdateKey] = useState()
    const [key, setKey] = useState('ccmodetheme');
    const location = useLocation()
    const [ccmodesetting,setCCModeSetting] = useState(null)
    useEffect(() => {
        if(props.userinfo.id){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/fetchCCModeSetting`,
                headers: { "Content-Type": "application/json" },
                data: {
                    CustomerId:props.userinfo.id
                },
            }).then(res => {
                if(res && res.data && res.data.data && res.data.data.length){
                    setCCModeSetting(res.data.data[0])
                }
            })
        }
    },[])
    // console.log(props.userinfo.id,"USERIDDDD")
    // console.log(ccmodesetting,"ccmodesetting")
    return (
        <div className="container-fluid">
            <Tab.Container id="settingTab" activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="row">
                    <div className="col-md-3 card custom-card rounded-0">
                        <div className="card-body p-3">
                            <Nav
                                variant="pills"
                                className="flex-column custom_nav"
                            >
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetheme">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Theme</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode theme setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetax">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Currency & Tax</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode Currency & Tax setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodelogin">
                                        <span className="font-size-h5 px-0 col-12">CCMODE Login</span >
                                        <span className="text-muted small px-0 col-12">Client Centric mode Login setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <div className="col-md-9 card custom-card rounded-0">
                        <div className="card-body">
                            <Tab.Content>
                                <Tab.Pane eventKey="ccmodetheme">
                                    <CcmodeThemeSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodetax">
                                    <CcmodeTaxSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodelogin">
                                    <CcmodeLoginSetting userinfo={props.userinfo} language={props.language} ccmodesetting={ccmodesetting}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
};
