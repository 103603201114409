import React from 'react';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

export const MockShortListColumn = (language,userinfo) => [

    {
        Header:language.TRACKLIST_STONE_POPUP_TRACKLIST_NAME,
        accessor:'tracklist_name',
        className:"align-middle text-center",
    },
    {
        Header:language.WISHLIST_DATE,
        accessor: (d) => {
            return (
                <>
                 { d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                {/* { d.CreatedAt} */}
                </>
            );
        },
        className:"text-center align-middle",
    },
    {
        Header:language.CCMODE_SETUP_APPROVED_NOSTONES,
        accessor:'total_stones',
        className:"text-center align-middle",
    },
    {
        Header:language.SEARCHLIST_TOTALCARAT,
        // accessor:'Carats',
        accessor: (d) => {
            return (
                <>
                    <span className="text-primary"> {d.total_carat?d.total_carat.toFixed(2):"0.00"}</span >
                </>
            );
        },
        className:"text-center align-middle",
    },
   
 
]