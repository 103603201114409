import React, {useMemo,useState} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import dummydata from "../dummyresult.json"
import { MockSearchResultColumn } from './Columns'; 
import { FaSortDown, FaSortUp, FaArrowAltCircleRight, FaArrowCircleDown,FaEllipsisV } from "react-icons/fa";
import GlobleFilter from '../tableFilter/GlobleFilter';
import { CheckBox } from '../tableFilter/CheckBox';
import { Dropdown, Modal } from 'react-bootstrap';
import  ExpandedDetails from "./ExpandedDetails"
export const MatchingPair = ({conversionrate,symbol,language})  => {
    console.log(conversionrate)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    const renderRowSubComponent = React.useCallback(
        () => (
            <>
                <ExpandedDetails />
            </>
        ),[]
      )

    const userData = useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    
    // const columns = useMemo(()=> MockSearchResultColumn,[] )
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else if (row.C_Length < row.C_Width) {
                $ratioval = (row.C_Width / row.C_Length).toFixed(2);
            } else if (row.C_Shape === 'HEART') {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else {
                $ratioval = '-';
            }
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }

    const columns = useMemo(()=> MockSearchResultColumn(conversionrate,user,symbol,GetRatio,language),[dummydata] )
    const data = useMemo(()=> dummydata,[])

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // nextPage,
        // previousPage,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // gotoPage,
        // pageCount,
        visibleColumns, 
        // setPageSize,
        setGlobalFilter,
        // selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter,
               
                selectedRowIds
              },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 150 }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div className='m_check'>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span className='m_expander'  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaArrowCircleDown className="text-success "  title="Collapse"/> :  <FaArrowAltCircleRight className="text-primary" title="Expand"/>}
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )
    return (
        <>
        <div>
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">{Object.keys(selectedRowIds).length}  Diamond(s) Selected </span>
                        <span className="text-muted font-weight-bold font-size-sm">
                            Total diamonds: 0 diamonds  |  Total Carat :0.00  |  Total $/CT: $0.00  |  Total Payable: $0.00
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Buy Diamonds</button>
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Add to Cart</button>
                        <button type="button" className="btn btn-sm btn-light mr-2 text-nowrap">Hold Diamond</button>
                        <button type="button" className="btn btn-sm btn-light-primary mr-2 text-nowrap">Modified Search</button>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                    <div  className="p-3" >
                                        <label className="checkbox my-2 border p-2">
                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                            <span className="mr-2"></span>
                                            Show All
                                        </label> 
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="card-body pb-10">
                    <div className="table-responsive w-100 custom-scrollbar" style={{height:"550px"}}>
                        <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                            <thead className="custom_datatable_head sticky-top" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                {page ? page.slice(0, 1).map((row) => {
                                    prepareRow(row)
                                    return(
                                        <>
                                            <tr className="matchingPairTr" {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </>
                                    )
                                }):null}
                            </thead>
                            <tbody className='custom-scrollbar' {...getTableBodyProps()} style={{height:"100px"}}>
                                {page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}