import React  from "react";
import { Grid, Typography, InputLabel,Switch,withStyles } from "@material-ui/core";
import teal from "material-ui/colors/teal";
import { InputField, MultiSelectEdit,CustomCheckbox,MediaAssets} from "../FormFields";
import { Field, useField } from "formik";

const CustomSwitch = withStyles({
    switchBase: {
      color: teal[300],
      '&$checked': {
        color: teal[500],
      },
      '&$checked + $track': {
        backgroundColor: teal[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

export default function NaturalDiamEdit(props) {
    const [field, meta, helper] = useField(props);
    // console.log(meta.error.overlap,"metaNaturalDiamerror")
    // console.log(props.values,"NaturalDiamValues")
    const diamondshape_data = [
      {
        value: 'ROUND',
        label: 'Round'
      },
      {
        value: 'PRINCESS',
        label: 'Princess'
      },
      {
        value: 'EMERALD',
        label: 'Emerald'
      },
      {
        value: 'ASSCHER',
        label: 'Asscher'
      },
      {
        value: 'OVAL',
        label: 'Oval'
      },
      {
        value: 'RADIANT',
        label: 'Radiant'
      },
      {
        value: 'SQUARE RADIANT',
        label: 'Square Radiant'
      },
      {
        value: 'PEAR',
        label: 'Pear'
      },
      {
        value: 'HEART',
        label: 'Heart'
      },
      {
        value: 'TRILLIANT',
        label: 'Trilliant'
      },
      {
        value: 'MARQUISE',
        label: 'Marquise'
      },
      {
        value: 'CUSHION',
        label: 'Cushion'
      },
      {
        value: 'CUSHION MODIFIED',
        label: 'Cushion Modified'
      },
      {
        value: 'TRIANGLE',
        label: 'Triangle'
      }
    ];
    const diamondcolor_data = [
        {
            value:"D",
            label:"D"
        },
        {
            value:"E",
            label:"E"
        },
        {
            value:"F",
            label:"F"
        },
        {
            value:"G",
            label:"G"
        },
        {
            value:"H",
            label:"H"
        },
        {
            value:"I",
            label:"I"
        },
        {
            value:"J",
            label:"J"
        },
        {
            value:"K",
            label:"K"
        },
        {
            value:"L",
            label:"L"
        },
        {
            value:"M",
            label:"M"
        },
        {
            value:"N",
            label:"N"
        },
        {
            value:"OP",
            label:"OP"
        },
        {
            value:"QR",
            label:"QR"
        },
        {
            value:"ST",
            label:"ST"
        },
        {
            value:"UV",
            label:"UV"
        },
        {
            value:"WX",
            label:"WX"
        },
        {
            value:"YZ",
            label:"YZ"
        }
    ];
    const diamondfancy_color_data = [
      {
          value:"YELLOW",
          label:"Yellow"
      },
      {
          value:"PINK",
          label:"Pink"
      },
      {
          value:"BLUE",
          label:"Blue"
      },
      {
          value:"RED",
          label:"Red"
      },
      {
          value:"GREEN",
          label:"Green"
      },
      {
          value:"PURPLE",
          label:"Purple"
      },
      {
          value:"ORANGE",
          label:"Orange"
      },
      {
          value:"VIOLET",
          label:"Violet"
      },
      {
          value:"GREY",
          label:"Gray"
      },
      {
          value:"BLACK",
          label:"Black"
      },
      {
          value:"BROWN",
          label:"Brown"
      },
      {
          value:"CHAMPAGNE",
          label:"Champagne"
      },
      {
          value:"COGNAC",
          label:"Cognac"
      },
      {
          value:"CHAMELEON",
          label:"Chameleon"
      },
      {
          value:"WHITE",
          label:"White"
      }
    ];
    const diamondfancy_intensity_data = [
      {
          value:"FAINT",
          label:"Faint"
      },
      {
          value:"VERY LIGHT",
          label:"Very Light"
      },
      {
          value:"LIGHT",
          label:"Light"
      },
      {
          value:"FANCY LIGHT",
          label:"Fancy Light"
      },
      {
          value:"FANCY",
          label:"Fancy"
      },
      {
          value:"FANCY DARK",
          label:"Fancy Dark"
      },
      {
          value:"FANCY INTENSE",
          label:"Fancy Intense"
      },
      {
          value:"FANCY VIVID",
          label:"Fancy Vivid"
      },
      {
          value:"FANCY DEEP",
          label:"Fancy Deep"
      }
    ];
    const diamondfancy_overtone_data = [
        {
            value:"NONE",
            label:"None"
        },
        {
            value:"YELLOW",
            label:"Yellow"
        },
        {
            value:"Yellowish",
            label:"Yellowish"
        },
        {
            value:"Pink",
            label:"Pink"
        },
        {
            value:"Pinkish",
            label:"Pinkish"
        },
        {
            value:"Blue",
            label:"Blue"
        },
        {
            value:"Blueish",
            label:"Blueish"
        },
        {
            value:"Red",
            label:"Red"
        },
        {
            value:"Reddish",
            label:"Reddish"
        },
        {
            value:"Green",
            label:"Green"
        },
        {
            value:"Greenish",
            label:"Greenish"
        },
        {
            value:"Purple",
            label:"Purple"
        },
        {
            value:"Purplish",
            label:"Purplish"
        },
        {
            value:"Orange",
            label:"Orange"
        },
        {
            value:"Orangy",
            label:"Orangy"
        },
        {
            value:"Violet",
            label:"Violet"
        },
        {
            value:"Violetish",
            label:"Violetish"
        },
        {
            value:"Gray",
            label:"Gray"
        },
        {
            value:"Grayish",
            label:"Grayish"
        },
        {
            value:"Black",
            label:"Black"
        },
        {
            value:"Brown",
            label:"Brown"
        },
        {
            value:"BROWNISH",
            label:"Brownish"
        },
        {
            value:"Champagne",
            label:"Champagne"
        },
        {
            value:"Cognac",
            label:"Cognac"
        },
        {
            value:"Chameleon",
            label:"Chameleon"
        },
        {
            value:"WHITE",
            label:"White"
        }
    ];
    const diamondclarity_data = [
        {
            value:"FL",
            label:"FL"
        },
        {
            value:"IF",
            label:"IF"
        },
        {
            value:"VVS1",
            label:"VVS1"
        },
        {
            value:"VVS2",
            label:"VVS2"
        },
        {
            value:"VS1",
            label:"VS1"
        },
        {
            value:"VS2",
            label:"VS2"
        },
        {
            value:"SI1",
            label:"SI1"
        },
        {
            value:"SI2",
            label:"SI2"
        },
        {
            value:"SI3",
            label:"SI3"
        },
        {
            value:"I1",
            label:"I1"
        },
        {
            value:"I2",
            label:"I2"
        },
        {
            value:"I3",
            label:"I3"
        }
    ];

    const lab_val_data = [
        {
            value:"GIA",
            label:"GIA"
        },
        {
            value:"IGI",
            label:"IGI"
        },
        {
            value:"HRD",
            label:"HRD"
        },
        {
            value:"AGS",
            label:"AGS"
        }
    ];
    
    const cuts_data = [
        {
            value:"ID",
            label:"IDEAL"
        },
        {
            value:"EX",
            label:"Excellent"
        },
        {
            value:"VG",
            label:"Very Good"
        },
        {
            value:"GD",
            label:"Good"
        },
        {
            value:"FR",
            label:"Fair"
        },
        {
            value:"PR",
            label:"Poor"
        }
    ];

    const polishes_data = [
        {
            value:"EX",
            label:"Excellent"
        },
        {
            value:"VG",
            label:"Very Good"
        },
        {
            value:"GD",
            label:"Good"
        },
        {
            value:"FR",
            label:"Fair"
        },
        {
            value:"PR",
            label:"Poor"
        }
    ];

    const flour_data = [
        {
            id: 1,
            value:"NON",
            flour:"NONE"
        },
        {
            id: 2,
            value:"FNT",
            flour:"FAINT"
        },
        {
            id: 3,
            value:"VSLT",
            flour:"VERY SLIGHT"
        },
        {
            id: 4,
            value:"SLT",
            flour:"SLIGHT"
        },
        {
            id: 5,
            value:"MED",
            flour:"MEDIUM"
        },
        {
            id: 6,
            value:"STG",
            flour:"STRONG"
        },
        {
            id: 7,
            value:"VST",
            flour:"VERY STRONG"
        }
    ];

    const shade_data = [
        {
            value:"NONE",
            label:"None"
        },
        {
            value:"BROWN",
            label:"Brown"
        },
        {
            value:"GREEN",
            label:"Green"
        },
        {
            value:"GREY",
            label:"Grey"
        },
        {
            value:"BLACK",
            label:"Black"
        },
        {
            value:"PINK",
            label:"Pink"
        },
        {
            value:"BLUE",
            label:"Blue"
        },
        {
            value:"LIGHT BROWN",
            label:"Light Brown"
        },
        {
            value:"LIGHT GREEN",
            label:"Light Green"
        },
        {
            value:"MIX TINGE",
            label:"Mix Tinge"
        }
    ];

    const milky_data = [
        {
            value:"NO MILKY",
            label:"No Milky"
        },
        {
            value:"LIGHT MILKY",
            label:"Light Milky"
        },
        {
            value:"MILKY",
            label:"Milky"
        }
    ];

    const eyeclean_data = [
        {
            value:"YES",
            label:"Yes"
        },
        {
            value:"NO",
            label:"No"
        }
    ];

    const media_data = [
        {
            value:"ALL",
            label:"ALL"
        },
        {
            value:"IMAGE",
            label:"IMAGE"
        },
        {
            value:"VIDEO",
            label:"VIDEO"
        },
        {
            value:"IMAGE AND VIDEO",
            label:"IMAGE AND VIDEO"
        },
        {
            value:"IMAGE OR VIDEO",
            label:"IMAGE OR VIDEO"
        },
        // {
        //     value:"HA",
        //     label:"HEART AND ARROW"
        // },
        // {
        //     value:"ASSET",
        //     label:"ASSET"
        // }
    ];

    const {
        formField: {
            diamondshape,
            carat_from,
            carat_to,
            diamondcolor,
            diamondfancy_color,
            diamondfancy_intensity,
            diamondfancy_overtone,
            diamondclarity,
            lab_val,
            cuts,
            polishes,
            symmetries,
            diamondflour,
            diamondshade,
            milky,
            eyeclean,
            price_from,
            price_to,
            total_price_from,
            total_price_to,
            media,
            measurementlengthfrom,
            measurementlengthto,
            measurementwidthfrom,
            measurementwidthto,
            measurementheightfrom,
            measurementheightto,
            tablefrom,
            tableto,
            depthfrom,
            depthto,
            ratiofrom,
            ratioto,
            // crownheightfrom,
            // crownheightto,
            // crownanglefrom,
            // crownangleto,
            // pavheightfrom,
            // pavheightto,
            // pavanglefrom,
            // pavangleto,
            // brand,
            // origin,
            // treatment,
            // keytosymbol,
            // overlap
        },
        values,
        setFieldValue
    } = props;
    // console.log(overlap,"overlapoverlap")
    // console.log(props.location,"props.locationprops.location")
    return (
        <React.Fragment>
            {/* <p class="text-center font-weight-bold text-muted">STEP 2 OF 3</p> */}
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL}
            </Typography>
            <p className="mb-1 text-center">
            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURALMSG1}
            </p>
            <p className="mb-5 text-center">
            {/* {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURALMSG2} */}
            that you want to display on your ccmode.
            </p>
            {meta.error.overlap?<h6 className="mb-5 text-center text-danger">{meta.error.overlap}</h6>:""}
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_DIAMONDSHAPE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelectEdit 
                                name={diamondshape.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_DIAMONDSHAPE}
                                fullWidth
                                data={diamondshape_data}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={carat_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                        onChange={(e) => {
                                            setFieldValue("carat_from",e.target.value)
                                            //setFieldValue("caratpricemarkups",[])
                                        }}
                                        value={values.carat_from}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <InputField
                                        name={carat_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                        onChange={(e) => {
                                            setFieldValue("carat_to",e.target.value)
                                            //setFieldValue("caratpricemarkups",[])
                                        }}
                                        value={values.carat_to}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                {props.location && props.location.state && props.location.state.naturaldiamond?
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                    {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_COLORLESS_DIAMOND}* 
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <MultiSelectEdit 
                                    name={diamondcolor.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_COLORLESS_DIAMOND}
                                    fullWidth
                                    data={diamondcolor_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                />
                            </Grid>
                        </Grid>
                        <hr className="w-100 m-0 mt-8" />
                    </Grid>
                :""}
                {props.location && props.location.state && props.location.state.naturalfancydiamond?
                    <Grid item xs={12} md={7}>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                    {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCYCOLOR}* 
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <MultiSelectEdit 
                                    name={diamondfancy_color.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_COLOR}
                                    fullWidth
                                    data={diamondfancy_color_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_color",e.target.value)}
                                />
                                <MultiSelectEdit 
                                    name={diamondfancy_intensity.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_INTENSITY}
                                    fullWidth
                                    data={diamondfancy_intensity_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_intensity_data",e.target.value)}
                                />
                                <MultiSelectEdit 
                                    name={diamondfancy_overtone.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_OVERTONE}
                                    fullWidth
                                    data={diamondfancy_overtone_data} 
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <hr className="w-100 m-0 mt-8" />
                    </Grid>
                :""}
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CLARITY}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelectEdit 
                                name={diamondclarity.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CLARITY}
                                fullWidth
                                data={diamondclarity_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_LAB}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={lab_val.name}
                                label={lab_val.label}
                                data={lab_val_data}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CUT}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={cuts.name}
                                label={cuts.label}
                                data={cuts_data}
                                classes={cuts.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_POLISH}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={polishes.name}
                                label={polishes.label}
                                data={polishes_data}
                                classes={polishes.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_SYMM}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={symmetries.name}
                                label={symmetries.label}
                                data={polishes_data}
                                classes={symmetries.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FLOUR}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelectEdit 
                                name={diamondflour.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FLOUR}
                                fullWidth
                                data={flour_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_SHADE} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelectEdit 
                                name={diamondshade.name}
                                label={props.language.MUI_SHADE}
                                fullWidth
                                data={shade_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_MILKY} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={milky.name}
                                label={props.language.MUI_MILKY}
                                data={milky_data}
                                classes={milky.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_EYECLEAN} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={eyeclean.name}
                                label={props.language.MUI_EYECLEAN}
                                data={eyeclean_data}
                                classes={eyeclean.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                // selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                {props.values.perctinclusive?<Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                USD/CT Price Range
                                <div className="d-flex align-items-center mt-2">
                                    <span className="font-weight-bold text-muted small">Total USD</span>
                                    <CustomSwitch
                                        checked={props.values.perctinclusive}
                                        onClick={() => props.setFieldValue("perctinclusive",!props.values.perctinclusive)}
                                        value="perctinclusive"
                                    />
                                    <span className="font-weight-bold text-muted small">USD/CT</span>
                                </div>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={price_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                        onChange={(e) => {
                                            props.setFieldValue("price_from",values.perctinclusive?e.target.value:null)
                                            props.setFieldValue("total_price_from",values.perctinclusive?Math.round(e.target.value/values.carat_to * 100)/100:e.target.value)
                                    }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <InputField
                                        name={price_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                        onChange={(e) => {
                                            props.setFieldValue("price_to",values.perctinclusive?e.target.value:null)
                                            props.setFieldValue("total_price_to",values.perctinclusive?Math.round(e.target.value * values.carat_to * 100)/100:e.target.value)
                                    }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>:
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_TOTALPRICERANGE}
                                <div className="d-flex align-items-center mt-2">
                                    <span className="font-weight-bold text-muted small">Total USD</span>
                                    <CustomSwitch
                                        checked={props.values.perctinclusive}
                                        onClick={() => props.setFieldValue("perctinclusive",!props.values.perctinclusive)}
                                        value="perctinclusive"
                                    />
                                    <span className="font-weight-bold text-muted small">USD/CT</span>
                                </div>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={total_price_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                        onChange={(e) => {
                                            props.setFieldValue("price_from",values.perctinclusive?e.target.value:null)
                                            props.setFieldValue("total_price_from",values.perctinclusive?Math.round(e.target.value/values.carat_to * 100)/100:e.target.value)
                                    }}
                                        value={values.total_price_from}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={total_price_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                        onChange={(e) => {
                                            props.setFieldValue("price_to",values.perctinclusive?e.target.value:null)
                                            props.setFieldValue("total_price_to",values.perctinclusive?Math.round(e.target.value * values.carat_to * 100)/100:e.target.value)
                                    }}
                                        value={values.total_price_to}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>}
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_MEDIA_ASSETS}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <MediaAssets
                                    name={media.name}
                                    label={media.label}
                                    data={media_data}
                                    fullWidth
                                    setFieldValue={setFieldValue}
                                    values={values}
                                //    selectallfields={props.selectallfields}

                                    // classes={eyeclean.classes}
                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                />  
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <hr className="w-100 m-0 mt-8" /> */}
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                Length
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementlengthfrom.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementlengthto.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                Width
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementwidthfrom.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementwidthto.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                Height
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementheightfrom.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={measurementheightto.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                Table
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={tablefrom.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={tableto.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                Depth
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={depthfrom.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={depthto.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>        
            </Grid>
        </React.Fragment>
    );
}
