import React from "react";
import {MultiSelect} from '../MultiSelect'
// import { Field } from "formik";
// import { toAbsoluteUrl } from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import PropTypes from "prop-types";
// import { at } from "lodash";
// import { useField } from "formik";

export const Shape = ({ language ,formik}) => {

    const ShapeData = [
        {
            id: 1,
            shape: "round",
            value: "ROUND",
        },
        {
            id: 2,
            shape: "princess",
            value: "PRINCESS",
        },
        {
            id: 3,
            shape: "oval",
            value: "OVAL",
        },
        {
            id: 4,
            shape: "heart",
            value: "HEART",
        },
        {
            id: 5,
            shape: "cushion",
            value: "CUSHION",
        },
        {
            id: 6,
            shape: "emerald",
            value: "EMERALD",
        },
        {
            id: 7,
            shape: "marquise",
            value: "MARQUISE",
        },
        {
            id: 8,
            shape: "cushion modified",
            value: "CUSHION MODIFIED",
        },
        {
            id: 9,
            shape: "pear",
            value: "PEAR",
        },
        {
            id: 10,
            shape: "asscher",
            value: "ASSCHER",
        },
        {
            id: 11,
            shape: "radiant",
            value: "RADIANT",
        },
        {
            id: 12,
            shape: "square radiant",
            value: "SQUARE RADIANT",
        },
        {
            id: 13,
            shape: "triangle",
            value: "TRIANGLE",
        },
        {
            id: 14,
            shape: "trilliant",
            value: "TRILLIANT",
        },
        {
            id: 15,
            shape: "other",
            value: "OTHER",
        },
    ];
    return (
        <>
            
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-0">
                        {language.SHAPE_SHAPE}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap d-flex">
                    <MultiSelect language={language} options={ShapeData} label={"Select Shape"} name={"Shape"} formik={formik}/>
                </div>
            </div>
            {/* <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">
                        {language.SHAPE_SHAPE}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap d-flex">
                    {ShapeData.map((item) => (
                        <label
                            key={item.id}
                            className="search_btn_check iframe_setup"
                        >
                            <Field
                                type="checkbox"
                                name="Shape"
                                id={`${item.shape}_shape`}
                                value={item.value}
                            />
                            <div className="labBox selectBox ">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            {item.shape}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-uppercase text-truncate">
                                        {item.shape}
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    ))}
                </div>
            </div> */}
        </>
    );
};
