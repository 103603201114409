/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { BsLightningFill,BsChevronLeft,BsCircle,BsCheckCircleFill } from "react-icons/bs";
import { FaAward } from "react-icons/fa";
import { IoCloseSharp } from 'react-icons/io5';
// import StockAPI from "./StockAPI.json"
import { toAbsoluteUrl } from "../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ConfirmGoods,HoldPopup,SwalWarn } from "../Popup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Checkbox } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { AddCompareDiamond } from "../Popup/AddCompareDiamond";
import { MainImagePopup } from "../widgets/tiles/MainImagePopUp";
import CompareSkeleton from "./CompareSkeleton";
export const Comparison = (props) => {
    
    const location = useLocation()
    const [comparedata,setCompareData] = React.useState([])
    const [showcompare,setShowCompare] = React.useState(false)
    const [update,setUpdate] = React.useState(false)
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
      const [swalWarnOpen,setSwalWarnOpen] = useState(false)
      const [alertMsg,setAlertMsg] = useState()
      const [diamondselected,setDiamondSelected] = useState([])
      const [buypopup,setBuyPopUp] = useState(false)
      const [holdpopup,setHoldPopUp] = useState(false)
      const [buypopupcinr,setBuyPopUpInr] = useState(0)
      const [buypopupctotal,setBuyPopUpTotal] = useState(0)
      const [totalcarat,setTotalCarat] = useState(0)
      const [totalinrcar,setTotalInrCar] = useState(0)
      const [diamondcount,setDiamondcount] = useState(0)
      const [totalourprice,setTotalOurPrice] = useState(0)
    const [mainimageclick,setMainImageClick] = useState(false)
      const [loader,setLoader] = useState(true)
      const [removedata,setRemoveData] = useState(null)
      useEffect(() => {
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            console.log(JSON.parse(getlocalstorage),"IF")
            let comparestones = JSON.parse(getlocalstorage)
            localStorage.setItem("comparestones",JSON.stringify(comparestones))
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
              "Stock_id":comparestones.toString(),
              "Certi_NO":comparestones.toString(),
              "CustomerId":userData.CustomerId
            }
            axios({
              method: "POST",
              url: `${NODE_API_URL}/API/searchById`,
              headers: {
                  "Content-Type": "application/json",
              },
              data: finalData,
            })
            .then(function(res) {
                setLoader(false)
              console.log(res.data.data,"res.data.data")
              setCompareData(res.data.data)
            })
            .catch(function(res) {
                setLoader(false)

            });
        }

      },[update])
      useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = comparedata.find(element => element.id.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selected.length>0?finddata.OurRate/selected.length:0)
                temptotalourprice += finddata.OurPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * props.conversionrate):0)
        setTotalOurPrice(temptotalourprice * props.conversionrate)
    },[selected])
      const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.id.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.id.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }  
      console.log(comparedata,"comparedatacomparedata")
      const RemoveStone = (data) => {
        console.log(data,"REMOVESTONES")
        OpenSwal()
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            let stones = JSON.parse(getlocalstorage).filter(val => val !== data.id.toString())
            stones = stones.filter(val => val !== data.Certi_NO.toString())
            localStorage.setItem("comparestones",JSON.stringify(stones))
            let newselected = selected.filter(diamond => diamond.toString() !== data.id.toString()) 
            setSelected(newselected)
            setUpdate(!update)
            setSwalWarnOpen(false)
            setRemoveData(null)
        }
      }
      const OpenSwal = (data) => {
        setSwalWarnOpen(true)
        setAlertMsg("are you sure you want to remove this stone?")
        setRemoveData(data)
      }
      const CloseSwal = () => {
        setSwalWarnOpen(false)
        setRemoveData(null)
      }
      const [mainimage,setMainImage] = useState()
      const [mainshape,setMainShape] = useState()
      const MainImageLoad = (diamonddata) => {
        console.log(diamonddata,"diamonddata")
        if(diamonddata.aws_image){
            setMainImageClick(true)
            setMainImage(diamonddata.aws_image)
            setMainShape(diamonddata.C_Shape)
        }else{
            setMainImageClick(true)
            setMainImage(`/media/shape/${diamonddata.C_Shape.toLowerCase()}-no.png`)
            setMainShape(diamonddata.C_Shape)
        }
      }
      const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
               
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        console.log(title.length)
        return title.length > 36 ? `${title.substring(0, 40)}...`: title
        // return title
    }
    return (
        <>
            {loader?<CompareSkeleton/>:<div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {props.language.DIAMOND_COMPARISION_DC} ({comparedata.length})
                        </span>
                        <span className="text-muted font-weight-bold font-size-sm">
                            {props.language.SEARCHLIST_TOTALDIAMONDSELECTED}: {selected.length}{" "}
                            {props.language.SEARCHLIST_DIAMONDS} |{" "}
                            {props.language.SEARCHLIST_TOTALCARAT} :{totalcarat.toFixed(2)} | Total $/CT: ${totalinrcar.toFixed(2)} |{" "}
                            {props.language.SEARCHLIST_TOTALPAYABLE}: ${totalourprice.toFixed(2)}
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <button type="button" className="btn btn-primary font-weight-bold mr-2 text-nowrap" onClick={() => BuyDiamond(selected)}><BsLightningFill /> {props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                        <button type="button" className="btn btn-light font-weight-bold mr-2 text-nowrap" onClick={() => HoldDiamond(selected)}> {props.language.SEARCHLIST_HOLDDIAMONDBTN} </button>
                        <button type="button" className="btn btn-danger font-weight-bold mr-2 text-nowrap" onClick={() =>window.history.go(-1)}><BsChevronLeft />{props.language.BACK}</button>
                        <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  
                        <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <div className="row">
                                <div className="col-12" style={{opacity:'0'}}>
                                    <div className="row m-0 card rounded-0 grid_box gridData">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                                            <img className="img-fluid" alt="shape"  src={toAbsoluteUrl(`/media/shape/round-no.png`)} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className="text-primary d-inline-block cursor-pointer font-weight-bold" >N</div>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2">
                                            <div className="text-center">
                                                <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/round.svg`)} />
                                                <span className="text-uppercase small">round</span >
                                            </div>
                                            <span className="text-dark font-weight-bold text-capitalize font-size-h5 flex-wrap d-flex ml-1"> 0.20 ROUND D IF EX EX EX NON NON NON </span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between ">
                                            <span className="text-muted font-size-xl font-weight-bold">$/CT: <span className="text-primary font-size-xl">123</span ></span>
                                            <span className="text-muted font-size-xl font-weight-bold">TOTAL: <span className="text-primary font-size-xl">123</span ></span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                                            <FaAward />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12" style={{opacity:'0'}}>
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <div className=" d-flex align-items-center justify-content-between border px-3">
                                                <label className="checkbox my-2">
                                                    <input type='checkbox' />
                                                    <span className="mr-2"></span>
                                                    S
                                                </label> 
                                                <button type="button" className="close btn btn-light btn-text-primary btn-hover-text-primary font-weight-bolder font-size-h3" >
                                                    <span aria-hidden="true"><IoCloseSharp /></span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="text-center p-0" style={{minHeight:'126px'}}>
                                                
                                                    <img
                                                        className="img-fluid compareDiam mr-2"
                                                        alt="round"
                                                        src={toAbsoluteUrl(
                                                            `/media/shape/round-no.png`
                                                        )}
                                                        style={{ backgroundColor: "#f4f4f4"}}
                                                    />
                                               
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 text-center">
                                            <div className="text-muted small font-weight-bold">$ </div>
                                            <div className="text-primary font-size-h3 font-weight-bold">$00 </div>
                                            <div className={` small font-weight-bold`}>00 </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 mt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>D</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DIAMONDTYPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SHAPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CARAT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_COLOR}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CLARITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CUT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_POL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SYMM}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_FLOU}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LAB}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CERTIFICATE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LOCATION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DELIN}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>P</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MEASUREMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TABLE}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_DEPTH}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_CROWN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_PAVILION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLECONDTION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_CULET}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_INTENSITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_OVERTONE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.CARAT_FANCY} {props.language.SEARCHLIST_COLOR}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>A</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_SHADE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MILKY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_EYECLEAN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_AVIAIL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TREATMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_KEY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_BRAND}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_ORIGIN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GROWTHTYPE}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-md-10 custom-scrollbar">
                            <div className="row" style={{minWidth:'1500px'}}> 
                                { comparedata ? comparedata.slice(0, 4).map((data) =>(
                                    <div className="compare-diamond-col-3" key={data.id}>
                                        <div className="row m-0 card rounded-0 mx-auto grid_box gridData">
                                            <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                                                <div className="gridCheck">
                                                    <Checkbox
                                                        checked={isSelected(data.id)}
                                                        className=""
                                                        inputProps={{ 'aria-labelledby': props.labelId }}
                                                        onClick={(event) => handleClick(event, data.id)}
                                                        icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                                                    />
                                                </div>
                                                <button type="button" className="close gridClose btn btn-light btn-text-primary btn-hover-text-primary font-weight-bolder font-size-h3" onClick={() => OpenSwal(data)}>
                                                    <span aria-hidden="true"><IoCloseSharp /></span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                            <SwalWarn show={swalWarnOpen} message={alertMsg} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => RemoveStone(removedata)} onHide={() => CloseSwal()}/> 

                                                {data.aws_image ? (
                                                    <div>
                                                    <LazyLoadImage
                                                        className="img-fluid"
                                                        src={ data.aws_image}
                                                        alt={data.C_Shape}
                                                        onError={e => {
                                                            e.currentTarget.src = toAbsoluteUrl(
                                                                `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                                )
                                                            }}
                                                        effect="blur" 
                                                        onClick={() => MainImageLoad(data)}
                                                    />
                                                    </div>
                                                ) : (
                                                    <div>
                                                    <img
                                                        className="img-fluid"
                                                        alt="shape"
                                                        src={toAbsoluteUrl(
                                                            `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                        )}
                                                        onClick={() => MainImageLoad(data)}
                                                    />
                                                    </div>
                                                    
                                                )}
                                <MainImagePopup Image={mainimage} Shape={mainshape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                                            </div>
                                            <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="text-primary d-inline-block cursor-pointer font-weight-bold" >{data.diamond_type + "-" + data.id}</div>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `Business Process`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `Guaranteed`
                                                                    : data.availability === "Not Available"
                                                                    ? `Not Available`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `Subject to Prior sale`
                                                                    : `Unknown`
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="availIco mx-2"
                                                            alt={data.availability}
                                                            src={toAbsoluteUrl(`/media/svg/${
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `availability_primary.svg`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `availability_success.svg`
                                                                    : data.availability === "Not Available"
                                                                    ? `availability_danger.svg`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `availability_warning.svg`
                                                                    : `availability_blank_white.svg`
                                                            }`)}
                                                        />
                                                        
                                                    </OverlayTrigger>
                                                </div>
                                                <div className="font-weight-bold">
                                                    {data.Lab}: 
                                                    <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary mx-2 text-left"
                                                    href={
                                                        data.Lab === "IGI"
                                                            ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                            : data.Lab === "GIA"
                                                            ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                            : data.Lab === "HRD"
                                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                            : data.Lab === "GCAL"
                                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                            : data.Certi_link
                                                        }
                                                    >
                                                        {data.Certi_NO}
                                                    </a>
                                                </div>
                                                <div> 
                                                    {data.country ? (
                                                        <OverlayTrigger
                                                        placement="top"
                                                        className="text-capitalize"
                                                        overlay={
                                                            <Tooltip id="tooltip">{data.country}</Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="flagIco mr-1"
                                                            alt={data.country}
                                                            src={toAbsoluteUrl(
                                                                `/media/map/${data.country.toLowerCase()}.png`
                                                            )}
                                                            onError={e => {
                                                                e.currentTarget.src =toAbsoluteUrl(
                                                                    `/media/map/other.png`
                                                                    )
                                                                }}
                                                        />
                                                    </OverlayTrigger>
                                                    ) : (
                                                        null
                                                    )}
                                                </div >
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex align-items-center  px-2">
                                                <div className="text-center">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {data.C_Shape}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${data.C_Shape.toLowerCase()}.svg`)} />
                                                    </OverlayTrigger>
                                                    <div className="text-uppercase small">{data.C_Shape}</div >
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            {data.C_Weight.toFixed(2)} {data.C_Shape}{" "}
                                                            {(data.C_Color ==="fancy") ? (<>{data.f_intensity} {data.f_overtone} {data.f_color}</>): data.C_Color}{" "}
                                                            {data.C_Clarity} {data.C_Cut}{" "}
                                                            {data.C_Polish} {data.C_Symmetry} {data.C_Fluorescence}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-dark  font-weight-bold text-capitalize font-size-h5 flex-wrap grid_title ml-1 d-flex align-items-center d-flex align-items-center">
                                                        {GenerateTitle(data)}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between ">
                                                <span className="text-muted font-size-xl font-weight-bold">{props.symbol}/CT: <span className="text-primary font-size-xl">{props.symbol}{(data.OurRate * props.conversionrate).toFixed(2)}</span ></span>
                                                <span className="text-muted font-size-xl font-weight-bold">TOTAL: <span className="text-primary font-size-xl">{props.symbol}{(data.OurPrice * props.conversionrate).toFixed(2)}</span ></span>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-start align-items-center mb-2">
                                            {/* <div className="d-flex justify-content-center align-items-center"> */}
                                                {data.aws_image ? (
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip">Image</Tooltip>}
                                                        >
                                                            <ImagePopup
                                                                Image={data.aws_image}
                                                                Shape={data.C_Shape}
                                                            />
                                                            {/* <a className="text-primary mx-2 text-center" href={data.aws_image}><BsCardImage/></a> */}
                                                        </OverlayTrigger>
                                                    </span>
                                                ) : ""}
                                                {data.video ? (
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip">Video</Tooltip>}
                                                        >
                                                            <VideoPopup
                                                                ProductId={data.id}
                                                                WLDiamondType={data.diamond_type}
                                                                Certi_No={data.Certi_NO}
                                                                Video={data.video}
                                                            />
                                                            {/* <a className="text-primary mx-2 text-center" href={data.video}><BsFillCameraVideoFill/></a> */}
                                                        </OverlayTrigger>
                                                    </span>
                                                ) : ""}
                                                {data.Certi_NO  ? (
                                                    <span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">Certificate</Tooltip>
                                                            }
                                                        >
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-primary mx-2 text-left"
                                                                href={
                                                                    data.Lab === "IGI"
                                                                        ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                                        : data.Lab === "GIA"
                                                                        ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                        : data.Lab === "HRD"
                                                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                                        : data.Lab === "GCAL"
                                                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                        : data.Certi_link
                                                                }
                                                            >
                                                                <FaAward />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </span>
                                                ) : ""}
                                            {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-12">
                                                <div className=" d-flex align-items-center justify-content-between border px-3">
                                                    <label className="checkbox my-2">
                                                        <input 
                                                        type='checkbox'
                                                        checked={isSelected(data.id)}
                                                        className=""
                                                        onClick={(event) => handleClick(event, data.id)}
                                                         />
                                                        <span className="mr-2"></span>
                                                        {props.language.DIAMOND_COMPARISION_SELECTBUY}
                                                    </label> 
                                                    
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="text-center p-0">
                                                    {data.aws_image &&  data.aws_image !=="null" ? (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.C_Shape}
                                                            src={data.aws_image}
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.C_Shape}
                                                            src={toAbsoluteUrl(
                                                                `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                            )}
                                                            height="130px"
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3 text-center">
                                                <div className="text-muted small font-weight-bold"> $/CT ${data.OurRate.toFixed(2)} </div>
                                                <div className="text-primary font-size-h3 font-weight-bold">${data.OurPrice.toFixed(2)} </div>
                                                <div className={`${(data.discount_main >= 0) ? 'text-success' : 'text-danger'} small font-weight-bold`}>{data.discount_main}% </div>
                                            </div>*/}
                                            <div className="col-12 mt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                                {props.language.DETAIL_DIAMONDDETAILS}
                                            </div> 
                                            <div className="col-12 py-3 text-center">
                                                {/* <div className="mt-3">{data.diamond_type}-{data.id}</div> */}
                                                <div className="mt-3 text-primary">{data.diamond_type === "L" ? "Lab Grown Diamond" : "Natural Diamond"}</div>
                                                <div className="mt-3 text-capitalize">{data.C_Shape?data.C_Shape:"-"}</div>
                                                <div className="mt-3">{data.C_Weight?data.C_Weight.toFixed(2):"-"}</div>
                                                <div className="mt-3">{data.C_Color?data.C_Color:"-"}</div>
                                                <div className="mt-3">{data.C_Clarity?data.C_Clarity:"-"}</div>
                                                <div className="mt-3">{data.C_Cut?data.C_Cut:"-"}</div>
                                                <div className="mt-3">{data.C_Polish?data.C_Polish:"-"}</div>
                                                <div className="mt-3">{data.C_Symmetry?data.C_Symmetry:"-"}</div>
                                                <div className="mt-3">{data.C_Fluorescence?data.C_Fluorescence:"-"}</div>
                                                <div className="mt-3">{data.Lab?data.Lab:"-"}</div>
                                                <div className="mt-3">
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-primary"
                                                        href={
                                                            data.Lab === "IGI"
                                                                ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                                : data.Lab === "GIA"
                                                                ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                : data.Lab === "HRD"
                                                                ? `http://ws2.hrdantwerp.com/HRdata.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                                : data.Lab === "GCAL"
                                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                : data.Certi_link
                                                        }
                                                    >
                                                        {data.Certi_NO?data.Certi_NO:"-"}
                                                    </a>
                                                </div>
                                                <div className="mt-3">{data.country ? data.country : "-"}</div>
                                                <div className="mt-3">{data.shipping_days?data.shipping_days:"-"} days</div>
                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                                {props.language.MUI_PARAM_DETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.C_Length?data.C_Length.toFixed(2):"0.00"}*{data.C_Width?data.C_Width.toFixed(2):"0.00"}*{data.C_Depth?data.C_Depth.toFixed(2):"0.00"}</div>
                                                <div className="mt-3">{data.C_TableP?data.C_TableP:"0.00"}%</div>
                                                <div className="mt-3">{data.C_DefthP?data.C_DefthP:"0.00"}%</div>
                                                <div className="mt-3">{data.Crn_Ht ? <>{data.Crn_Ht}%</> : "-"}, {data.Crn_Ag ? <>{data.Crn_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.Pav_Dp ? <>{data.Pav_Dp}%</> : "-"}, {data.Pav_Ag ? <>{data.Pav_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.gridle_per ? data.gridle_per : "-"}</div>
                                                <div className="mt-3">{data.gridle ? data.gridle : "-"}</div>
                                                <div className="mt-3">{data.cutlet ? data.cutlet : "-"}</div>
                                                <div className="mt-3">{data.f_intensity ? data.f_intensity : "-"}</div>
                                                <div className="mt-3">{data.f_overtone ? data.f_overtone : "-"}</div>
                                                <div className="mt-3">{data.f_color ? data.f_color : "-"}</div>

                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                                {props.language.MUI_ADDITIONALDETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.shade ? data.shade : "-"}</div>
                                                <div className="mt-3">{data.Milky ? data.Milky : "-"}</div>
                                                <div className="mt-3">{data.EyeC ? data.EyeC : "-"}</div>
                                                <div className="mt-3">
                                                    {data.availability === "Memo" ||
                                                        data.availability === "Hold"
                                                            ? `Business Process`
                                                            : data.availability === "Guaranteed"
                                                            ? `Guaranteed`
                                                            : data.availability === "Not Available"
                                                            ? `Not Available`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `Subject to Prior sale`
                                                            : `Unknown`}
                                                </div>
                                                {/* <div className="mt-3">{data.treatment ? data.treatment : "-"}</div> */}
                                                <div className="mt-3">{data.diamond_type === "L" ?data.lab_treat?data.lab_treat === "Treated"?"Post Growth Treatment":data.lab_treat:data.green?data.green:"-":data.green?data.green:"-"}</div>
                                                <div className="mt-3 text-truncate mx-auto"  style={{maxWidth:"110px"}}>
                                                    {data.Key_Symbols ?
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        {data.Key_Symbols}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span> {data.Key_Symbols}</span>
                                                            </OverlayTrigger> 
                                                        </>
                                                    : "-"}
                                                </div>
                                                <div className="mt-3">{data.canada_mark ? data.canada_mark : "-"}</div>
                                                <div className="mt-3">{data.diamond_type === "N" && data.brown ? data.brown : "-"}</div>
                                                <div className="mt-3">{data.diamond_type === "L" && data.brown ? data.brown : "-"}</div>
                                        
                                            </div>
                                        </div>
                                    </div>
                                )) : null}
                                {comparedata.length === 0?<>
                                <div className="col-3 compareEmptyBox text-center" key={0}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={1}>
                                   <div> <button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 1?<>
                                <div className="col-3 compareEmptyBox" key={1}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 2?<>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 3?<>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                <AddCompareDiamond show={showcompare} close={() => setShowCompare(false)} onHide={() => setShowCompare(false)} setUpdate={setUpdate} update={update}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};
