import React from "react";
import { Field } from "formik";

export const Eyeclean = ({language}) => {
    
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.TINGE_TINGE}</p>
                </div>
                <div className="col-lg-10 d-flex flex-wrap flex-md-nowrap">
                    <label className="search_btn_check w-100">
                        <Field type="checkbox" name="eyeclean" id="Yes_tinge" value="YES"/>
                        <div className="tingeBox selectBox">
                            <span>YES, 100%EYECLEAN</span>
                        </div>
                    </label>
                    <label className="search_btn_check w-100">
                        <Field type="checkbox" name="eyeclean" id="No_tinge" value="NO"/>
                        <div className="tingeBox selectBox">
                            <span>NO</span>
                        </div>
                    </label>
                    <label className="search_btn_check w-100">
                        <Field type="checkbox" name="eyeclean" id="UNKNOWN" value="UNKNOWN"/>
                        <div className="tingeBox selectBox">
                            <span>OTHER</span>
                        </div>
                    </label>
                </div>
            </div>
        </>
    );
};