import React from "react";
import { Accordion, Card } from "react-bootstrap";
import {Eyeclean} from "./EyeClean/Eyeclean"
import { Shade } from "./EyeClean/Shade";

export const EyeClean = ({language,formik}) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {language.TINGE_TINGE}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Eyeclean language={language}/>
                           <Shade language={language} formik={formik}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
