import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm";

const PUBLIC_KEY = "pk_test_51M3wW4SJKIyNAsDy9uMERZDyTc4RfKWWYXasZhAWSI83j7k29EnnuVJbn0fcRpuSb3xSFESM9HImNrMm2bgw5TB0005TUmjor7";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Stripe = () => {
  return (
    <Elements stripe={stripeTestPromise} className=".StripeElement--webkit-autofill">
      <CheckoutForm />
    </Elements>
  );
};

export default Stripe;