/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";

export const SalesExecutiveTile = (props) => {

  
    return (
        <>
            <div className={`card card-custom gutter-b`}>
                <div className="card-body salesExecutiveTile">
                    <span className="small_title">{props.language.DASHBOARD_SALESEXECUTIVE}</span>
                    <div className="d-flex align-items-center mt-5">
                        <div className="mr-8">
                            <img
                                width="86"
                                className="rounded-circle bg-light p-2"
                                src="/media/General/usericon.png"
                                alt="user img"
                            />
                        </div>
                        <div className="natTab">
                            <span className="sales_exc_name text-capitalize font-size-h2 font-weight-bolder text-pripary ">
                                {props.salesData.fname ? props.salesData.fname : null}{" "}
                                {props.salesData.lname ? props.salesData.lname : null}
                            </span>
                            <span className="contact_card_img d-block mt-2">
                                <FaWhatsapp className="text-success font-size-h6" />
                                <a
                                    className="ml-3 text-primary font-size-h6"
                                    href={`https://wa.me/${
                                        props.salesData.mobile ? props.salesData.mobile : null
                                    }`}
                                >
                                    {props.salesData.mobile ? props.salesData.mobile : null}
                                </a>
                            </span>
                            <span className="contact_card_img d-flex align-items-center mt-1">
                                <FaEnvelope className="text-danger font-size-h6" />
                                <a
                                    className="ml-3 text-primary mt-0 font-size-h6 text-break"
                                    href={`mailto:${
                                        props.salesData.mail
                                            ? props.salesData.mail ===
                                              "admin@diamondsoncall.com"
                                                ? "info@diamondsoncall.com"
                                                : props.salesData.mail
                                            : null
                                    }`}
                                >
                                    {props.salesData.mail
                                        ? props.salesData.mail ===
                                          "admin@diamondsoncall.com"
                                            ? "info@diamondsoncall.com"
                                            : props.salesData.mail
                                        : null}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
