import React, { useState,useEffect,useCallback } from 'react'
import { Formik, Form } from "formik";
import axios from "axios";
import { FaSearch } from 'react-icons/fa';
import { Link,useHistory } from "react-router-dom";
import {
    makeStyles ,Paper, InputBase, IconButton,Snackbar,SnackbarContent,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import red from "material-ui/colors/red";
import { Basic } from './searchcomponent/Basic';
import { ParcelSearchResult } from './ParcelSearchResult';
import { NODE_API_URL} from "./../../../env_config";
import { SwalWarn } from "../Popup";

const useStyles = makeStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 150,
      boxShadow:'none!important',
      backgroundColor: '#F9FAFF',
      border:'solid 2px #E0E7FF',
      height: '36px',
    //   marginRight: 50
    },
    input: {
      marginLeft: 8,
      flex: 1,
      fontWeight: 500,
      color : "#8798AD"
    },
    iconButton: {
      padding: 5,
      color : "#8798AD"
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
    error: {
      backgroundColor: red[600],
    },
  });

export const ParcelDiamSearch = ({userinfo,language,conversionrate,symbol}) => {

    const history = useHistory();
    const classes = useStyles();
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [msgOnResponse,setMsgOnResponse] = useState()
    const [pathName,setPathName] = useState('')
    const [parcelResultOpen, setParcelResultOpen] =useState(true);
    const [parcelSearchOpen, setParcelSearchOpen] =useState(true);
    const [search,setSearch] = useState()
    const [openLoader, setOpenLoader] =useState(false);
    const [isSubmitting, setIsSubmitting] =useState(false);
    const [resultData,setResultData]=useState([])
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [loading, setLoading] =useState(false);
    const [colorErr, setColorErr] =useState(false);
    const [minCaratErr, setMinCaratErr] =useState(false);
    const [clarityErr, setClarityErr] =useState(false);
    const [rangeErr, setRangeErr] =useState(false);
    const [caratErr, setCaratErr] =useState(false);
    const [pcsErr, setPcsErr] =useState(false);
    const [dimondType, setDiamondType] =useState('');
    const [windowSize, setWindowSize] =useState(0);
    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);
    useEffect(() => {
        // console.log("resultData",resultData)
        if( window.innerWidth > 760) {
            setParcelResultOpen(true)
            setParcelSearchOpen(true)
        } else {
            setParcelResultOpen(false)
            setParcelSearchOpen(true)
        }
        
    },[])
    useEffect(() => {
        window.DataParcel = null
        if(window.location.pathname === "/parcel-diamond"){
            setDiamondType("PN")
        }
        if(window.location.pathname === "/lab-parcel-diamond"){
            setDiamondType("PL")
        }
    },[])

    function handleClose(event, reason) {
        setAlertOpen(false);
    }


    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    const initialValues = {
        "shape":window.DataParcel && window.DataParcel.shape ? window.DataParcel.shape : "ROUND",
        "range_value_from":window.DataParcel && window.DataParcel.range_value_from ?`${window.DataParcel.range_value_from}` : "1",
        "range_value_to":window.DataParcel && window.DataParcel.range_value_to ? `${window.DataParcel.range_value_to}` : "63",
        "color":window.DataParcel && window.DataParcel.color ? window.DataParcel.color : "",
        "clarities":window.DataParcel && window.DataParcel.clarities ? window.DataParcel.clarities : "",
        "pcs_value":window.DataParcel && window.DataParcel.pcs_value ? window.DataParcel.pcs_value : "",
        "carat_value":window.DataParcel && window.DataParcel.carat_value ? window.DataParcel.carat_value : "",
        "diamond_type":dimondType || "PN",
        "type":window.DataParcel && window.DataParcel.type ? window.DataParcel.type : "carat",
        "cx_country":userinfo.country,
        }
    
    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        setIsSubmitting(true)
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        setIsSubmitting(false)
    };

    function handelRest(newValue) {
        // setParcelResultOpen(false)
        // setParcelResultOpenAnim(0)
        setTimeout(() => {
            window.location.reload(true);
        }, 400);
    }

    const SearchParcelById = () => {
        if(search){
          const finalData = {
            "parcelId": search.split('-')[1],
            "cx_country":userinfo.country,
          }
          axios({
            method: "POST",
            url: `${NODE_API_URL}/parcel/searchPrclByPid`,
            headers: {
                "Content-Type": "application/json",
            },
            data: finalData,
          })
          .then(function(res) {
            if(res && res.data){
                const dataa = JSON.stringify(res.data.data)
                disableLoading();
                if(window.location.pathname === "/parcel-diamond" && res.data.data.diamond_type === "PL" ){
                    setPathName("/lab-parcel-diamond")
                    setMsgOnResponse("Lab-Grown Parcel Diamond")
                    setSwalWarnOpen(true)
                    const alertMessage = (
                        <div>
                          Please visit{' '}
                          <Link className='text-success' to='/parcel-diamond'>
                            Lab-Grown Parcel Diamond
                          </Link>{' '}
                          to search for Lab Parcel Stock.
                        </div>
                      );
                    setAlertMessage(alertMessage);
                }else if (window.location.pathname === "/lab-parcel-diamond" && res.data.data.diamond_type === "PN"){
                    setPathName("/parcel-diamond")
                    setMsgOnResponse("Natural Parcel Diamond")
                    setSwalWarnOpen(true)
                    const alertMessage = (
                        <div>
                          Please visit{' '}
                          <Link className='text-primary' to='/parcel-diamond'>
                            Natural Parcel Diamond
                          </Link>{' '}
                          to search for Natural Parcel Stock.
                        </div>
                      );
                    setAlertMessage(alertMessage);

                }else{
                    setResultData(JSON.parse(dataa))
                }
                window.scrollTo(0, 0)
                setSearch('')
            }
            else{
              disableLoading();
              setSearch('')
            }
          })
          .catch(function(res) {
            if(res.response.data.msg === "No Record Found"){
                disableLoading();
                setAlertOpen(true);
                setSearch('')
            }
            if(res.response.data.message === "Please Provide valid req body"){
                disableLoading();
                setAlertOpen(true);
                setSearch('')
            }
            disableLoading();
          });
        }
    }

    const changeUrlOnId = () =>{
        // console.log(pathName)
        history.push({ pathname: pathName})
    }
    function handleSearch(values) {
        // console.log(values)
        // console.log(values)
        enableLoading()
        // const finalData = `${};
        axios({
            method: "POST",
            url: `${NODE_API_URL}/parcel/searchDiamond`,
            headers: { "Content-Type": "application/json" },
            data: values,
        })
        .then(function(res) {
            if(res && res.data){
                // console.log(res.data)
              const dataa = JSON.stringify(res.data)
              disableLoading();
              setResultData(JSON.parse(dataa))
              window.scrollTo(0, 0)
          }
        })
        .catch(function(res) {
            console.log("error",res)
            disableLoading();
        });
    }
    
    return (
        <>
            <div className={`row ${dimondType === "PN" ? "natTab" : "labTab" }`} >
                {parcelSearchOpen ?
                <div className={`${parcelResultOpen ? "col-md-4" : "col-md-12"}`} style={{maxHeight:"80vh"}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        // validationSchema= {Schema}
                        onSubmit={async (values, { setSubmitting }) => {
                            // enableLoading();
                            window.DataParcel = values;
                            // console.log(values)
                            if(values.range_value_from ==='' || values.range_value_to ===''){
                                setRangeErr(true)
                            }
                            if(values.color ===''){
                                setColorErr(true)
                            }
                            if(values.clarities ===''){
                                setClarityErr(true)
                            }
                            if ( values.carat_value ==='' && values.pcs_value ===''){
                                setCaratErr(true)
                            }
                           
                            if (values.shape === '' || values.range_value_from ==='' || values.range_value_to ===''|| values.color ==='' || values.clarities ==='' || ( values.carat_value ==='' && values.pcs_value ==='') || pcsErr || minCaratErr){
                                    return
                            } else{
                                Object.keys(values).forEach((key) => {
                                    if (
                                        values[key] === "" ||
                                        values[key] === null ||
                                        values[key].length === 0
                                    ) {
                                        delete values[key];
                                    }
                                });
                                setTimeout(() => {
                                    setRangeErr(false)
                                    setColorErr(false)
                                    setClarityErr(false)
                                    setCaratErr(false)
                                    handleSearch(values);
                                    
                                    setParcelResultOpen(true)
                                    if( window.innerWidth > 700) {
                                        setParcelResultOpen(true)
                                        setParcelSearchOpen(true)
                                    } else {
                                        setParcelResultOpen(true)
                                        setParcelSearchOpen(false)
                                    }
                                }, 1000);
                            }
                        }}
                        >
                        {(formik, handleSubmit) => (
                            <>
                                <Form>
                                    <div className="card card-custom card-sticky">
                                    <div className="card-header align-items-center ribbon ribbon-top">
                                        {/* <div className="ribbon-target bg-warning" style={{top:"-2px",right:"20px"}} >Beta</div> */}
                                        <div className="card-title text-primary">{dimondType === "PN" ? language.PARCEL_DIAMONDS_NATURAL:language.PARCEL_DIAMONDS_LAB}{language.PARCEL_DIAMONDS_PARCEL_DIAMONDS}</div>
                                        { window.innerWidth > 700 ? 
                                            null
                                        :
                                            <div className="card-toolbar p-5 text-right sticky-top">
                                                <span onClick={()=>{setParcelResultOpen(true); setParcelSearchOpen(false);}}><FaSearch /> Go to Result</span> 
                                            </div>
                                        }
                                        <Paper className={classes.root}>
                                            <InputBase
                                                className={`${classes.input} ${isSubmitting ? "cursor-na" : ""}`}
                                                placeholder={language.SEARCHBAR_PARCEL_PLACEHOLDER}
                                                inputProps={{ 'aria-label': 'Search by ID' }}
                                                onChange={(e) => setSearch(e.target.value)}
                                                onKeyDown={(e) => e.keyCode === 13?SearchParcelById():""}
                                                value={search}
                                            />
                                                <IconButton className={`${classes.iconButton} ${isSubmitting ? "cursor-na" : ""}`}aria-label="Search" onClick={SearchParcelById} disabled={isSubmitting}>
                                                    <SearchIcon/>
                                                </IconButton>
                                            <Backdrop open={openLoader} >
                                            <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
                                            </Backdrop>
                                        </Paper>
                                    </div>
                                        {/* <div className="card-header sticky-top bg-white"style={{top:"50px"}}>
                                            <div className="card-title text-primary">{dimondType === "PN" ? language.PARCEL_DIAMONDS_NATURAL:language.PARCEL_DIAMONDS_LAB}{language.PARCEL_DIAMONDS_PARCEL_DIAMONDS}</div>
                                            { window.innerWidth > 700 ? 
                                                null
                                            :
                                                <div className="card-toolbar p-5 text-right sticky-top">
                                                    <span onClick={()=>{setParcelResultOpen(true); setParcelSearchOpen(false);}}><FaSearch /> Go to Result</span> 
                                                </div>
                                            }
                                        </div> */}
                                        <div className="card-body parcelSearch py-0 custom-scrollbar" style={{maxHeight:"64vh"}}>
                                            <div className="row">
                                                <div className={`${parcelResultOpen ? "col-md-12" : "col-md-5"} mb-10`}>
                                                    <Basic
                                                        colorErr={colorErr}
                                                        clarityErr={clarityErr}
                                                        rangeErr={rangeErr}
                                                        caratErr={caratErr}
                                                        pcsErr={pcsErr}
                                                        minCaratErr={minCaratErr}
                                                        formik={formik}
                                                        language={language}
                                                        setRangeErr={setRangeErr}
                                                        setColorErr={setColorErr}
                                                        setClarityErr={setClarityErr}
                                                        setCaratErr={setCaratErr}
                                                        setPcsErr={setPcsErr}
                                                        setMinCaratErr={setMinCaratErr}
                                                        dimondType={dimondType}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="card-footer bg-white natTab text-center sticky-top py-5"
                                            style={{ bottom: "0" }}
                                        >
                                            <button
                                                type="submit"
                                                className={`btn btn-primary px-10 font-weight-bold btn-square mr-2 ${isSubmitting ? "cursor-na" : ""}`}
                                               // disabled={(formik.values.shape === '' || formik.values.range_value_from ==='' || formik.values.range_value_to ===''|| formik.values.color ==='' || formik.values.clarities ==='') || isSubmitting ? true : false}
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting
                                                    ? "Please wait..."
                                                    : language.PARCEL_DIAMONDS_SEARCH}
                                                
                                            </button>
                                            <Backdrop
                                                sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                open={openLoader}
                                            >
                                                <CircularProgress color="secondary" />
                                            </Backdrop>
                                            <button
                                                type="reset"
                                                onClick={handelRest}
                                                disabled={isSubmitting}
                                                className={`btn font-weight-bold btn-square btn-warning px-10 ${isSubmitting ? "cursor-na" : ""}`}
                                            >
                                                {language.PARCEL_DIAMONDS_RESET}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div> :""}
                {parcelResultOpen ?
                    <div className="col-md-8 parcelResultBlock bg-white rounded custom-scrollbar" style={{maxHeight:"80vh"}}>
                        <ParcelSearchResult language={language} userinfo={userinfo} resultData={resultData} setResultData={setResultData} setParcelSearchOpen={setParcelSearchOpen} setParcelResultOpen={setParcelResultOpen} symbol={symbol} conversionrate={conversionrate}/>
                    </div> : ""}
            </div>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} msgOnOk={msgOnResponse} responseOnOk={() => changeUrlOnId()}/> 
            <Snackbar
                anchorOrigin={{
                vertical: "top",
                horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                onClose={handleClose}
                variant="error"
                message={`No Stock Found`}
                />
            </Snackbar>
        </>
    )
}