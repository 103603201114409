import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toAbsoluteUrl } from "../../../_helpers";
import { MainImagePopup } from "./MainImagePopUp";
export const MainImageExpandedDetailsPopup = (props) => {
    const history = useHistory()
    function handelDetails(row) {
        history.push({
            pathname: `/detail/${row.data.diamond_type}-${row.data.id}`
        });
    }
    const [mainimageclick,setMainImageClick] = useState(false)
    const ImageUrl = (data) => {
        let image = ""
        if (data.ext_status === "Success") {
            image = data.ext_img_runtime
        }
        else if (data.aws_image) {
            image = data.aws_image
        }
        return image
    }
    return (
        <>
            {ImageUrl(props.data) ? <div>
                <MainImagePopup Image={ImageUrl(props.data)} Shape={props.data.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                <LazyLoadImage
                    className="img-fluid cursor-pointer"
                    onClick={() => setMainImageClick(true)}
                    src={ImageUrl(props.data)}
                    alt={props.data.C_Shape}
                    onError={e => {
                        e.currentTarget.src = toAbsoluteUrl(
                            `/media/shape/${props.data.C_Shape.toLowerCase()}-no.png`
                        )
                    }}
                    effect="blur"
                />
            </div> : <div>
            <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${props.data.C_Shape.toLowerCase()}-no.png` )} Shape={props.data.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                <img
                    className="img-fluid cursor-pointer"
                    onClick={() => setMainImageClick(true)}
                    alt="shape"
                    src={toAbsoluteUrl(
                        `/media/shape/${props.data.C_Shape.toLowerCase()}-no.png`
                    )}
                />
            </div>}
        </>
    );
};
