import React,{useState} from "react";
import { Field,useFormikContext } from "formik";
import {toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = ({language}) => {
    const ShapeData = ([
        {
            id: 1,
            shape:"round",
            value:"ROUND"
        },
        {
            id: 2,
            shape:"princess",
            value:"PRINCESS"
        },
        {
            id: 3,
            shape:"oval",
            value:"OVAL"
        },
        {
            id: 4,
            shape:"heart",
            value:"HEART"
        },
        {
            id: 5,
            shape:"cushion",
            value:"CUSHION"
        },
        {
            id: 6,
            shape:"emerald",
            value:"EMERALD"
        },
        {
            id: 7,
            shape:"marquise",
            value:"MARQUISE"
        },
        {
            id: 8,
            shape:"cushion modified",
            value:"CUSHION MODIFIED"
        },
        {
            id: 9,
            shape:"pear",
            value:"PEAR"
        },
        {
            id: 10,
            shape:"asscher",
            value:"ASSCHER"
        },
        {
            id: 11,
            shape:"radiant",
            value:"RADIANT"
        },
        {
            id: 12,
            shape:"square radiant",
            value:"SQUARE RADIANT"
        },
        {
            id: 13,
            shape:"triangle",
            value:"TRIANGLE"
        },
        {
            id: 14,
            shape:"trilliant",
            value:"TRILLIANT"
        },
        {
            id: 15,
            shape:"other",
            value:"OTHER"
        },
    ]);

    const [selectedAll, setSelectedAll] = useState(false);
    const { setFieldValue } = useFormikContext();

    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        ShapeData.forEach(item => setFieldValue("Shape", newSelectedAll ? ShapeData.map(item => item.value) : []));
    };
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.SHAPE_SHAPE}</p>
                </div>
                <div className="col-lg-10 flex-wrap d-flex">
                    <label className="search_btn_check">
                        <input
                            type="checkbox"
                            name="Shape_all"
                            id="shape_all"
                            onChange={handleSelectAll}
                            checked={selectedAll}
                        />
                        <div className="shapeBox selectBox d-flex align-items-center justify-content-center">
                            <span className="text-uppercase font-size-h1">ALL</span>
                        </div>
                    </label>
                    {ShapeData.map((item) => (
                        <label key={`${item.id}_shape`}  className="search_btn_check">
                            <Field type="checkbox" name="Shape" id={`${item.shape}_shape`} value={item.value}/>
                            {/* <span></span> */}
                            <div className="shapeBox selectBox">
                                <SVG className={item.shape} src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape}.svg`)} />
                                <br/>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip" >{item.shape}</Tooltip>} >
                                    <span className="text-uppercase text-truncate">{item.shape}</span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};
