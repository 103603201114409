import React, { useState,useRef,useEffect } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle,FaLongArrowAltUp,FaLongArrowAltDown} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import {Modal,Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import VideoIframe from "../../../detailPage/VideoIframe";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MainImagePopup } from '../../../widgets/tiles/MainImagePopUp';
import { IVPCURRENCY } from '../../../../../env_config';
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from "../../../Popup";

export default function ExpandedDetais({row,open,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [showVideo, setShowVideo] = React.useState(false);
    const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
    const [holdPopupShow, setHoldPopupShow] = React.useState(false);
    const [semiAprroveModal, setSemiAprroveModal] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast,SetToast] = useState(false)
    const [wishlistmessage,setWishListMessage] = useState(null)
    const [currentCurrRate,setCurrentCurrRate] = useState("1")
    const history = useHistory()
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        return title
    }
    // const RouteToDetail = (data) => {
    //     history.push({
    //         pathname: `/detail/${row.WLDiamondType}-${row.ProductId}`
    //         });
    // }
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
        setSemiAprroveModal(false);
    }

    useEffect(() => {
        let curr = "cur_inr"
        if(row.Currency === "INR"){
          curr = "cur_inr"
        }
        if(row.Currency === "USD"){
          curr = "cur_usd"
        }
        if(row.Currency === "AUD"){
          curr = "cur_aud"
        }
        if(row.Currency === "CAD"){
          curr = "cur_cad"
        }
        if(row.Currency === "HKD"){
          curr = "cur_hkd"
        }
        if(row.Currency === "CNY"){
          curr = "cur_cny"
        }
        if(row.Currency === "EUR"){
          curr = "cur_eur"
        }
        if(row.Currency === "GBP"){
          curr = "cur_gbp"
        }
        if(row.Currency === "NZD"){
          curr = "cur_nzd"
        }
        if(row.Currency === "JPY"){
          curr = "cur_jpy" 
        }
        if(row.Currency === "CHF"){
          curr = "cur_chf"
        }
        if(row.Currency === "BND"){
            curr = "cur_bnd"
        }
        if(row.Currency === "SGD"){
            curr = "cur_sgd"
        }
        if(row.Currency === "THB"){
            curr = "cur_thb"
        }
        if(row.Currency === "MYR"){
            curr = "cur_myr"
        }
        if(row.Currency === "DKK"){
            curr = "cur_dkk"
        }
        if(row.Currency === "SEK"){
            curr = "cur_sek"
        }
        axios({
            method: "POST",
            url: IVPCURRENCY,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if(response && response.data){
                // console.log(response.data.data[0])
                response.data.data[0].cur_usd = 1
                const currentCurrency = response.data.data[0][curr];
                setCurrentCurrRate(Math.round(response.data.data[0][curr]*100)/100)
                // console.log(curr,currentCurrency)
            }
        })
    },[row.Currency])
// console.log("currentCurrRate",currentCurrRate)
function openVideoModal(event) {
    if (event.ctrlKey || event.metaKey) {
        window.open(row.diamond_type === "N" || row.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}`,"_blank")
      } else {
        setShowVideo(true)
      }
}

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }

    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }
    const handleHoldPopupClose = () => setHoldPopupShow(false);
    // const handleConfirmPopupShow = () => setConfirmPopupShow(true);
    const [mainimageclick,setMainImageClick] = useState(false)

    const handleConfirmPopupClose = () => setConfirmPopupShow(false);

    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape != 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else if (row.C_Length < row.C_Width) {
                $ratioval = (row.C_Width / row.C_Length).toFixed(2);
            } else if (row.C_Shape == 'HEART') {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else {
                $ratioval = '-';
            }
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    const getshortlistedsymbol = (responsecurrency) => {
        let symbol = "$"
        if(responsecurrency === "INR"){
          symbol = "₹"
        }
        if(responsecurrency === "USD"){
          symbol = "$"
        }
        if(responsecurrency === "AUD"){
          symbol = "$"
        }
        if(responsecurrency === "CAD"){
          symbol = "$"
        }
        if(responsecurrency === "HKD"){
          symbol = "$"
        }
        if(responsecurrency === "CNY"){
          symbol = "¥"
        }
        if(responsecurrency === "EUR"){
          symbol = "€"
        }
        if(responsecurrency === "GBP"){
          symbol = "£"
        }
        if(responsecurrency === "NZD"){
          symbol = "$"
        }
        if(responsecurrency === "JPY"){
          symbol = "¥" 
        }
        if(responsecurrency === "CHF"){
          symbol = "₣"
        }
        if(responsecurrency === "SGD"){
            symbol = "$"
            
        } if(responsecurrency === "THB"){
            symbol = "฿"
            
        } if(responsecurrency === "MYR"){
            symbol = "RM"
            
        } if(responsecurrency === "BND"){
            symbol = "$"
            
        } if(responsecurrency === "DKK"){
            symbol = "kr."
            
        } if(responsecurrency === "SEK"){
            symbol = "kr"
            
        }
        return symbol
      }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    return(
       <>
        <div className={`expandedDetails natTab ${row.C_Color ==="fancy" ? "fancyExpand" : "" }`} >
            <div className="row pt-2">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        <li className="img_thumb">
                            {row.aws_image ? (
                                <div>
                                <MainImagePopup Image={row.aws_image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                <img
                                    className="img thumb"
                                    alt={row.C_Shape}
                                    src={row.aws_image}
                                    onClick={() => setMainImageClick(true)}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/${row.C_Shape ? row.C_Shape.toLowerCase() : ""}-no.png`
                                            )
                                        }}
                                />
                                </div>
                            ) : (
                                <div>
                                    <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                                <img
                                    className="img thumb"
                                    alt="shape"
                                    src={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )}
                                />
                                </div>
                            )}
                        </li>
                        {row.video  ? (
                            <li className="img_thumb cursor-pointer" onClick={(e) => openVideoModal(e)}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                            </li>
                        ):('')}
                        {row.aws_heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row.aws_arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row.aws_asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4">
                        {row.aws_image ? (
                            <div>
                                <MainImagePopup Image={row.aws_image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                            <img
                                className="img-fluid cursor-pointer"
                                alt={row.C_Shape}
                                src={row.aws_image}
                                onClick={() => setMainImageClick(true)}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${row.C_Shape ? row.C_Shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}

                            />
                            </div>
                        ) : (
                            <div>
                                <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                            <img
                                className="img-fluid"
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${row.C_Shape.toLowerCase()}-no.png`
                                )}
                            />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2 border-right text-primary">
                                {row.Lab && user.CustomerType !=="7" && user.CustomerType !=="11" ? <>{row.Lab}:</> : ""}{row.Certi_NO &&  user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 text-left"
                                        href={
                                            row.Lab === "IGI"
                                                ? `https://www.igi.org/verify-your-report/?r=LG${row.Certi_NO}`
                                                : row.Lab === "GIA"
                                                ? `https://www.gia.edu/report-check?reportno=${row.Certi_NO}`
                                                : row.Lab === "HRD"
                                                ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.Certi_NO}`
                                                : row.Lab === "GCAL"
                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.Certi_NO}`
                                                : row.Certi_link
                                        }
                                    >
                                        {row.Certi_NO} <FaExternalLinkAlt />
                                    </a>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div >{language.MUI_STOCKNO}: <div className="text-primary d-inline-block mr-2 cursor-pointer" onClick={() => {
                                history.push({
                                    pathname: `/detail/${row.WLDiamondType}-${row.StockId}`
                                    });
                            }}>{row.WLDiamondType + "-" + row.StockId}</div>
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h3 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className='col-12'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
                         
                            { row.Milky && row.Milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                               {/* eyeclean */}
                               { row.EyeC && row.EyeC.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.EyeC && row.EyeC.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamond_type === "L" && (row.brown && row.brown === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamond_type === "L" && (row.brown && row.brown === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamond_type === "L" ) && (row.lab_treat && row.lab_treat.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamond_type === "N" || row.diamond_type === "W") && (row.green && row.green.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${row.raprate?row.raprate:"0000"}</span>
                            <span className="font-size-lg text-dark-50 ml-8"><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.CustomerShippingDays}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-6 mt-5">
                            <div className="shortlist-price-box">
                                <h3 className="text-primary">
                                   Shortlisted Price
                                </h3>
                                <hr className="w-25 ml-0 my-2"/>
                                <div className='d-flex'>
                                    <div className="text-dark font-size-lg">({ row.Currency})</div>
                                    {row.stone_status !== 1 ? <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Shortlisted Cost {getshortlistedsymbol(row.Currency)}/CT <b>{getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurRate * row.CurrencyConversionRate)} </b>
                                                <br/>Shortlisted Cost price<b> {getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurPrice * row.CurrencyConversionRate)}</b> 
                                            </Tooltip>
                                        }
                                    >
                                        <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                    </OverlayTrigger> : null }
                                </div>
                                <span className="h1">
                                    <><span className="small">{getshortlistedsymbol(row.Currency)}</span >{numberWithCommas(row.CCMarkUpPrice)}</>
                                </span>
                                <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCMarkUpRate)}</span></span>
                                {row.Tax ? <span className="d-flex small text-success font-weight-bold mt-2 align-items-center"><em>* Inclusive {row.Tax}% tax</em></span> :<span className="d-flex small text-warning mt-2  font-weight-bold align-items-center"><em>* Exclusive tax</em></span>}
                            </div>
                        </div>
                        <div className="col-6 mt-12 d-flex">
                            {row.stone_status === 1 ?
                                <div className="p-5">
                                    <h5 className="position-relative text-primary">
                                        Current Cost Price
                                    </h5>
                                    <hr className="w-25 ml-0 my-2"/>
                                    {row.CalculatedPrice.discount_main?row.CalculatedPrice.discount_main.includes('-')?
                                    <div className="d-flex small text-danger align-items-center">{row.CalculatedPrice.discount_main}% Off<span className="ml-8 text-dark font-size-lg">({ row.Currency})</span></div>:
                                    <div className="d-flex small text-success align-items-center">{row.CalculatedPrice.discount_main}% PR<span className="ml-8 text-dark font-size-lg">({ row.Currency})</span></div>
                                    :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark">({ row.Currency})</span></div>}
                                    
                                    <div className='d-flex align-items-end'>
                                        <div>
                                            <span className="h4">
                                                <><span className="small">{getshortlistedsymbol(row.Currency)}</span >{numberWithCommas(row.CalculatedPrice.total_our_price * currentCurrRate)}</>
                                            </span>
                                            <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CalculatedPrice.T_C_Rate * currentCurrRate)}</span></span>
                                        </div>
                                        <div>
                                            { 
                                                row.CCOurPrice * row.CurrencyConversionRate === row.CalculatedPrice.total_our_price * currentCurrRate
                                                ? ""
                                                : row.CCOurPrice * row.CurrencyConversionRate < row.CalculatedPrice.total_our_price * currentCurrRate
                                                ?
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Shortlisted Cost {getshortlistedsymbol(row.Currency)}/CT <b>{getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurRate * row.CurrencyConversionRate)} </b>
                                                                <br/>Shortlisted Cost price<b> {getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurPrice * row.CurrencyConversionRate)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltUp className='text-danger cursor-pointer font-size-h3' /></span>
                                                    </OverlayTrigger>
                                                :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Shortlisted Cost {getshortlistedsymbol(row.Currency)}/CT <b>{getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurRate * row.CurrencyConversionRate)} </b>
                                                                <br/>Shortlisted Cost price<b> {getshortlistedsymbol(row.Currency)}{numberWithCommas(row.CCOurPrice * row.CurrencyConversionRate)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltDown className='text-success cursor-pointer  font-size-h3' /></span>
                                                    </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null }  
                        </div>
                        <div className="col-12 mt-5 d-flex align-items-end">
                            <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond([{original:row}]) :  DetailSemiAprrove() }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light-primary px-6 mr-3 font-rubik font-weight-bold btn-pill" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? HoldDiamond([{original:row}]) :  DetailSemiAprrove() }> 
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.51 319.47" height="20px">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                    <path className="cls-1" d="M54.24.26a16.67,16.67,0,0,0-9.87,6.83C42.63,9.83,1.08,89,.54,90.58a15.49,15.49,0,0,0,.2,8.77c.49,1.27,22.45,32.16,74.43,104.7,68,94.94,73.84,103,75.12,103.62,2.49,1.26,5,.76,7.28-1.45S306,104.93,307.46,102.09a12,12,0,0,0,1.06-8.74c-.34-1.36-7.15-14.69-22.46-44C274,26.23,263.7,6.74,263.21,6.05a14.45,14.45,0,0,0-6.85-5.3l-1.83-.7L155.18,0C93.07,0,55.23.07,54.24.26Zm79.2,21.32L113.27,50.76c-10.15,14.7-18.53,26.62-18.61,26.48S76.71,23,75.72,19.83l-.22-.69h59.65l-1.71,2.44Zm101.71-.77c-4.1,11.73-18.67,53.05-18.72,53.09S174.11,19.83,174,19.45c-.07-.21,10.47-.31,30.82-.31h30.93l-.59,1.67Zm-168,35.1c5.49,16.69,10,30.41,10,30.52s-11.86.21-26.34.21-26.34-.07-26.34-.15,7.26-14,16.14-30.92c9.78-18.68,16.22-30.63,16.35-30.38s4.71,14,10.21,30.72ZM178,56.36l23.26,30.12-22.32.08c-12.27,0-32.41,0-44.74,0l-22.43-.08L133,56.28c11.68-16.63,21.34-30.17,21.48-30.13S165.18,39.8,178,56.36Zm90.71,1c8.26,15.9,15,29,15,29.1s-11.57.18-25.74.18c-24.35,0-25.73,0-25.59-.57.21-.79,20.47-57.61,20.65-57.91.09-.15.24-.15.38,0s7,13.3,15.27,29.19ZM104.37,168.08c26.84,81.54,27,82.1,26.56,81.6-.29-.32-84.52-117.81-102-142.32l-1.06-1.48H83.89l20.48,62.2Zm100.49-62c0,.66-51.61,147.65-51.77,147.46s-48.55-147-48.55-147.46,100.32-.29,100.32,0Zm76.27.34c-4.43,6.19-105.72,143.29-105.8,143.21s11.2-32.46,25-72l25.16-71.82h28c27.06,0,28,0,27.6.56Z" fill="#0037bf"/>
                                    <path className="cls-1" d="M325.92,202.66a99.35,99.35,0,0,0-194.9-3.1c-1.67,8-2,11.73-2,21.11s.53,14.27,2.19,21.46a99.93,99.93,0,0,0,82.34,76.57c6.36,1,22.87,1,29.22,0A99,99,0,0,0,299.94,289a93.36,93.36,0,0,0,17.5-25.16A98.5,98.5,0,0,0,325.92,202.66Z" fill="#0037bf"/>
                                    <path className="cls-2" d="M272.8,287.88a14.71,14.71,0,0,1-6.12,5.58c-2,.93-2.22,1-5.71,1-6.33,0-4.21,1.68-29.79-23.9-20.27-20.27-22-22-22.82-23.88a17.82,17.82,0,0,1-1.22-4.09c-.42-2.91-.44-82.44,0-85.11a13.85,13.85,0,0,1,10.65-11.21,16.28,16.28,0,0,1,7.37.61,14.45,14.45,0,0,1,6.51,5.14c2.5,3.73,2.31.21,2.41,44l.09,39,18.9,19c11.56,11.59,19.21,19.49,19.68,20.32A14.46,14.46,0,0,1,272.8,287.88Z" fill="#efefef"/>
                                </g>
                            </svg>
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                        </div>
                    {/*   
                        {row.show_supplier === 0 ? (
                            ""
                        ):(
                            <div className="col-12 mt-3">
                                { user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                    <span className="font-size-lg"><span className="text-dark font-size-sm font-weight-bold text-capitalize">{language.MUI_SUPPLIER} :</span> ***************</span>
                                ) :(
                                    <span className="font-size-lg"><span className="text-dark font-size-sm font-weight-bold text-capitalize">{language.MUI_SUPPLIER} :</span> {row.C_Name}</span>
                                )}
                            </div>
                        )}
                        
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${row.raprate?row.raprate:"0000"}</span>
                            <span className="font-size-lg text-dark-50 ml-8"><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.CustomerShippingDays}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row"> 
                         
                                <div className="col-6">
                                    <div className="price_border_right"></div>
                                    <div className="px-0">
                                        <h5>
                                            Mark Up Price
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.SupplierDiscount?row.SupplierDiscount.includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.SupplierDiscount}% Off<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.SupplierDiscount}% PR<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>} 
                                        {
                                             <span className="h1">
                                                <><span className="small">{GetCurrencyAndSymbol(row.Currency)}</span >{numberWithCommas(row.MarkUpPrice)}</>
                                            </span>
                                        }
                                        <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{GetCurrencyAndSymbol(row.Currency)}{numberWithCommas(row.MarkUpRate)}</span></span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="px-0">
                                        <h5 className="position-relative">
                                            {language.MUI_OURPRICE}
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.discount_main?row.discount_main.includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.discount_main}% Off<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.discount_main}% PR<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({row.Currency})</span></div>}
                                        
                                        <span className="h1">
                                            <><span className="small">{GetCurrencyAndSymbol(row.Currency)}</span >{numberWithCommas(row.OurPrice * conversionrate)}</>
                                        </span>
                                        <span className="d-flex small text-dark-50 align-items-center">For Per Ct. <span className="font-size-sm text-dark-50 ml-2">{GetCurrencyAndSymbol(row.Currency)}{numberWithCommas(row.OurRate * conversionrate)}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-end">
                            <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond([{original:row}]) :  DetailSemiAprrove() }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light-primary px-6 mr-3 font-rubik font-weight-bold btn-pill" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? HoldDiamond([{original:row}]) :  DetailSemiAprrove() }> 
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.51 319.47" height="20px">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                    <path className="cls-1" d="M54.24.26a16.67,16.67,0,0,0-9.87,6.83C42.63,9.83,1.08,89,.54,90.58a15.49,15.49,0,0,0,.2,8.77c.49,1.27,22.45,32.16,74.43,104.7,68,94.94,73.84,103,75.12,103.62,2.49,1.26,5,.76,7.28-1.45S306,104.93,307.46,102.09a12,12,0,0,0,1.06-8.74c-.34-1.36-7.15-14.69-22.46-44C274,26.23,263.7,6.74,263.21,6.05a14.45,14.45,0,0,0-6.85-5.3l-1.83-.7L155.18,0C93.07,0,55.23.07,54.24.26Zm79.2,21.32L113.27,50.76c-10.15,14.7-18.53,26.62-18.61,26.48S76.71,23,75.72,19.83l-.22-.69h59.65l-1.71,2.44Zm101.71-.77c-4.1,11.73-18.67,53.05-18.72,53.09S174.11,19.83,174,19.45c-.07-.21,10.47-.31,30.82-.31h30.93l-.59,1.67Zm-168,35.1c5.49,16.69,10,30.41,10,30.52s-11.86.21-26.34.21-26.34-.07-26.34-.15,7.26-14,16.14-30.92c9.78-18.68,16.22-30.63,16.35-30.38s4.71,14,10.21,30.72ZM178,56.36l23.26,30.12-22.32.08c-12.27,0-32.41,0-44.74,0l-22.43-.08L133,56.28c11.68-16.63,21.34-30.17,21.48-30.13S165.18,39.8,178,56.36Zm90.71,1c8.26,15.9,15,29,15,29.1s-11.57.18-25.74.18c-24.35,0-25.73,0-25.59-.57.21-.79,20.47-57.61,20.65-57.91.09-.15.24-.15.38,0s7,13.3,15.27,29.19ZM104.37,168.08c26.84,81.54,27,82.1,26.56,81.6-.29-.32-84.52-117.81-102-142.32l-1.06-1.48H83.89l20.48,62.2Zm100.49-62c0,.66-51.61,147.65-51.77,147.46s-48.55-147-48.55-147.46,100.32-.29,100.32,0Zm76.27.34c-4.43,6.19-105.72,143.29-105.8,143.21s11.2-32.46,25-72l25.16-71.82h28c27.06,0,28,0,27.6.56Z" fill="#0037bf"/>
                                    <path className="cls-1" d="M325.92,202.66a99.35,99.35,0,0,0-194.9-3.1c-1.67,8-2,11.73-2,21.11s.53,14.27,2.19,21.46a99.93,99.93,0,0,0,82.34,76.57c6.36,1,22.87,1,29.22,0A99,99,0,0,0,299.94,289a93.36,93.36,0,0,0,17.5-25.16A98.5,98.5,0,0,0,325.92,202.66Z" fill="#0037bf"/>
                                    <path className="cls-2" d="M272.8,287.88a14.71,14.71,0,0,1-6.12,5.58c-2,.93-2.22,1-5.71,1-6.33,0-4.21,1.68-29.79-23.9-20.27-20.27-22-22-22.82-23.88a17.82,17.82,0,0,1-1.22-4.09c-.42-2.91-.44-82.44,0-85.11a13.85,13.85,0,0,1,10.65-11.21,16.28,16.28,0,0,1,7.37.61,14.45,14.45,0,0,1,6.51,5.14c2.5,3.73,2.31.21,2.41,44l.09,39,18.9,19c11.56,11.59,19.21,19.49,19.68,20.32A14.46,14.46,0,0,1,272.8,287.88Z" fill="#efefef"/>
                                </g>
                            </svg>
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                            <button type="button" className="btn btn-light-primary px-6 font-rubik font-weight-bold btn-pill" onClick={(e) => (user.CustomerType !=="7" && user.CustomerType !=="11") ? RouteToDetail(row) :  DetailSemiAprrove() }> 
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="22.384" height="20.075" viewBox="0 0 22.384 20.075">
                                    <g id="ic_view_details" transform="translate(-794 -623.819)">
                                        <path className="cls-1" id="Path_28337" data-name="Path 28337" d="M3.485.017a1.07,1.07,0,0,0-.634.436C2.739.627.071,5.681.037,5.781a.983.983,0,0,0,.013.56c.031.081,1.441,2.053,4.779,6.683,4.366,6.06,4.741,6.574,4.823,6.614a.385.385,0,0,0,.467-.093c.146-.141,9.53-12.848,9.624-13.029a.762.762,0,0,0,.068-.558c-.022-.087-.459-.938-1.442-2.808C17.595,1.674,16.933.43,16.9.386a.927.927,0,0,0-.44-.338L16.345,0,9.966,0C5.978,0,3.548,0,3.485.017ZM8.57,1.377,7.275,3.24c-.652.938-1.19,1.7-1.195,1.69S4.927,1.468,4.864,1.266L4.85,1.222H8.68Zm6.53-.049c-.263.749-1.2,3.386-1.2,3.389s-2.717-3.451-2.724-3.475.672-.02,1.979-.02h1.986l-.038.107ZM4.314,3.569c.352,1.065.642,1.941.642,1.948s-.761.013-1.691.013-1.691,0-1.691-.01.466-.894,1.036-1.974c.628-1.192,1.041-1.955,1.05-1.939s.3.894.656,1.961Zm7.117.029L12.924,5.52l-1.433.005H8.619L7.178,5.52,8.542,3.592c.75-1.061,1.37-1.926,1.379-1.923S10.608,2.54,11.431,3.6Zm5.824.064c.53,1.015.963,1.851.963,1.857s-.743.011-1.653.011c-1.563,0-1.652,0-1.643-.036s1.314-3.677,1.326-3.7.015-.01.024,0,.449.849.98,1.863ZM6.7,10.728c1.723,5.2,1.734,5.24,1.705,5.208S2.982,8.417,1.86,6.852l-.068-.094h3.6L6.7,10.728Zm6.452-3.957c0,.042-3.314,9.424-3.324,9.412S6.714,6.8,6.714,6.771,13.155,6.752,13.155,6.771Zm4.9.022c-.284.4-6.788,9.146-6.793,9.141s.719-2.072,1.605-4.6L14.48,6.754h1.8c1.737,0,1.8,0,1.772.036Z" transform="translate(793.998 623.819)" fill="#0037bf"/>
                                        <path className="cls-1" id="eye" d="M11.958,8.205A6.7,6.7,0,0,0,6.015,4.5,6.7,6.7,0,0,0,.073,8.206a.676.676,0,0,0,0,.61,6.7,6.7,0,0,0,5.943,3.705,6.7,6.7,0,0,0,5.943-3.706A.676.676,0,0,0,11.958,8.205ZM6.015,11.518A3.008,3.008,0,1,1,9.023,8.51,3.008,3.008,0,0,1,6.015,11.518Zm0-5.013a1.991,1.991,0,0,0-.529.079,1,1,0,0,1-1.4,1.4A2,2,0,1,0,6.015,6.505Z" transform="translate(804.354 631.374)" fill="#0037bf"/>
                                    </g>
                                </svg>
                                {language.MUI_DETAILS}
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.C_Color && row.C_Color.toLowerCase() ==="fancy") ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row.f_intensity ? row.f_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row.f_overtone ? row.f_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row.f_color ? row.f_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 font-weight-bold"> {row.C_Length} * {row.C_Width} * {row.C_Depth} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 font-weight-bold"> {row.C_TableP ? row.C_TableP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 font-weight-bold"> {row.C_DefthP ? row.C_DefthP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Crn_Ht ? row.Crn_Ht : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Crn_Ag ? row.Crn_Ag : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Pav_Dp ? row.Pav_Dp : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Pav_Ag ? row.Pav_Ag : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.Ratio?row.Ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.gridle_per ? row.gridle_per : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.gridle  ? row.gridle : "-"}
                                </div>
                            </div>
                            
                        </div>
                        {(user.CustomerType !=="7" && user.CustomerType !=="11") ? (
                            <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bolder mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.canada_mark ? row.canada_mark : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6">
                                        Currency
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.Currency ? row.Currency : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        Tax Name
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        -
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        Tax Value
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        -
                                    </div>
                                </div> */}
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.Milky  ? row.Milky : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.EyeC ? row.EyeC : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 font-weight-bold"> {row.cutlet  ? row.cutlet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 font-weight-bold">{row.Key_Symbols ? row.Key_Symbols : "-" }</div>
                                </div>
                                    {row.WLDiamondType === "L" ? 
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.lab_treat) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.lab_treat === "Treated"?"Post Growth Treatment":row.lab_treat} </div>
                                                </div>
                                            ):(row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.canada_mark) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 font-weight-bold">  {row.canada_mark ? row.canada_mark : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                            ) : ("")}
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_heart} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_arrow} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_asset} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}