import React, { useState } from 'react';
import {
  Backdrop,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import PersonalInfoForm from '../KycUpdate/FormsEdit/PersonalInfoForm';
import CompanyInfoForm from '../KycUpdate/FormsEdit/CompanyInfoForm';
import ShippingInfoForm from '../KycUpdate/FormsEdit/ShippingInfoForm';
// import ReviewOrder from './ReviewOrder';
import ProfileSuccess from '../KycUpdate/ProfileSuccess/ProfileSuccess';
import { NODE_API_URL } from '../../../../env_config';
import validationSchemaEdit from '../KycUpdate/FormModel/validationSchemaEdit';
import kycFormModel from '../KycUpdate/FormModel/kycFormModel';
import formInitialValues from '../KycUpdate/FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';
import { toAbsoluteUrl } from "../../../_helpers";
import { FaMapMarkerAlt } from "react-icons/fa";
import useStyles from '../KycUpdate/styles';
import Scrollspy from 'react-scrollspy'
import { useHistory } from "react-router-dom";
import { SwalWarn,SwalSuccess} from "../../Popup";
const steps = ['User Information', 'Company Profile', 'Shipping Information'];
const { formId, formField } = kycFormModel;

// function _renderStepContent(step,setFirstFile) {
//   switch (step) {
//     case 0:
//       return <PersonalInfoForm formField={formField} setFirstFile={setFirstFile}/>;
//     case 1:
//       return <CompanyInfoForm formField={formField} />;
//     case 2:
//       return <ShippingInfoForm formField={formField} />;

//       // return <ReviewOrder />;
//     default:
//       return <div>Not Found</div>;
//   }
// }

export default function ProfileEdit(props) {
  const classes = useStyles();
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEdit[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [FirstFile,setFirstFile] = useState()
  const [SecondFile,setSecondFile] = useState()
  const [ThirdFile,setThirdFile] = useState()
  const [passportfilename,setPassportFileName] = useState("")
  const [companyregname,setCompanyRegName] = useState("")
  const [swalWarnOpen,setSwalWarnOpen] = useState(false)
  const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
  const [alertMessage,setAlertMessage] = useState()
  const [formaction,setFormAction] = useState()
  const [formvalue,setFormValue] = useState()
  const [loading, setLoading] = React.useState(false);
  const[isSubmitting ,SetIsSubmitting] = useState(false)
  const [openLoader, setOpenLoader] = React.useState(false);

  const enableLoading = () => {
      setLoading(true);
      setOpenLoader(true);
      SetIsSubmitting(true)
  };
  const disableLoading = () => {
      setLoading(false);
      setOpenLoader(false);
      SetIsSubmitting(false)
  };

  const cancelSwal = () => {
    // setSemiAprroveModal(true)
    setSwalWarnOpen(true)
    setAlertMessage("Are you sure want to Cancel !!")
}

const refreshoncacel = () => {
  window.location.reload(true)
}

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submitttttt")
    await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    // console.log("profile",JSON.stringify(values, null, 2))
    actions.setSubmitting(false);
    const userData = JSON.parse(localStorage.getItem("userData"))
    //   console.log(values,"values")
    const formData = new FormData();
    if(FirstFile){
      formData.append("user_file", FirstFile[0])

    }
    if(SecondFile){
    formData.append("user_file", SecondFile[0])
      
    }
    if(ThirdFile){
      formData.append("user_file", ThirdFile[0])

    }
    let shippingaddress = ""
    if(values.shipping_address1){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address1
      shippingaddress += "</p>"
    }
    if(values.shipping_address2){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address2
      shippingaddress += "</p>"
    }
    else{
      shippingaddress += "<p>"
      shippingaddress += '"'
      shippingaddress += "</p>"
    }
    if(values.shipping_city){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_city
      shippingaddress += "</p>"
    }
    if(values.shipping_state){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_state
      shippingaddress += "</p>"
    }
    if(values.shipping_zipcode){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_zipcode
      shippingaddress += "</p>"
    }
    if(values.shipping_country){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_country
      shippingaddress += "</p>"
    }
    // console.log(values.shipping_address1.split('\n'),"Splitting")
    let newshippingadress = "<p>"+values.shipping_address1.replaceAll("\n","<br>").replaceAll("\r","<br>")+"</p>"
    // console.log(shippingaddress,"shippingaddress")
    const DataObj = {
      // user_file:[FirstFile[0],SecondFile[0],ThirdFile[0]],
      "cname":values.company_name,
      "fname":values.firstName,
      "lname":values.lastName,
      "mail":values.email,
      invoiceemail:values.email,
      mobile:values.contanct_no,
      passport_id:values.passport_id,
      passport_file:values.passport_id_file,
      work_profile:values.work_profile,
      address:values.address1,
      secaddress:values.address2,
      city:values.city,
      state:values.state,
      country:values.country,
      com_zip:values.zipcode,
      com_reg_no:values.comp_reg_no,
      com_reg_doc:values.comp_reg_no_file,
      nature_buss:values.nature_of_business,
      buss_esta_date:values.business_est_date,
      director_name:values.name_of_partner,
      directory_contact:values.contact_of_partner,
      shipping_phone:values.shipping_telephone,
      shipping_email:values.shipping_email,
      id:userData.CustomerId,
      company_tax:values.tax_id,
      port_of_discharge:values.port_of_discharge,
      shipping_address:newshippingadress,
      website:values.website,
      invoiceemail:values.invoice_email,
      fax:values.fax,
      contact2:values.telephone,
      invoiceemail:values.invoice_email,
      secaddress:values.address2
    }
    for ( var key in DataObj ) {
      formData.append(key, DataObj[key]);
    }
    enableLoading();
    axios({
      method: "POST",
      url: `${NODE_API_URL}/API/KYC/insertandupdateData`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
    }).then(res => {
        disableLoading()
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
        window.location.reload(true)
        // console.log(res)
    }).catch(err => {
      disableLoading()
      console.log(err,"ERRROR")
      // actions.setSubmitting(false);

    })
    // console.log(DataObj,"DataObj")
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
    // if (isLastStep) {
    // } else {
    //   setActiveStep(activeStep + 1);
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    // }
  }
  function submitSwal(values, actions) {
    // setSemiAprroveModal(true)
    setFormValue(values)
    setFormAction(actions)
    setSwalSuccessOpen(true)
    setAlertMessage("Are you sure want to save !!")
    // }
  }

  // function _handleBack() {
  //   setActiveStep(activeStep - 1);
  // }

  return (
    <React.Fragment>
        <div className="row">
            
           
        </div>
      {/* {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )} */}
      <div  className="row">
        <div className="col-md-4 text-center">
           <div className='sticky-top bg-white row'style={{top:"132px"}}>
             <div className="col-12">
                {props.userinfo.company_logo?<img
                    alt="profile"
                    className="img-fluid profileLogo rounded-circle bg-light p-2"
                    width="125px"
                    src={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/documents/customer_logo/${props.userinfo.company_logo}`:`https://alpha.diamondsupload.com/assets/customer_logo/${props.userinfo.company_logo}`}

                />:<img
                alt="profile"
                className="img-fluid profileLogo rounded-circle bg-light p-2"
                width="125px"
                src={toAbsoluteUrl(`/media/General/usericon.png`)}
            />}
             </div >
              <div className="font-size-h4 mt-5 text-capitalize font-weight-bolder col-12">
                  {props.userinfo.cname}
              </div>
              <div className="font-size-h5 text-muted mt-1 text-capitalize font-weight-bold col-12">
                  <FaMapMarkerAlt className="mr-2" />
                  {props.userinfo.city}, {props.userinfo.country}
              </div>
              <div className="col-12">
                <Scrollspy className="profile-scrollspy mt-md-20 mt-5" items={ ['personal', 'company', 'shipping'] } currentClassName="is-current">
                  <li><a href="#personal">{props.language.PROFILEVIEW_USER_INFORMATION}</a></li>
                  <li><a href="#company">{props.language.PROFILEVIEW_COMPANY_INFORMATION}</a></li>
                  <li><a href="#shipping">{props.language.PROFILEVIEW_SHIPPING_INFORMATION}</a></li>
                </Scrollspy>
              </div >
           </div >
          
        </div>
        {/* {activeStep === steps.length ? (
          <ProfileSuccess />
        ) : ( */}
        <div className="col-md-8">
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={submitSwal}
          >
            {({ setFieldValue ,values}) => (
              <Form id={formId}>
                
                {/* {_renderStepContent(activeStep,setFirstFile)} */}
                    <section id="personal">
                      <PersonalInfoForm  setFieldValue={setFieldValue} formField={formField} setFirstFile={setFirstFile} values={values} userinfo={props.userinfo} language={props.language} setPassportFileName={setPassportFileName}/>
                    </section>
                    <section id="company">
                      <CompanyInfoForm formField={formField} setFirstFile={setFirstFile} setSecondFile={setSecondFile} setThirdFile={setThirdFile} values={values} setFieldValue={setFieldValue} setPassportFileName={setPassportFileName} setCompanyRegName={setCompanyRegName} FirstFile={FirstFile} SecondFile={SecondFile} ThirdFile={ThirdFile} language={props.language}/>
                    </section>
                    <section id="shipping">
                      <ShippingInfoForm formField={formField} setFirstFile={setFirstFile} values={values} setFieldValue={setFieldValue} language={props.language}/>
                    </section>
                    <Grid className="mt-10 sticky-top bg-white" container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                      {/* {activeStep !== 0 && (
                        <Button color="primary" onClick={_handleBack} className={classes.button}>
                          <FaChevronLeft className="mr-1" /> <span className="mt-1">Previous</span >
                        </Button>
                      )} */}
                      <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {props.language.PROFILE_EDIT_SAVE_CHANGES}
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => cancelSwal()}
                          className={classes.button}
                        >
                          {props.language.PROFILE_EDIT_CANCEL}
                        </Button>
                        {loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop
                            sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                            open={openLoader}
                            // onClick={handleCloseLoader}
                        >
                            <CircularProgress color="secondary" />
                        </Backdrop>
                      </div>
                    </Grid>
              </Form>
            )}
          </Formik>
          <SwalSuccess show={swalSuccessOpen} message={alertMessage} onHide={() => setSwalSuccessOpen(false)} onClose={() => setSwalSuccessOpen(false)} responseOnOk={() => _handleSubmit(formvalue,formaction)}/> 
          <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => refreshoncacel()}/> 
        </div>
        {/* )} */}
      </div>
    </React.Fragment>
  );
}
