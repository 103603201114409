/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    wl_naturaldiamond,
    wl_naturalfancydiamond,
    wl_lgwhitediam,
    wl_lgfancydiam,
    muti_currency,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    white_label_logo,
    wl_primarycolor,
    n_fontcolor,
    lab_primary,
    lab_fontcolor,
    password,
    cofirm_password,
    email,
  }
} = kycFormModel;


const numbersonly =/^[0-9]\d{0,9}(\.\d{1,2})?%?$/
const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/

export default [
  Yup.object().shape({
    // [wl_naturaldiamond.name]: Yup.string().required(`${wl_naturaldiamond.requiredErrorMsg}`),

    // ------------------------------------------------------------------------------------------

   [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
   [diamondmarkup.name]: Yup.string().required(`${diamondmarkup.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
   [diamondfancymarkup.name]: Yup.string().required(`${diamondfancymarkup.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
   [lab_diamondmarkup.name]: Yup.string().required(`${lab_diamondmarkup.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
   [lab_diamondfancymarkup.name]: Yup.string().required(`${lab_diamondfancymarkup.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),

  // ------------------------------------------------------------------------------------------

   [white_label_logo.name]: Yup.string().required(`${white_label_logo.requiredErrorMsg}`),
   [wl_primarycolor.name]: Yup.string().required(`${wl_primarycolor.requiredErrorMsg}`),
   [n_fontcolor.name]: Yup.string().required(`${n_fontcolor.requiredErrorMsg}`),
   [lab_primary.name]: Yup.string().required(`${lab_primary.requiredErrorMsg}`),
   [lab_fontcolor.name]: Yup.string().required(`${lab_fontcolor.requiredErrorMsg}`),

  // ------------------------------------------------------------------------------------------

   [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
  //  [password.name]: Yup.string().min(8, "Password should be of minimum 8 characters length") .required(`${password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
  //  [cofirm_password.name]: Yup.string() .min(8, "Password should be of minimum 8 characters length").oneOf([Yup.ref("password"), null], "Password must match") .required(`${cofirm_password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),

  }).test(
    'wl_naturaldiamond',
    null,
    (obj) => {
      // console.log(obj,"OBJJSJSJSSJ")
      if ( obj.wl_naturaldiamond || obj.wl_naturalfancydiamond || obj.wl_lgwhitediam || obj.wl_lgfancydiam ) {
        return true; // everything is fine
      }
      const keys = ['wl_naturaldiamond', 'wl_naturalfancydiamond','wl_lgwhitediam','wl_lgfancydiam']

      const errors = keys.map((key) => {
  
          return new Yup.ValidationError(
            `${key} is empty`,
            null,
            key
          )
      }).filter(Boolean)
      return new Yup.ValidationError(errors);
    }
  ),
];
