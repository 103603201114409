/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IFrameSetupFrom from "./IFrameSetupNew/IFrameSetupFrom"

export const IframeSetupNew = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">  
                    <IFrameSetupFrom userinfo={props.userinfo} language={props.language}/>
                </div>
            </div>
        </>
    );
};
