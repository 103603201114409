import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { KeytoSymbol } from "./AdditionalLab/KeytoSymbol";
import { GrowthType } from "./AdditionalLab/GrowthType";
import { Treatment } from "./AdditionalLab/Treatment";

export const AdditionalLab = ({language,formik}) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {language.MUI_ADDITIONALDETAILS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <GrowthType language={language}/>
                            <Treatment language={language} formik={formik}/>
                            <KeytoSymbol language={language}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
