import React from "react"
import hongkong_icon from "../../../../app/modules/Auth/pages/Images/hongkong_icon.svg"
import usa_icon from "../../../../app/modules/Auth/pages/Images/usa_icon.svg"
import australia_icon from "../../../../app/modules/Auth/pages/Images/australia_icon.svg"
import india_icon from "../../../../app/modules/Auth/pages/Images/india_icon.svg"
import uk_icon from "../../../../app/modules/Auth/pages/Images/big_ben.svg"
import singapore_icon from "../../../../app/modules/Auth/pages/Images/singapore_icon.svg"
import styles from "../../../../app/modules/Auth/pages/css/style.module.css"

const LocalNumbers = (props) =>{
    return(
        <>
            <div className="container-fluid bg-white">
                <div className="container mt-8 mb-12">
                    <div className="row pt-5 justify-content-center ">
                        <div className="col-lg-12 text-center">
                            <h2 className={styles.customH2Title}>{props.language.CONTACTUS_CALLSALES}</h2>
                        </div>
                    </div>
                    <div className={`row justify-content-center ${styles.ct_sales} mt-3 pt-6`}>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end" >
                            <img className="pb-8" src={hongkong_icon} alt="hongkong" title="Hong Kong" height="100" />
                            <h4>{props.language.CONTACTUS_HONGKONG}</h4>
                            <a href="tel:+85235655670">+852 3565 5670</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end">
                            <img className="pb-8" src={usa_icon} alt="usa" title="USA" height="100" />
                            <h4>{props.language.CONTACTUS_USAORCAN}</h4>
                            <a href="tel:+13322397300">+1 (332) 239-7300</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end">
                            <img className="pb-8" src={australia_icon} alt="australia" title="Australia" height="66.77" />
                            <h4>{props.language.CONTACTUS_AUSTRALIA}</h4>
                            <a href="tel:+611800841842">+61 1800 841 842</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end">
                            <img className="pb-8" src={india_icon} alt="india" title="India" height="100" />
                            <h4>{props.language.CONTACTUS_INDIA}</h4>
                            <a href="tel:+919106558044">+91 91065 58044</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end">
                            <img className="pb-8" src={uk_icon} alt="india" title="India" height="100" />
                            <h4>{props.language.CONTACTUS_UK}</h4>
                            <a href="tel:+441617795441">+44 161 779 5441</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end">
                            <img className="pb-8" src={singapore_icon} alt="india" title="India" height="100" />
                            <h4>{props.language.CONTACTUS_SG}</h4>
                            <a href="tel:+6560185200">+65 6018 5200</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocalNumbers;
