import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function DashboardSkeleton() {
    return (
        <>
             <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" width={400} height={20} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <Skeleton className="mr-3" variant="text" width={120} height={45} />
                        <Skeleton className="mr-3" variant="text" width={140} height={45} />
                        <Skeleton className="mr-3" variant="text" width={50} height={40} />
                    </div>
                </div>
                <div className="card-body pt-7 pb-0 mt-n3">
                    <div className="table-responsive w-100">
                        <table className="table table-hover table-striped">
                            <thead className="custom_datatable_head">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={120} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={140} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={120} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                </tr>
                            </thead>
                           <tbody >
                                {Array.from(Array(6), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Skeleton className="mx-auto" variant="text" width={20} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={20} height={20} /></td>
                                            <td className="text-center">
                                                <Skeleton className="mx-auto" variant="text" width={50} height={20} />
                                                <Skeleton className="mx-auto" variant="text" width={80} height={10} />
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-end">
                                                    <Skeleton className="mr-5" variant="rect" width={50} height={50} />
                                                    <div>
                                                        <div className="d-flex align-items-center">
                                                            <Skeleton variant="circle" width={15} height={15} />
                                                            <span className="mr-10 ml-2"><Skeleton variant="text" width={100} /></span>
                                                            <span><Skeleton variant="text" width={100} /></span>
                                                        </div>
                                                        <Skeleton variant="text" width={280} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <Skeleton className="mx-auto" variant="text" width={80} height={20} />
                                                <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                            </td>
                                            <td><Skeleton className="mx-auto" variant="text" width={80} height={30} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={50} height={20} /></td>
                                            <td>
                                                <Skeleton className="mx-auto" variant="text" width={100} height={20} />
                                                <Skeleton className="mx-auto" variant="text" width={100} height={15} />
                                            </td>
                                            <td><Skeleton className="mx-auto" variant="text" width={100} height={20} /></td>
                                        </tr>
                                    );
                                })}
                           </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer border-0 d-flex align-items-center justify-content-between">
                <Skeleton className="mr-3" variant="text" width={100} height={40} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
                <Skeleton className="mr-3" variant="text" width={200} height={50} />
                </div>
             </div>
        </>
    );
}
