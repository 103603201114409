import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import PersonalInfoForm from './Forms/PersonalInfoForm';
import CompanyInfoForm from './Forms/CompanyInfoForm';
import ShippingInfoForm from './Forms/ShippingInfoForm';
// import ReviewOrder from './ReviewOrder';
import ProfileSuccess from './ProfileSuccess/ProfileSuccess';
import { NODE_API_URL } from '../../../../env_config';
import validationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
import formInitialValues from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';

const steps = ['User Information', 'Company Profile', 'Shipping Information'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFirstFile,setFieldValue,setSecondFile,setThirdFile,prev,setPrev,values,FirstFile,setCompanyRegName,setPassportFileName,SecondFile,ThirdFile,language) {
  switch (step) {
    case 0:
      return <PersonalInfoForm formField={formField} setFirstFile={setFirstFile} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} FirstFile={FirstFile} language={language} setPassportFileName={setPassportFileName} values={values}/>;
    case 1:
      return <CompanyInfoForm setFieldValue={setFieldValue} formField={formField} setSecondFile={setSecondFile} setThirdFile={setThirdFile} values={values} setFirstFile={setFirstFile} setPassportFileName={setPassportFileName} setCompanyRegName={setCompanyRegName} FirstFile={FirstFile} SecondFile={SecondFile} ThirdFile={ThirdFile} language={language}/>;
    case 2:
      return <ShippingInfoForm formField={formField} values={values} setFieldValue={setFieldValue} language={language}/>;

      // return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function KycUpdate({userinfo,language}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [FirstFile,setFirstFile] = useState()
  const [SecondFile,setSecondFile] = useState()
  const [ThirdFile,setThirdFile] = useState()
  const [passportfilename,setPassportFileName] = useState("")
  const [companyregname,setCompanyRegName] = useState("")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    // console.log("profile",JSON.stringify(values, null, 2))

    const userData = JSON.parse(localStorage.getItem("userData"))
    // console.log(values,"values")
    // console.log(FirstFile,"FirstFile")
    // console.log(SecondFile,"SecondFile")
    // console.log(ThirdFile,"ThirdFile")
    const formData = new FormData();
    if(FirstFile){
    formData.append("user_file", FirstFile[0])
      
    }
    if(SecondFile){
    formData.append("user_file", SecondFile[0])

    }
    if(ThirdFile){
      formData.append("user_file", ThirdFile[0])

    }
    let shippingaddress = ""
    if(values.shipping_address1){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address1
      shippingaddress += "</p>"
    }
    if(values.shipping_address2){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address2
      shippingaddress += "</p>"
    }
    else{
      shippingaddress += "<p>"
      shippingaddress += '"'
      shippingaddress += "</p>"
    }
    if(values.shipping_city){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_city
      shippingaddress += "</p>"
    }
    if(values.shipping_state){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_state
      shippingaddress += "</p>"
    }
    if(values.shipping_zipcode){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_zipcode
      shippingaddress += "</p>"
    }
    if(values.shipping_country){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_country
      shippingaddress += "</p>"
    }
    let newshippingadress = "<p>"+values.shipping_address1.replaceAll("\n","<br>").replaceAll("\r","<br>")+"</p>"

    const DataObj = {
      // user_file:FirstFile[0],
      // user_file:SecondFile[0],
      // user_file:array,
      "cname":values.company_name,
      "fname":values.firstName,
      "lname":values.lastName,
      "mail":values.email,
      invoiceemail:values.email,
      mobile:values.contanct_no,
      passport_id:values.passport_id,
      passport_file:values.passport_id_file,
      work_profile:values.work_profile,
      address:values.address1,
      secaddress:values.address2,
      city:values.city,
      state:values.state,
      country:values.country,
      com_zip:values.zipcode,
      com_reg_no:values.comp_reg_no,
      com_reg_doc:values.comp_reg_no_file,
      nature_buss:values.nature_of_business,
      buss_esta_date:values.business_est_date,
      director_name:values.name_of_partner,
      directory_contact:values.contact_of_partner,
      shipping_phone:values.shipping_telephone,
      shipping_email:values.shipping_email,
      id:userData.CustomerId,
      company_tax:values.tax_id,
      port_of_discharge:values.port_of_discharge,
      shipping_address:newshippingadress,
      website:values.website,
      invoiceemail:values.invoice_email,
      fax:values.fax,
      contact2:values.telephone,
      invoiceemail:values.invoice_email
    }
    for ( var key in DataObj ) {
      formData.append(key, DataObj[key]);
  }
  
    // console.log(DataObj,"DataObj")

    axios({
      method: "POST",
      url: `${NODE_API_URL}/API/KYC/insertandupdateData`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
  }).then(res => {
    actions.setSubmitting(false);
    // setActiveStep(activeStep + 1);
    window.location.reload(true)

  }).catch(err => {
    actions.setSubmitting(false);
    alert(err)
  })
    // console.log(DataObj,"DataObj")
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }

  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <ProfileSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep,setFirstFile,setFieldValue,setSecondFile,setThirdFile,prev,setPrev,values,FirstFile,setCompanyRegName,setPassportFileName,SecondFile,ThirdFile,language)}
                <Grid className="mt-10" container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  >
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? language.PROFILE_COMPLETE_PROFILE : language.PROFILE_NEXT}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
