import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Media } from "./Preference/Media";
import { Price } from "./Preference/Price";
import { Showonly } from "./Preference/Showonly";


export const Preference = (props) => {
       return (
        <>
            <Accordion className="search_accordion" defaultActiveKey="">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {props.language.SEARCHDIAMOND_PREFERENCE}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Price language={props.language}/>
                           {/* <Showonly language={props.language}/> */}
                           <Media language={props.language}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
