import React from "react";
import { Field,useFormikContext  } from "formik";

export const Treatment = ({language}) => {
    const { setFieldValue } = useFormikContext();

    const TreatmentData = ([
        {
            id: 1,
            Treatment:"NONE",
            value:"none"
        },
        {
            id: 2,
            Treatment:"LASER DRILLED",
            value:"Laser Drilled"
        },
        {
            id: 3,
            Treatment:"CLARTY ENHANCED",
            value:"Clarity Enhanced"
        },
        {
            id: 4,
            Treatment:"COLOR ENHANCED",
            value:"Color Enhanced"
        },
        {
            id: 5,
            Treatment:"IRRADIATED",
            value:"Irradiated"
        },
        {
            id: 6,
            Treatment:"HPHT",
            value:"High Pressure High Temperature"
        },
        {
            id: 7,
            Treatment:"OTHER",
            value:"other"
        },
        

    ]);

    // Set initial value for Treatment field to "none"
    // React.useEffect(() => {
    //     setFieldValue('Treatment', ['none']);
    // }, [setFieldValue]);
    
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.MUI_TREATMENT}</p>
                </div>
                <div className="col-lg-10 d-flex flex-wrap">
                    {TreatmentData.map((item) => (
                        <label key={`${item.id}_Treatment`} className="search_btn_check">
                            <Field type="checkbox" name="Treatment" id={`${item.Treatment}_Treatment`} value={item.value}/>
                            <div className="treatBox selectBox">
                                <span>{item.Treatment}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};