import React, {  useState, useEffect, useRef } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import { BsFillCameraVideoFill, } from "react-icons/bs";
import { CircularProgress } from '@material-ui/core';
import { ImageSharePopup } from "./ImageSharePopup";
import axios from "axios";
export const VideoExpandPopupCommon = (props) => {
    const [show, setShow] = useState(false);
   
    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        console.log(props.data.diamond_type,"props.data.diamond_type")
        if (event.ctrlKey || event.metaKey) {
            window.open(props.data.diamond_type === "N" || props.data.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`, '_blank')
          } else {
            setShow(true)
          }
    };
    const [frameloaded, setFrameLoaded] = useState(false)
    const [showimage,setShowImage] = useState(false)
    const [shareurl,setShareURL] = useState("")
    const FrameFunction = () => {
      // console.log("DONE")
      setFrameLoaded(true)
      // var iframe = document.getElementById('iframedata');
      // console.log(iframe.contentWindow,"iframeDoc")
      // console.log($("#iframedata"),"FRAMEID")
    }
    const copyurl = (diamonddata) => {
      let video = ""
        if(diamonddata.ext_status === "Success"){
          axios({
            method: "POST",
            url: `https://api.dia360.cloud/api/admin/revert-public-url`,
            headers: { "Content-Type": "application/json","x-api-key":"26eca0a8-1981-11ee-be56-0242ac120002"},
            data: {
              "ext_video_id":diamonddata.ext_video_id.toString(),
              "client_id":props.userinfo.id.toString()
          },
        })
        .then(function(response) {
            if(response && response.data && response.data.url){
              setShareURL(response.data.url)
              navigator.clipboard.writeText(response.data.url)
              setShowImage(true)
            }
        })
        }
        else if(diamonddata.video){
            setShareURL(props.data.diamond_type === "N" || props.data.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`)
              navigator.clipboard.writeText(props.data.diamond_type === "N" || props.data.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`)
              setShowImage(true)
          }
  }
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && show) {
        handleClose();
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, handleClose]);
  const GetVideoUrl = (data) => {
    let video = ""
        if(data.ext_status === "Success"){
            video = data.m_video_path
        }
        else if(data.video){
            video = props.data.diamond_type === "N" || props.data.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`
        }
        return video
  }
    return (
        <>
        {GetVideoUrl(props.data)?<div>
            {/* <BsFillCameraVideoFill className="cursor-pointer text-primary mx-2 text-center"  onClick={(e) => handleShow(e)}/> */}
            <li className="img_thumb cursor-pointer" onClick={(e) => handleShow(e)}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                            </li>
      <ImageSharePopup show={showimage} onClose={() => setShowImage(false)} src={shareurl}/>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        
        <Modal.Body>
          {frameloaded ? "" : <div>
            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              marginTop:"50"
            }}>
              <CircularProgress
                      // style={{marginLeft: '40%'}}
              />
              <h6>Please Wait...</h6>
            </div>
          </div>}
          <iframe id="iframedata" title="Video" src={GetVideoUrl(props.data)} height='450px' width='100%' frameborder='0' onLoad={FrameFunction}></iframe>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="primary"
            onClick={() => copyurl(props.data)}
          >
            Share
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>:""}
        </>
    );
};
