/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router-dom";
import { FaUserCircle ,FaCalendarAlt,FaAward} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import { INSERT_WISHLIST } from "../../../../env_config";
import { TracklistStone } from "../../Popup/TracklistPopup";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// import { green } from '@mui/material/colors'
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import { BsCircle,BsCheckCircleFill } from 'react-icons/bs';
// import {IUD_SHORTLIST} from "./../../../../env_config"
// import {DETAILPAGE_LOG} from "./../../../../env_config"
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { StoreDiamondDB } from "../../../_helpers/IndexedDB";
// import { GetDiamondDB } from "../../../_helpers/IndexedDB";
import { Checkbox } from "@material-ui/core";
import { SwalWarn } from "../../Popup";
import { ImagePopup } from "../tiles/ImagePopUp";
import { VideoPopup } from "../tiles/VideoPopUp";
import { ImagePopupCommon } from "./ImagePopupCommon";
import { VideoPopupCommon } from "./VideoPopupCommon";
import { LazyImageComponent } from "../tiles/LazyImageComponent";
export const SimilarDiamonds = (props) => {
    
    const history = useHistory();

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)

    const [semiAprroveModal, setSemiAprroveModal] = React.useState(false);
    const [diamonddetails,setDiamondDetail] = useState([])
    // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [show,setShow] = useState(false)
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()

    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    function handleClose(event, reason) {
        setOpen(false);
        setSuccessOpen(false);
        setSemiAprroveModal(false)
    }
   
    function handelDetails(row){
        history.push({
            pathname: `/detail/${row.diamond_type}-${row.id}`
            });
    }
   
   
    if(user === undefined ){
        return
    }
    const AddToWishlist = (row) => {
        // console.log(row,"row")
        const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = {
            "user_name":userData.EMail,
            "user_id":userData.CustomerId,
            "diamond_type":row.diamond_type,
            "loat_no":row.Loat_NO,
            "Certi_No":row.Certi_NO,
            "curr":props.userinfo.websitecurrency
        }
        axios({
            method: "POST",
            url: INSERT_WISHLIST,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data){
                // alert(res.data.msg)
                setSuccessOpen(true)
                let NewStock = []
                for(let i = 0; i < props.StockAPI.length;i++){
                    if(row.Certi_NO.toString() === props.StockAPI[i].Certi_NO.toString()){
                        props.StockAPI[i].wishlisted = true
                    }
                    NewStock.push(props.StockAPI[i])
                }
                props.setStockAPI(NewStock)
                // if(props.page === 1 && props.location && props.location.state && props.location.state.oldvalues){
                //     console.log("Page 1")
                    history.push({
                        pathname: "/search-grid",
                      //   state: stableSort(StockAPI, getComparator(order, orderBy)),
                        state: {
                          searchresult:NewStock.slice(0,100),
                          oldvalues:props.location.state.oldvalues,
                          page:1
                      }
                            // state: decompress(res.data.ProductDetails),
                        });
                // }
            }
        }).catch(err => {
            // alert(err.response.data.msg)
            setOpen(true);

        })
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
               
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        // console.log(title.length)
        return title.length > 36 ? `${title.substring(0, 40)}...`: title
        // return title
    }
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            $ratioval = (row.C_Length / row.C_Width).toFixed(2);
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
    const AddToTracklist = (row) => {
        setShow(true)
        setDiamondDetail([{
            "Certi_NO":row.Certi_NO,
            "diamond_type":row.diamond_type,
            "tracklisted_price_usd":row.OurPrice,
            "tracklisted_price":Math.round(row.OurPrice * props.conversionrate * 100)/100
        }])
    }
    
    return (
        <>
            <div
                className={`mb-5 px-2 grid_box  ${
                    props.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                key={props.id}
                id={props.id}
            >
                <div className="row m-0 card rounded-0 gridData ribbon ribbon-top ribbon-left">
                    {props.diamond_type === "N" || props.diamond_type === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }

                   
                    { (props.diamond_type === "L" ) && (props.brown && props.brown === "HPHT")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                        HPHT
                    </div> : null }
                    { (props.diamond_type === "L" ) && (props.brown && props.brown === "CVD")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                        CVD
                    </div> : null }

                    {/* { (props.diamond_type === "N" || props.diamond_type === "W") && (props.green && props.green.toLowerCase() !== "none")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"150px",left:"-3px"}}>
                        Tx
                    </div> : null }

                    { (props.diamond_type === "L" ) && (props.lab_treat && props.lab_treat.toLowerCase() === "treated")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"150px",left:"-3px"}}>
                        Tx
                    </div> : null }
                    { (props.diamond_type === "L" ) && (props.lab_treat && props.lab_treat.toLowerCase() === "as grown")  ? 
                    <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"150px",left:"-3px"}}>
                        As-G
                    </div> : null } */}

                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                        <LazyImageComponent data={props}/>
                    </div>
                    <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                       <div>
                            { user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                <div className="text-primary d-inline-block cursor-pointer font-weight-bold" onClick={() => DetailSemiAprrove()}>{props.diamond_type + "-" + props.id}</div>
                            ):(
                                <div className="text-primary d-inline-block cursor-pointer font-weight-bold" onClick={() => handelDetails(props)}>{props.diamond_type + "-" + props.id}</div>
                            )}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `Business Process`
                                            : props.availability === "Guaranteed"
                                            ? `Guaranteed`
                                            : props.availability === "Not Available"
                                            ? `Not Available`
                                            : props.availability === "Subject to Prior sale"
                                            ? `Subject to Prior sale`
                                            : `Unknown`
                                        }
                                    </Tooltip>
                                }
                            >
                                <img
                                    className="availIco mx-2"
                                    alt={props.availability}
                                    src={toAbsoluteUrl(`/media/svg/${
                                        props.availability === 'Memo' || props.availability === 'Hold'
                                            ? `availability_primary.svg`
                                            : props.availability === "Guaranteed"
                                            ? `availability_success.svg`
                                            : props.availability === "Not Available"
                                            ? `availability_danger.svg`
                                            : props.availability === "Subject to Prior sale"
                                            ? `availability_warning.svg`
                                            : `availability_blank_white.svg`
                                    }`)}
                                />
                                
                            </OverlayTrigger>
                       </div>
                        <div className="font-weight-bold">
                            {props.Lab && user.CustomerType !=="7" && user.CustomerType !=="11" ? <>{props.Lab}:</> : ""}{props.Certi_NO &&  user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        props.Lab === "IGI"
                                            ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                            : props.Lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                            : props.Lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                            : props.Lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                            : props.Certi_link
                                    }
                                >
                                    {props.Certi_NO}
                                </a>
                            ) : (
                                ""
                            )}
                             {/* {props.country ? (
                                <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={<Tooltip id="tooltip">{props.country}</Tooltip>}
                                >
                                    <img
                                        className="flagIco mr-1"
                                        alt={props.country}
                                        src={toAbsoluteUrl(`/media/map/${props.country.toLowerCase()}.png`)}
                                    />
                                </OverlayTrigger>
                            ) : (
                                null
                            )} */}
                        </div>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-end align-items-center">
                            {/* shade */}
                            {renderShadeLabel(props.shade)}
                            
                            { props.Milky && props.Milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.Milky && props.Milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.Milky && props.Milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { props.Milky && props.Milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* eyeclean */}
                            { props.EyeC && props.EyeC.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { props.EyeC && props.EyeC.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { (props.diamond_type === "L" ) && (props.lab_treat && props.lab_treat.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (props.diamond_type === "N" || props.diamond_type === "W") && (props.green && props.green.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            
                            <ImagePopupCommon data={props}/>
                            <VideoPopupCommon data={props} userinfo={props.userinfo}/>
                            {props.Certi_NO  && (
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">Certificate</Tooltip>
                                        }
                                    >
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary mr-2 text-left"
                                            href={
                                                props.Lab === "IGI"
                                                    ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                                    : props.Lab === "GIA"
                                                    ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                                    : props.Lab === "HRD"
                                                    ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                                    : props.Lab === "GCAL"
                                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                                    : props.Certi_link
                                            }
                                        >
                                            <FaAward />
                                        </a>
                                    </OverlayTrigger>
                                </span>
                            )}
                        </div>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex  px-2 align-items-center">
                        <div className="text-center">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {props.C_Shape}
                                    </Tooltip>
                                }
                            >
                                <SVG width="40px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${props.C_Shape.toLowerCase()}.svg`)} />
                            </OverlayTrigger>
                            {/* <div className="text-uppercase small">{props.C_Shape}</div > */}
                        </div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.C_Weight.toFixed(2)} {props.C_Shape}{" "}
                                    {(props.C_Color ==="fancy") ? (<>{props.f_intensity} {props.f_overtone} {props.f_color}</>): props.C_Color}{" "}
                                    {props.C_Clarity} {props.C_Cut}{" "}
                                    {props.C_Polish} {props.C_Symmetry} {props.C_Fluorescence}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark  font-weight-bold text-capitalize font-size-h5 flex-wrap grid_title ml-1  d-flex align-items-center">
                                {GenerateTitle(props)}
                                {/* <span className="pr-2">
                                    {props.C_Weight.toFixed(2)}
                                </span>
                                <span className="pr-2">{props.C_Shape}</span>
                                { (props.C_Color ==="fancy") ?
                                    (
                                        <>
                                             <span className="pr-2">{props.f_intensity}</span>
                                             <span className="pr-2">{props.f_overtone}</span>
                                             <span className="pr-2">{props.f_color}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.C_Color}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.C_Clarity}</span>
                                <span className="pr-2">{props.C_Cut}</span>
                                <span className="pr-2">{props.C_Polish}</span>
                                <span className="pr-2">{props.C_Symmetry}</span>
                                <span className="pr-2">{props.C_Fluorescence}</span>*/}
                            </span> 
                            
                        </OverlayTrigger>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between ">
                        {
                            props.symbol !=="$" ? <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            ${numberWithCommas(props.OurRate)}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-muted font-size-xl font-weight-bold">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.OurRate * props.conversionrate)}</span ></span>
                                </OverlayTrigger>
                            </> :
                            <span className="text-muted font-size-xl font-weight-bold">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(props.OurRate * props.conversionrate)}</span ></span>
                        }
                        {
                            props.symbol !=="$" ? <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            ${numberWithCommas(props.OurPrice)}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-muted font-size-xl font-weight-bold">{props.language.SEARCHGRID_TOTAL}: <span className="text-primary font-size-xl">{props.symbol}{numberWithCommas(props.OurPrice* props.conversionrate)}</span ></span>
                                </OverlayTrigger>
                            </> :
                            <span className="text-muted font-size-xl font-weight-bold">{props.language.SEARCHGRID_TOTAL}: <span className="text-primary font-size-xl">{props.symbol}{numberWithCommas(props.OurPrice* props.conversionrate)}</span ></span>
                        } 
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 d-flex justify-content-between px-2 mtdr">
                       <span className="text-muted font-size-sm font-weight-bold">M: <span className="text-dark font-size-sm">{props.C_Length}*{props.C_Width}*{props.C_Depth}</span ></span>
                       <span className="text-muted font-size-sm font-weight-bold">T: <span className="text-dark font-size-sm">{props.C_TableP.toFixed(2)}%</span ></span>
                       <span className="text-muted font-size-sm font-weight-bold">D: <span className="text-dark font-size-sm">{props.C_DefthP.toFixed(2)}%</span ></span>
                       {/* <span className="text-muted font-size-sm font-weight-bold">R: <span className="text-dark font-size-sm">{props.Ratio?props.Ratio.toFixed(2):"-"}</span ></span> */}
                       <span className="text-muted font-size-sm font-weight-bold">R: <span className="text-dark font-size-sm">{props.Ratio?props.Ratio.toFixed(2):"-"}</span ></span>
                    </div>
                    <hr className="w-100 my-2" />
                    <div className="col-12 px-2">
                        <span className="d-flex text-muted justify-content-between align-items-center font-size-sm font-weight-bold">
                            <div>
                                <span className="text-dark-25 mr-2"><FaCalendarAlt /></span> 
                                {props.language.SEARCHGRID_SHIPPINGDAYS}: <span className="text-primary font-size-sm ml-1">{<>{props.shipping_days}{" "}{props.language.MUI_DAYS} </>}</span>
                            </div>
                            {props.country ? (
                                <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={
                                        <Tooltip id="tooltip">{props.country}</Tooltip>
                                    }
                                >
                                    <img
                                        className="flagIco mr-1"
                                        alt={props.value}
                                        src={toAbsoluteUrl(
                                            `/media/map/${props.country.toLowerCase()}.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src =toAbsoluteUrl(
                                                `/media/map/other.png`
                                                )
                                            }}
                                    />
                                </OverlayTrigger>
                            ) : (
                                null
                            )}
                        </span>
                        {props.show_supplier === 0 ? (
                            ""
                        ):(
                            <>
                            {user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                               <OverlayTrigger
                                    placement="top"
                                    className="text-capitalize"
                                    overlay={<Tooltip id="tooltip">{props.C_Name}</Tooltip>}
                                >
                                    <span className="d-flex text-muted align-items-center font-size-sm font-weight-bold mt-2" > <span className="text-dark-25 mr-2"><FaUserCircle /></span> {props.language.MUI_SUPPLIER}: <span className="font-size-sm ml-1 text-dark text-truncate">{props.C_Name}</span></span>
                                </OverlayTrigger>
                            ):(
                                <span className="d-flex text-muted align-items-center font-size-sm font-weight-bold mt-2" > <span className="text-dark-25 mr-2"><FaUserCircle /></span> {props.language.MUI_SUPPLIER}: <span className="font-size-sm ml-1 text-dark text-truncate">************</span></span>
                            )} </> 
                        )}
                    </div>
                    <hr className="w-100 my-2" />
                </div>
            </div>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="Stone already in tracklist"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Stone tracklisted successfully"
                />
            </Snackbar>
        </>
    );
};
