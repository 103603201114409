import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import { BsCardImage } from "react-icons/bs";
import { CircularProgress } from '@material-ui/core';

export const ImageExpandPopupCommon = (props) => {
    const buttonStyles = {
        position: "absolute",
        top: "10px",
        right: "10px"
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imageload, setImageLoad] = useState(false)
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && show) {
                handleClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, handleClose]);
    const ImageUrl = (data) => {
        let image = ""
        if (data.ext_status === "Success") {
            image = data.ext_img_runtime
        }
        else if (data.aws_image) {
            image = data.aws_image
        }
        console.log(image, "imageimage")
        return image
    }
    return (
        <>
            
            {ImageUrl(props.data) ? 
            <li className="img_thumb">
            <div>
                <img
                    className="img thumb"
                    alt={props.data.C_Shape}
                    src={ImageUrl(props.data)}
                    onClick={() => handleShow()}
                />
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                >
                    <Modal.Body>
                        {imageload ? "" : <div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "50"
                            }}>
                                <CircularProgress
                                // style={{marginLeft: '40%'}}
                                />
                                <h6>Please Wait...</h6>
                            </div>
                        </div>}
                        <div style={{ textAlign: "center" }}>
                            <img src={ImageUrl(props.data)} alt={props.data.C_Shape} style={{ objectFit: "cover" }} height='500px' width='500px' onLoad={() => setImageLoad(true)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            style={buttonStyles}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> 
            </li>: ""}
            
        </>
    );
};
