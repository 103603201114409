import React ,{useState,useEffect} from "react";
import { Grid, Typography, InputLabel } from "@material-ui/core";
import { SelectField,InputField } from "../FormFields";
import { GetCookies } from "../../../../_helpers/Cookies";


export default function MarkupCurrency(props) {
    const {
        formField: {
            muti_currency,
            tax_value,
            tax_name
        },
    } = props;
    // console.log(values,"BALUESSS")
    const [inr,setInr] = useState(0)
    const [cad,setCad] = useState(0)
    const [aud,setAud] = useState(0)
    const [hkd,setHkd] = useState(0)
    const [cny,setCny] = useState(0)
    const [eur,setEur] = useState(0)
    const [gbp,setGbp] = useState(0)
    const [nzd,setNzd] = useState(0)
    const [jpy,setJpy] = useState(0)
    const [chf,setChf] = useState(0)
    const [sgd,setSgd] = useState(0)
    const [thb,setThb] = useState(0)
    const [myr,setMyr] = useState(0)
    const [bnd,setBnd] = useState(0)
    const [dkk,setDkk] = useState(0)
    const [sek,setSek] = useState(0)
    const [usd,setUsd] = useState(0)

    useEffect(() => {
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round(currencies[0].cur_inr*100)/100)
                setCad(Math.round(currencies[0].cur_cad*100)/100)
                setAud(Math.round(currencies[0].cur_aud*100)/100)
                setHkd(Math.round(currencies[0].cur_hkd*100)/100)
                setCny(Math.round(currencies[0].cur_cny*100)/100)
                setEur(Math.round(currencies[0].cur_eur*100)/100)
                setGbp(Math.round(currencies[0].cur_gbp*100)/100)
                setNzd(Math.round(currencies[0].cur_nzd*100)/100)
                setJpy(Math.round(currencies[0].cur_jpy*100)/100)
                setChf(Math.round(currencies[0].cur_chf*100)/100)

                setSgd(Math.round(currencies[0].cur_sgd*100)/100)
                setThb(Math.round(currencies[0].cur_thb*100)/100)
                setMyr(Math.round(currencies[0].cur_myr*100)/100)
                setBnd(Math.round(currencies[0].cur_bnd*100)/100)
                setDkk(Math.round(currencies[0].cur_dkk*100)/100)
                setSek(Math.round(currencies[0].cur_sek*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)

            }
        })
    },[])
    // console.log(inr,usd,"inr,usd")
    const muti_currency_data = [
        {
          value: 'INR',
          label: `INR ₹ - ${inr.toFixed(2)}`
        },
        {
          value: 'USD',
          label: `USD $ - ${usd.toFixed(2)}`
        },
        {
          value: 'CAD',
          label: `CAD $ - ${cad.toFixed(2)}`
        },
        {
          value: 'AUD',
          label: `AUD $ - ${aud.toFixed(2)}`
        },
        {
          value: 'HKD',
          label: `HKD $ - ${hkd.toFixed(2)}`
        },
        {
          value: 'CNY',
          label: `CNY ¥ - ${cny.toFixed(2)}`
        },
        {
          value: 'EUR',
          label: `EUR € - ${eur.toFixed(2)}`
        },
        {
          value: 'GBP',
          label: `GBP £ - ${gbp.toFixed(2)}`
        },
        {
          value: 'NZD',
          label: `NZD $ - ${nzd.toFixed(2)}`
        },
        {
          value: 'JPY',
          label: `JPY ¥ - ${jpy.toFixed(2)}`
        },
        {
          value: 'CHF',
          label: `CHF ₣ - ${chf.toFixed(2)}`
        },
        {
          value: 'SGD',
          label: `SGD $ - ${sgd.toFixed(2)}`
        },
        {
          value: 'THB',
          label: `THB ฿ - ${thb.toFixed(2)}`
        },
        {
          value: 'MYR',
          label: `MYR RM - ${myr.toFixed(2)}`
        },
        {
          value: 'BND',
          label: `BND $ - ${bnd.toFixed(2)}`
        },
        {
          value: 'DKK',
          label: `DKK kr. - ${dkk.toFixed(2)}`
        },
        {
          value: 'SEK',
          label: `SEK kr - ${sek.toFixed(2)}`
        }
      ];
      const SetTaxInclusive = (values,setFieldValue) => {
        // console.log(values,"valuesvalues")
        setFieldValue("taxinclusive",!values.taxinclusive)
        props.SetTaxInclusiveValidation(!values.taxinclusive)
        if(values.taxinclusive){
          setFieldValue("tax_name","")
          setFieldValue("tax_value",0)
        }
      }
    return (
        <React.Fragment>
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
              {/* {props.language.CCMODE_SETUP_CURRENCY} */}
              Set Your Currency & Tax
            </Typography>
            <p className="mb-1 text-center">
              {props.language.CCMODE_SETUP_CURRENCY_MSG1}
            </p>
            <p className="mb-15 text-center">
              {props.language.CCMODE_SETUP_CURRENCY_MSG2}
            </p>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_SELECT_CURRENCY}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                name={muti_currency.name}
                                label={props.language.CCMODE_SETUP_SELECT_CURRENCY}
                                fullWidth
                                data={muti_currency_data}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <InputLabel className="font-size-h6 font-weight-bold">{props.values.taxinclusive?"Inclusive Tax":"Exclusive Tax"}</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="switch ">
                              <input type="checkbox" className="success" checked={props.values.taxinclusive} value={props.values.taxinclusive} onClick={() => SetTaxInclusive(props.values,props.setFieldValue)}/>
                              <span className="slider"></span>
                          </label>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                {props.values.taxinclusive?<>
                  <Grid item xs={12} md={7}>
                      <Grid container spacing={3} alignItems="center">
                          <Grid item xs={4}>
                              <InputLabel className="font-size-h6 font-weight-bold">
                                  Tax Name
                              </InputLabel>
                          </Grid>
                          <Grid item xs={6}>
                              <InputField
                                  name={tax_name.name}
                                  label={tax_name.label}
                                  fullWidth
                              />
                          </Grid>
                      </Grid>
                      <hr className="w-100 m-0 mt-8" />
                  </Grid>
                  <Grid item xs={12} md={7}>
                      <Grid container spacing={3} alignItems="center">
                          <Grid item xs={4}>
                              <InputLabel className="font-size-h6 font-weight-bold">
                                  Tax Value
                              </InputLabel>
                          </Grid>
                          <Grid item xs={6}>
                              <InputField
                                  name={tax_value.name}
                                  label={tax_value.label}
                                  fullWidth
                              />
                          </Grid>
                      </Grid>
                      <hr className="w-100 m-0 mt-8" />
                  </Grid>
                </>:""}
            </Grid>
        </React.Fragment>
    );
}
