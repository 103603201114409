import React from "react";
import { BASE_URL } from "./../../../../../env_config"
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";

export default function CompanyInfo({userinfo,setEdit,contactbookuserinfo,language}) {
    return (
        <>
            <div className="row">
                <div className="col-12 border-bottom d-flex justify-content-between pb-3 mb-5">
                    <h3>{language.PROFILEVIEW_COMPANY_INFORMATION}</h3>
                    <button className="btn btn-sm btn-secondary" onClick={() => setEdit(true)}>{language.PROFILE_EDIT}</button>
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_COMPANY_NAME}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.cname}</div>
                    </div>
                    {contactbookuserinfo.cname !== userinfo.cname?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.INVOICE_DETAIL_ADDRESS}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.address}, {userinfo.secaddress}, {userinfo.city} {userinfo.state} <br />{userinfo.country} {userinfo.com_zip}</div>
                    </div>
                    {contactbookuserinfo.address !== userinfo.address || contactbookuserinfo.secaddress !== userinfo.secaddress || contactbookuserinfo.city !== userinfo.city || contactbookuserinfo.state !== userinfo.state || contactbookuserinfo.country !== userinfo.country || contactbookuserinfo.com_zip !== userinfo.com_zip?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_TELEPHONE}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.mobile}</div>
                    </div>
                    {contactbookuserinfo.mobile !== userinfo.mobile?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_FAX}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.fax}</div>
                    </div>
                    {contactbookuserinfo.fax !== userinfo.fax?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_WEBSITE}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.website}</div>
                    </div>
                    {contactbookuserinfo.website !== userinfo.website?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_INVOICE_NOTIFICATION_EMAIL}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.invoiceemail}</div>
                    </div>
                    {contactbookuserinfo.invoiceemail !== userinfo.invoiceemail?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                <div className="col-md-7 mb-4  d-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_COMP_REG}</div>
                        <div className="row">
                            <div className="col-md-12 d-md-flex">
                                <div className="font-size-lg font-weight-bolder" >{userinfo.com_reg_no}  </div>
                                {userinfo.com_reg_doc ? 
                                    <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={<Tooltip id="tooltip">{userinfo.com_reg_doc}</Tooltip>}
                                    >
                                        <a href={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/documents/${userinfo.com_reg_doc}`:`${BASE_URL}/assets/documents/${userinfo.com_reg_doc}`} target="_blank" rel="noreferrer" className="font-weight-bold ml-md-10">{language.PROFILE_EDIT_VIEW_IMAGE}</a>
                                    </OverlayTrigger>
                                : "" }
                            </div>
                        </div>
                    </div>
                    {contactbookuserinfo.com_reg_doc !== userinfo.com_reg_doc?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_NATURE_BUSSINESS}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.nature_buss}</div>
                    </div>
                    {contactbookuserinfo.nature_buss !== userinfo.nature_buss?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_BUSSINESS_EST_DATE}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.buss_esta_date}</div>
                    </div>
                    {contactbookuserinfo.buss_esta_date !== userinfo.buss_esta_date?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                   <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_NAME_DPP}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.director_name}</div>
                   </div>
                    {contactbookuserinfo.director_name !== userinfo.director_name?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_CONTACT_DPP}</div>
                        <div className="font-size-lg font-weight-bolder" >{userinfo.directory_contact}</div>
                    </div>
                    {contactbookuserinfo.directory_contact !== userinfo.directory_contact?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                
            </div>
        </>
    );
}
