import React from "react";
import { Field } from "formik";

export const KeytoSymbol = ({language}) => {
    const keytoSymbolData = ([
        {
            id: 1,
            keytoSymbol:"Bearding"
        },
        {
            id: 2,
            keytoSymbol:"Crystal"
        },
        {
            id: 3,
            keytoSymbol:"Internal Graining"
        },
        {
            id: 4,
            keytoSymbol:"Natural"
        },
        {
            id: 5,
            keytoSymbol:"Brown patch of color"
        },
        {
            id: 6,
            keytoSymbol:"Canada"
        },
        {
            id: 7,
            keytoSymbol:"Crystal Surface"
        },
        {
            id: 8,
            keytoSymbol:"Internal Inscription"
        },
        {
            id: 9,
            keytoSymbol:"Needle"
        },
        {
            id: 10,
            keytoSymbol:"Bruise"
        },
        {
            id: 11,
            keytoSymbol:"Etch Channel"
        },
        {
            id: 12,
            keytoSymbol:"Internal Laser Drilling"
        },
        {
            id: 13,
            keytoSymbol:"No Inclusion"
        },
        {
            id: 14,
            keytoSymbol:"Cavity"
        },
        {
            id: 15,
            keytoSymbol:"Extra Facet"
        },
        {
            id: 16,
            keytoSymbol:"Knot"
        },
        {
            id: 17,
            keytoSymbol:"Pinpoint"
        },
        {
            id: 18,
            keytoSymbol:"Chip"
        },
        {
            id: 19,
            keytoSymbol:"Feather"
        },
        {
            id: 20,
            keytoSymbol:"Laser Drill Hole"
        },
        {
            id: 21,
            keytoSymbol:"Reﬂecting Surface Graining"
        },
        {
            id: 22,
            keytoSymbol:"Cleavage"
        },
        {
            id: 23,
            keytoSymbol:"Flux Remnant"
        },
        {
            id: 24,
            keytoSymbol:"Manufacturing Remnant"
        },
        {
            id: 25,
            keytoSymbol:"Surface Graining"
        },
        {
            id: 26,
            keytoSymbol:"Cloud"
        },
        {
            id: 27,
            keytoSymbol:"Indented Natural"
        },
        {
            id: 28,
            keytoSymbol:"Minor Details of Polish"
        },
        {
            id: 29,
            keytoSymbol:"Twinning Wisp"
        }

    ]);
    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.MUI_KEY}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {keytoSymbolData.map((item) => (
                            <div key={`${item.id}_keytoSymbol`} className="col-md-3">
                                <label className="checkbox mb-4 font-size-lg">
                                    <Field type="checkbox" name="keytoSymbol" id={`${item.keytoSymbol}_keytoSymbol`} value={item.keytoSymbol}/>
                                    <span className="mr-2"></span>
                                    {item.keytoSymbol}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}; 